import {
  CardBadge,
  CardGreyText,
  SplitOrderText,
  CardStatusText,
  CardsBody,
} from "./styles";
import {
  Typography,
  NIcons,
  IconsEnum,
  BadgeStatus,
  NTheme as theme,
} from "kudi-component-library";
import { Flex, Box } from "rebass";
import { OrderSummaryBody } from "../../../types/MenuList";
import { formatDateValue } from "../../../utils";
import { returnFormattedOrderStatusName } from "../../../utils/status";

interface KdsCardInterface extends OrderSummaryBody {
  variant?: BadgeStatus;
  orderStatus?: string;
  editable?: boolean;
  handleClick?: (order: OrderSummaryBody) => void;
}

export default function KdsCard({
  variant = "default",
  orderStatus = "New Order",
  editable = false,
  handleClick = (order: OrderSummaryBody) => { },
  ...props
}: KdsCardInterface) {
  return (
    <CardsBody p={"12px"} onClick={() => handleClick(props)}>
      <Flex justifyContent={"space-between"}>
        <Box>
          <CardGreyText variant="n-text1" fontWeight="light">
            {formatDateValue(props.orderDate as unknown as Date)}
          </CardGreyText>
          <CardGreyText variant="n-text1" fontWeight="light">
            {" "}
            Order ID: {props.orderReference}
          </CardGreyText>
        </Box>
        <CardBadge variant={variant}>
          <CardStatusText variant="n-text0">
            {returnFormattedOrderStatusName(props.status)}
          </CardStatusText>
          {editable ? (
            <NIcons
              height="12"
              width="12"
              icon={IconsEnum.OUTLINE_CHEVRON_DOWN}
            />
          ) : null}
        </CardBadge>
      </Flex>
      <Flex justifyContent={"space-between"}>
        <Typography variant="n-text1" fontWeight="heavy">
          {props.items.map((oneItem, index) =>
            index + 1 < props.items.length ? `${oneItem.name} + ` : oneItem.name
          )}{" "}
        </Typography>
      </Flex>
      <Flex justifyContent={"space-between"}>
        <Flex
          style={{ borderRadius: 5, background: theme.nColors["n-grey1"] }}
          px={"8px"}
          py={"3px"}
          justifyContent="center"
          alignItems="center"
        >
          {props.orderType.toUpperCase()}
        </Flex>
        {props.splitOrder ? (
          <SplitOrderText variant="n-text0">Split Order</SplitOrderText>
        ) : null}
      </Flex>
    </CardsBody>
  );
}
