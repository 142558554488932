import { Box, Flex } from "rebass";
import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Button, TextInput, Typography } from "kudi-component-library";
import styled from "styled-components";
import { useOrderData } from "../state/context";
import { useMixPanel } from "../hooks/useMixpanel";
import BottomSheetModal from "./BottomSheetModal";
import Select from "./Select";
import { MenuDeliveryLocations } from "../types/MenuList";

const PlaceOrderButton = styled(Button)`
  width: 100%;
`;

const PlaceOrderInputWrapper = styled(Box)`
  & > div {
    width: 100%;
  }

  & input {
    width: "100%";
    color: ${({ theme }) => theme.nColors["n-dark"]};
    background: transparent;
  }
`;

interface EnterDeliveryAreaProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  deliveryAreas: MenuDeliveryLocations[];
}

const EnterDeliveryArea = ({
  isOpen,
  setIsOpen,
  deliveryAreas,
}: EnterDeliveryAreaProps) => {
  const { setOrderData, orderData } = useOrderData();
  const [tableNumberValue, setTableNumberValue] = useState<string>("");
  const [selectedArea, setSelectedArea] = useState<string>("");
  const [deliveryAreasForSelect, setDeliveryAreasForSelect] = useState<
    {
      text: string;
      value: string;
    }[]
  >([]);
  const { track } = useMixPanel();

  const onEnterDeliveryArea = () => {
    setOrderData({
      ...orderData,
      tableNumber: tableNumberValue,
      deliveryArea: JSON.parse(selectedArea),
    });
    setIsOpen(false);
  };

  useEffect(() => {
    if (orderData?.tableNumber) {
      setTableNumberValue(orderData?.tableNumber);
    }
    if (deliveryAreas) {
      setDeliveryAreasForSelect(
        deliveryAreas.map((item) => ({
          text: `${item.area.split("+")[0]}, ${item.area.split("+")[1]}`,
          value: JSON.stringify(item),
        }))
      );
    }
  }, [deliveryAreas]);

  const handlechange = (e: FormEvent<HTMLInputElement>) => {
    let newValue = e.currentTarget.value;
    setTableNumberValue(newValue);
  };
  const handleSelect = (e: { name: string; value: string | null }) => {
    setSelectedArea(e.value as string);
  };

  return (
    <BottomSheetModal
      title="Delivery Locations"
      subTitle="Select a delivery area, and enter your full address."
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Box mt={40} justifyContent="center" alignItems={"center"}>
        <PlaceOrderInputWrapper mb={"20px"}>
          <Select
            value={selectedArea}
            name="selectArea"
            options={deliveryAreasForSelect}
            label="Delivery Areas"
            placeholder="Select"
            onChange={(e) => {
              handleSelect(e.target);
            }}
          />
        </PlaceOrderInputWrapper>
        <PlaceOrderInputWrapper mb={"20px"}>
          <TextInput
            id="address"
            label="Your full Address"
            name="address"
            type="text"
            placeholder="Enter your full address"
            value={tableNumberValue}
            onChange={(e) => {
              handlechange(e);
            }}
          />
        </PlaceOrderInputWrapper>
        <Flex
          mt="50px"
          mb="20px"
          justifyContent={"center"}
          alignItems={"center"}
          style={{ gap: "15px" }}
        >
          <PlaceOrderButton
            variant="medium"
            disabled={!tableNumberValue || !selectedArea}
            onClick={() => {
              onEnterDeliveryArea();
              track("MENU_CUSTOMER_SELECTS_TABLE_NUMBER");
            }}
          >
            Continue
          </PlaceOrderButton>
        </Flex>
      </Box>
    </BottomSheetModal>
  );
};

export default EnterDeliveryArea;
