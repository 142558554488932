import styled from "styled-components";

export const SearchWrapper = styled.form`
  width: 100%;
  height: 50px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
  border: 1px solid ${({ theme }) => theme.nColors["n-grey1"]};

  svg {
    margin-right: 22.86px;
  }
  input {
    border: none;
    outline: none;
    width: 100%;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

export const ClearIconWrapper = styled.div`
  svg {
    margin-right: 0;
    cursor: pointer;
  }
`;
