import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MenuDataBody } from "../../types";

interface InitialState {
  menuInformation: MenuDataBody;
}

const initialState: InitialState = {
  menuInformation: {
    items: [],
    pageToken: "",
    alias: "",
    businessId: "",
    coverImageUrl: "",
    id: 0,
    reference: "",
    timeCreated: "",
    menuId: "",
    menuStoreResponse: {
      storeId: "",
      storeName: "",
      description: "",
      storeLocation: "",
      businessId: "",
      taxOnOrder: 0,
      showCustomerPhoneNumber: false,
      phoneNumber: "",
      inHouseService: false,
      deliveryService: false,
      coverImageUrl: "",
    },
    businessHours: [],
    deliveryLocations: [],
  },
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setMenuInformation: (state, action: PayloadAction<MenuDataBody>) => {
      state.menuInformation = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setMenuInformation } = menuSlice.actions;

export default menuSlice.reducer;
