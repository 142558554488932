import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postPlaceOrder } from '../../queries';
import { useOrderData } from '../../state/context';
import { ErrorResponse, MenuListProduct } from '../../types';
import { PlacedOrderDataBody, PlaceOrderPayload } from '../../types/MenuList';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useMixPanel } from '../../hooks/useMixpanel';

type SavedBusinessData = {
  deliveryLocations: { area: string; id: string; price: number }[];
};

const usePlaceOrder = () => {
  const [placeOrderRes, setPlaceOrderRes] = useState<PlacedOrderDataBody>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const savedBusinessDataStringified = localStorage.getItem('savedBusinessData');

  const savedBusinessData: SavedBusinessData = JSON.parse(savedBusinessDataStringified || '{deliveryLocations:[]}');
  const { track } = useMixPanel();

  const { businessData, orderData, setOrderReference } = useOrderData();
  const getDeliveryLocationId = (area: string) => {
    const locationId = savedBusinessData.deliveryLocations.filter((item: any) => item.area === area);
    return locationId[0].id;
  };

  const handlePlaceOrder = async (pageTo: string) => {
    setIsLoading(true);
    if (businessData) {
      const getItems: MenuListProduct[] | undefined = orderData?.selectedMeals?.map((val) => ({
        ...val.meal,
        quantity: val.quantity,
      }));
      const payload: PlaceOrderPayload = {
        businessId: businessData.businessId,
        discountId: 0,
        destination: `${orderData?.tableNumber || '-'}`,
        menuId: businessData?.menuId,
        customerPhoneNumber: orderData?.customerPhone as string,
        customerFullName: orderData?.customerName as string,
        source: 'menu',
        items: getItems as MenuListProduct[],
        orderType: !!orderData?.deliveryArea ? 'DELIVERY' : 'DINE_IN',
        description: orderData?.additionalNotes || ' ',
      };

      if (orderData?.deliveryArea?.area) {
        payload['deliveryLocationId'] = getDeliveryLocationId(orderData?.deliveryArea?.area as string);
        payload['destination'] = `${orderData?.tableNumber}+${orderData?.deliveryArea?.area}` || '-';
      }
      const { result, error: fetchError } = await postPlaceOrder(payload);
      setIsLoading(false);
      if (result) {
        localStorage.removeItem('savedOrder');
        setPlaceOrderRes(result.data);
        if (setOrderReference) setOrderReference(result.data);
        track('MENU_CUSTOMER_ORDER_PLACED');
        if (pageTo === 'fromTerminal') {
          window.location.href = `intent://com.nomba.pro#Intent?scheme=cardPaymentScheme;action=card_payment_action;S.amount=${result.data?.totalAmount};S.orderReference=${result.data?.orderReference};end`;
        } else {
          navigate(pageTo);
        }
      }
      if (fetchError) {
        track('MENU_CUSTOMER_ERROR_PLACING_ORDER', {
          error: JSON.stringify(fetchError),
        });
        toast.error((fetchError as ErrorResponse)?.data.message || 'An error occured while fetching menu');
      }
    }
  };

  return {
    handlePlaceOrder,
    placeOrderRes,
    isLoading,
  };
};

export default usePlaceOrder;
