import { useState } from 'react'
import { OrderStatus } from '../../../types/MenuList';
import putChangeMenuOrderStatus from '../../../queries/putChangeMenuOrderStatus';
import { useAppDispatch, useAppSelector } from '../../../state/store';
import { useAuth0 } from '@auth0/auth0-react';
import { setProposedOrderStatus, setSelectedOrder } from '../../../state/kds/slice';
import { ReduxAction } from '../../../types/redux';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';

const useUpdateOrderStatus = ({ onSuccess, onFailure }: ReduxAction) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const { selectedOrder, proposedOrderStatus } = useAppSelector((state) => state.kds);

    const { menuInformation } = useAppSelector((state) => state.menu);

    const isCompletedOrCancelled = selectedOrder?.status === "CANCELLED" || selectedOrder?.status === "COMPLETED"

    const changeMenuOrderStatus = async () => {
        setIsLoading(true);

        const accessToken = await getAccessTokenSilently();

        if (proposedOrderStatus === selectedOrder?.status) {
            toast.error(`Cannot update order to the same status`);
            setIsLoading(false);
            onFailure?.(selectedOrder);
            dispatch(setProposedOrderStatus(null));
            return;
        }

        if (isCompletedOrCancelled) {
            toast.error(`Cannot update a ${selectedOrder.status} order`);
            onFailure?.(selectedOrder);
            dispatch(setProposedOrderStatus(null));
            return;
        }

        const { result, error: statusError } = await putChangeMenuOrderStatus({
            orderStatus: proposedOrderStatus as OrderStatus,
            orderId: selectedOrder?.orderReference || '',
            accessToken,
            businessId: menuInformation?.businessId as string,
            onFailure,
            onSuccess
        });
        setIsLoading(false);
        dispatch(setProposedOrderStatus(null));
        dispatch(setSelectedOrder(null));
        if (result) {
            toast.success(result.message);
        }
        if (statusError) {
            toast.error((statusError as AxiosResponse)?.data?.message || "Error occured while updating order status");
        }
    }
    return {
        changeMenuOrderStatus
    }
}

export default useUpdateOrderStatus
