import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ErrorResponse } from "../../../types";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useMenuListProductsModel from "../../MenuListPageSetup/useMenuListProducts";
import { PlacedOrderDataBody } from "../../../types/MenuList";
import fetchOrderList from "../../../queries/fetchOrderList";
import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";
import { AppliedFilter } from "../../newFilter/defs";

const useWaiterOrderList = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orders, setOrders] = useState<PlacedOrderDataBody[]>([]);
  const navigate = useNavigate();
  const [params, setParams] = useState<URLSearchParams>();
  const [pageTokens, setPageTokens] = useState<string[]>([""]);
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const [reloadMode, setReload] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [selectedFormat, setSelectedFormat] = useState("Today");

  const { businessId } = useParams();
  const { productBody } = useMenuListProductsModel(businessId as string);
  const { getAccessTokenSilently } = useAuth0();

  const handleFetchOrders = async () => {
    const accessToken = await getAccessTokenSilently();
    const newParams = new URLSearchParams(params);
    newParams.set("source", "menu");
    newParams.set("limit", "10");
    newParams.set("storeId", productBody?.menuStoreResponse?.storeId as string);

    setIsLoading(true);
    const { result, error: fetchError } = await fetchOrderList({
      params: newParams,
      accessToken: accessToken,
    });
    if (result?.data) {
      if (reloadMode) {
        setOrders(result.data.list);
      } else {
        setOrders([...orders, ...result.data.list]);
      }
      setPageTokens((pageResetTokens) => [
        ...pageResetTokens,
        result.data.pageToken,
      ]);
      setIsLastPage(!result.data.pageToken);
      setIsLoading(false);
      setReload(false);
    }
    if (fetchError) {
      setIsLoading(false);
      toast.error(
        (fetchError as ErrorResponse)?.data.message ||
        "An error occured while fetching item"
      );
    }
  };

  const handleOnchangePagination = () => {
    const newParams = new URLSearchParams(params);
    newParams.set("cursor", pageTokens[page]);
    setParams(newParams);
    setPage(page + 1);
  };

  const handleSelect = (e: { name: string; value: string | null }) => {
    const newParams = new URLSearchParams(params);
    newParams.delete("cursor");
    if (e.value === "today") {
      newParams.set(
        "startDate",
        dayjs().startOf("day").format("YYYY-MM-DD HH:mm")
      );
      newParams.set("endDate", dayjs().endOf("day").format("YYYY-MM-DD HH:mm"));
    } else if (e.value === "yesterday") {
      newParams.set(
        "startDate",
        dayjs().add(-1, "day").startOf("day").format("YYYY-MM-DD HH:mm")
      );
      newParams.set(
        "endDate",
        dayjs().add(-1, "day").endOf("day").format("YYYY-MM-DD HH:mm")
      );
    } else {
      newParams.delete("startDate");
      newParams.delete("endDate");
    }
    setReload(true);
    setParams(newParams);
    setSelectedFormat(e.value as string);
  };

  const handleFilterOrders = (filterItems: AppliedFilter[]) => {
    const newParams = new URLSearchParams();
    filterItems.forEach((item) => {
      const { query, value } = item;
      if (value === "ALL") {
        setParams(new URLSearchParams());
        return;
      }
      if (query === "dateRange") {
        newParams.set(
          "startDate",
          dayjs(value[0]).startOf("day").format("YYYY-MM-DD HH:mm")
        );
        newParams.set(
          "endDate",
          dayjs(value[1]).startOf("day").format("YYYY-MM-DD HH:mm")
        );
      } else {
        newParams.set(item.query, String(item.value));
      }
    });
    setReload(true);
    setParams(newParams);
  };

  const handleSearchOrders = (destination: string) => {
    const newParams = new URLSearchParams(params?.toString());
    newParams.set("destination", destination);
    setReload(true);
    setParams(newParams);
  };

  const handleClearSearch = () => {
    const newParams = new URLSearchParams(params?.toString());
    newParams.delete("destination");
    setReload(true);
    setParams(newParams);
  };

  const handleClearFilters = () => {
    setReload(true);
    setParams(new URLSearchParams());
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const request = new AbortController();
    if (productBody?.businessId) {
      handleFetchOrders();
    }
    return () => request.abort();
  }, [productBody?.businessId, params]);

  return {
    orders,
    isLoading,
    isLastPage,
    handleOnchangePagination,
    selectedFormat,
    handleSelect,
    handleFilterOrders,
    handleClearFilters,
    handleClearSearch,
    handleSearchOrders,
    handleScrollToTop,
    reloadMode,
  };
};

export default useWaiterOrderList;
