import mixpanel from "mixpanel-browser";
import appConfig from "../utils/config";
import DeviceParser from "ua-parser-js";

mixpanel.init(appConfig.mixPanelToken as string);

const tokenAvailable = !!appConfig.mixPanelToken;

export const getUserBrowser = () => {
  const ua = DeviceParser(window.navigator.userAgent);

  return ua.browser.name
    ? `${ua.browser.name}-${ua.browser.version || ""}`
    : "unknown";
};

export const useMixPanel = () => {
  return {
    identify: () => {
      if (tokenAvailable) mixpanel.identify();
    },
    alias: (id: string) => {
      if (tokenAvailable) mixpanel.alias(id);
    },
    track: (
      name: string,
      object?: Record<string, string | number | boolean>
    ) => {
      if (tokenAvailable)
        mixpanel.track(name, { userBrowser: getUserBrowser(), ...object });
    },
    people: {
      set: (props: Record<string, string | number | boolean | null>) => {
        mixpanel.people.set(props);
      },
    },
  };
};
