import styled from "styled-components";

export const NavWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 20px 30px;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.nColors["n-light"]};
  position: relative;
  z-index: 50;
  height: 72px;
  position: fixed;
  left: 0;
  right: 0;
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.05));
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.primary.black5};
`;

// nav filter
export const NavFilterWrapper = styled.div`
  display: flex;
`;

export const NavFilterHeaderWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  position: relative;
  cursor: pointer;
  text-transform: capitalize;
`;

export const NavContextMenuWrapper = styled.div<{ top?: string }>`
  position: absolute;
  top: ${({ top }) => top || "58px"};
  display: flex;
  flex-direction: column;
  min-width: 200px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.nColors["n-light"]};
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.05));
  justify-content: center;
`;

export const NavContextMenuItem = styled.div<{ selected?: boolean }>`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: 200px;
  height: 100%
  align-content: center;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.nColors["n-light"]};
  border-bottom: 1px solid ${({ theme }) => theme.nColors["n-grey0"]};
  &:first-of-type {
    border-radius: 4px 4px 0px 0px;
  }
  &:last-of-type {
    border-radius: 0px 0px 4px 4px;
    border-bottom: none;
  }
  p {
    font-weight: ${({ selected }) => (selected ? "500" : "400")};
    text-transform: capitalize;
    color: ${({ theme, selected }) =>
      selected ? theme.nColors["n-dark"] : theme.nColors["n-grey5"]};
  }
  &:hover {
    background-color: ${({ theme }) => theme.nColors["n-grey1"]};
    p {
      color: ${({ theme }) => theme.nColors["n-dark"]};
    }
  }
`;

// nav actions
export const NavbarActionsWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  align-content: center;
  svg {
    cursor: pointer;
  }
`;

export const LogoutWrapper = styled.div`
  cursor: pointer;

  &:hover {
    span {
      color: ${({ theme }) => theme.nColors["n-grey4"]};
    }
  }
  span {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.nColors["n-yellow"]};
  }
`;
