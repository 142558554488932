import { FilterConfig, FilterConfigOption } from "../newFilter/defs";

const statusOptions: FilterConfigOption[] = [
    { text: 'All', value: 'ALL' },
    { text: 'New order', value: 'INITIATED' },
    { text: 'Processing', value: 'IN_PROGRESS' },
    { text: 'Completed', value: 'COMPLETED' },
    { text: 'Cancelled', value: 'CANCELLED' },
  ];

const paymentOptions: FilterConfigOption[] = [
  { text: 'All', value: 'ALL' },
    { text: 'Cash', value: 'CASH' },
    { text: 'Transfer', value: 'TRANSFER' },
    { text: 'Card', value: 'CARD' },
  ];
  
  export const OrderFilterConfig: Array<FilterConfig> = [
    {
      name: 'Date',
      type: 'date',
      query: 'dateRange',
      options: [],
    },
    {
      name: 'Status',
      type: 'select',
      query: 'status',
      options: statusOptions,
    },
    {
      name: 'Payment Method',
      type: 'select',
      query: 'paymentMethod',
      options: paymentOptions,
    },
  ];