import { Box, Flex } from "rebass";
import { BackButton, Typography } from "kudi-component-library";
import styled from "styled-components";
import {
  capitalizeFirstLetter,
  formatCurrency,
  formatDateValue,
} from "../../utils";
import { useNavigate, useParams } from "react-router-dom";
import { OrderStatus } from "../../types/MenuList";
import {
  EllipsedText,
  HeadBox,
  OrderItem,
  OrderStatusBadge,
  ViewMoreButton,
} from "../Styles";
import { MainPageWrapper } from "../Layout";
import useWaiterOrderList from "./models/useWaiterOrderList";
import NoOrdersIcon from "../Icons/NoOrders";
import { Oval } from "react-loader-spinner";
import Filter from "../newFilter";
import { OrderFilterConfig } from "./config";

export default function WaiterSearchOrderList() {
  const navigate = useNavigate();
  const { businessId } = useParams();
  const {
    orders,
    isLoading: isLoadingOrders,
    handleFilterOrders,
    handleClearFilters,
    handleClearSearch,
    handleSearchOrders,
    handleScrollToTop,
    handleOnchangePagination,
    reloadMode,
    isLastPage,
  } = useWaiterOrderList();

  const returnOrderNames = (status: OrderStatus) => {
    let orderStatusVal: string = status;
    if (status === "INITIATED") {
      orderStatusVal = "New order";
    }
    if (status === "IN_PROGRESS") {
      orderStatusVal = "Processing";
    }
    return orderStatusVal;
  };

  return (
    <>
      <MainPageWrapper>
        <div className="pageSetup">
          <Box p="20px" mb="200px">
            <HeadBox pb="20px">
              <Flex justifyContent="space-between">
                <BackButton
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "none",
                  }}
                  onClick={() => navigate(-1)}
                >
                  Back
                </BackButton>
              </Flex>
              <Box my="20px">
                <Typography variant="n-text5">Order List (All)</Typography>
              </Box>
              <Filter
                searchPlaceholder="Search by Table Number"
                filterConfig={OrderFilterConfig}
                onSearch={handleSearchOrders}
                onClearSearch={handleClearSearch}
                onApplyFilters={handleFilterOrders}
                onClearFilters={handleClearFilters}
              />
            </HeadBox>
            <Flex flexDirection="column" alignItems={"center"} mb="100px">
              <Box py="20px" width="100%">
                {isLoadingOrders || reloadMode ? (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    my="120px"
                  >
                    <Oval
                      height={60}
                      width={60}
                      color="#ffcc00"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#332900"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </Flex>
                ) : (
                  <>
                    {orders.length === 0 ? (
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        my="120px"
                        style={{ gap: "20px" }}
                      >
                        <NoOrdersIcon />
                        <Typography color="n-grey3a">
                          You have not taken an order, today.
                        </Typography>
                      </Flex>
                    ) : (
                      orders.map((item) => {
                        return (
                          <OrderItem
                            mb="10px"
                            onClick={() => {
                              navigate(
                                `/${businessId}/waiter/order-details/${item.orderReference}`
                              );
                            }}
                          >
                            <Flex
                              justifyContent="space-between"
                              style={{ borderBottom: "solid 1px #EEE" }}
                              mb="5px"
                              px="15px"
                              py="10px"
                            >
                              <Typography variant="n-text1" color="n-grey3a">
                                Table {item.destination}
                              </Typography>
                              {item.paymentStatus === "PAYMENT_PENDING" ? (
                                <Typography variant="n-text1" color="n-red">
                                  Not Paid
                                </Typography>
                              ) : (
                                <Typography variant="n-text1" color="n-green">
                                  Paid by{" "}
                                  {capitalizeFirstLetter(item.paymentMethod)}
                                </Typography>
                              )}
                            </Flex>
                            <Box px="15px" py="10px">
                              <Box mb="5px">
                                <EllipsedText
                                  style={{ maxWidth: "80%" }}
                                  variant="n-text2"
                                >
                                  {item.itemSummary.replaceAll(
                                    "Item name: ",
                                    ""
                                  ) || item.orderReference}
                                </EllipsedText>
                              </Box>
                              <Typography variant="n-text2" fontWeight="heavy">
                                {formatCurrency(item.totalAmount)}
                              </Typography>
                              <Flex
                                justifyContent="space-between"
                                flexWrap="wrap"
                              >
                                <Flex
                                  style={{ gap: "5px" }}
                                  mb="5px"
                                  mr="10px"
                                  alignItems="center"
                                  flexWrap="wrap"
                                >
                                  <Typography
                                    variant="n-text2"
                                    color="n-grey3a"
                                  >
                                    {item.orderReference}
                                  </Typography>
                                  <span>
                                    <svg
                                      width="6"
                                      height="6"
                                      viewBox="0 0 6 6"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M2.9375 5.13068C2.53646 5.13068 2.17022 5.03291 1.83878 4.83736C1.50734 4.63849 1.24219 4.37334 1.04332 4.0419C0.847775 3.71046 0.75 3.34422 0.75 2.94318C0.75 2.53883 0.847775 2.17259 1.04332 1.84446C1.24219 1.51302 1.50734 1.24953 1.83878 1.05398C2.17022 0.855113 2.53646 0.755682 2.9375 0.755682C3.34186 0.755682 3.7081 0.855113 4.03622 1.05398C4.36766 1.24953 4.63116 1.51302 4.8267 1.84446C5.02557 2.17259 5.125 2.53883 5.125 2.94318C5.125 3.34422 5.02557 3.71046 4.8267 4.0419C4.63116 4.37334 4.36766 4.63849 4.03622 4.83736C3.7081 5.03291 3.34186 5.13068 2.9375 5.13068Z"
                                        fill="#8C8C8C"
                                      />
                                    </svg>
                                  </span>
                                  <Typography
                                    variant="n-text2"
                                    color="n-grey3a"
                                  >
                                    {formatDateValue(
                                      item.orderDate as unknown as Date
                                    )}
                                  </Typography>
                                </Flex>
                                <OrderStatusBadge status={item.status}>
                                  {capitalizeFirstLetter(
                                    returnOrderNames(item.status)
                                  )}
                                </OrderStatusBadge>
                              </Flex>
                            </Box>
                          </OrderItem>
                        );
                      })
                    )}
                    {isLastPage && orders.length > 5 ? (
                      <ViewMoreButton
                        variant="medium"
                        color="secondary"
                        onClick={handleScrollToTop}
                      >
                        Scroll Back Up
                      </ViewMoreButton>
                    ) : (
                      <ViewMoreButton
                        disabled={isLastPage}
                        loading={isLoadingOrders}
                        variant="medium"
                        color="secondary"
                        onClick={handleOnchangePagination}
                      >
                        View more
                      </ViewMoreButton>
                    )}
                  </>
                )}
              </Box>
            </Flex>
          </Box>
        </div>
      </MainPageWrapper>
    </>
  );
}
