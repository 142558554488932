import { Box, Flex, Link } from "rebass";
import {
  Button,
  Checkbox,
  IconsEnum,
  NIcons,
  Typography,
} from "kudi-component-library";
import styled from "styled-components";
import { MainPageWrapper } from "../Layout";
import useLoadOrderSummary from "./useLoadOrderSummary";
import PageLoad from "../PageLoad";
import { useOrderData } from "../../state/context";
import { formatCurrency, formatDateValue } from "../../utils";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMixPanel } from "../../hooks/useMixpanel";
import { EllipsedText } from "../Styles";
import { useLocalStorage } from "../../hooks/useLocalStorage";

const TrackerDash = styled.div`
  position: absolute;
  border: 1px dashed ${({ theme }) => theme.nColors["n-grey2"]};
  height: 60px;
  left: 13px;
  top: 40px;
`;
const TrackStatus = styled(Flex)`
  position: relative;
`;

const TotalWrapper = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.nColors["n-grey1"]};
  padding-bottom: 25px;
  padding-top: 15px;
`;
const Navigation = styled.section`
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CancelledBox = styled.div`
  background: ${({ theme }) => theme.nColors["n-red5"]};
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: ${({ theme }) => theme.nColors["n-red1"]};
  font-size: 12px;
`;

const RefreshButton = styled(Button)`
  min-width: 115px;
  padding: 0px 8px;
  span {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;
const PageBody = styled(Box)`
  background: ${({ theme }) => theme.nColors["n-light"]};
  min-height: 100vh;
`;

const OrderSummaryPageSetup = () => {
  const { orderId } = useParams();
  const { track } = useMixPanel();

  const navigate = useNavigate();

  const [savedBusinessData] = useLocalStorage("savedBusinessData");
  const [fromTerminal] = useLocalStorage("fromTerminal");

  const {
    orderSummary,
    tableOrRoomNumberDetails,
    getOrderCombinedDetails,
    isLoading,
  } = useLoadOrderSummary({
    orderId: orderId as string,
  });

  useEffect(() => {
    track("MENU_CUSTOMER_CUSTOMER_VIEW_ORDER_SUMMARY_PAGE_DETAILS", {
      orderId: orderId as string,
    });
  }, []);

  return (
    <PageBody>
      {isLoading ? (
        <PageLoad />
      ) : (
        <>
          {orderSummary ? (
            <MainPageWrapper>
              <Box className="pageSetup" p="20px">
                <Navigation>
                  <span
                    onClick={() => {
                      navigate(
                        `/${savedBusinessData?.alias}/${
                          fromTerminal ? "waiter" : ""
                        }`
                      );
                    }}
                  >
                    <NIcons icon={IconsEnum.OUTLINE_CHEVRON_LEFT} />
                  </span>
                  <Typography variant="n-text5">Track Order</Typography>
                  <span
                    onClick={() => {
                      navigate(
                        `/${savedBusinessData?.alias}/${
                          fromTerminal ? "waiter" : ""
                        }`
                      );
                    }}
                  >
                    <NIcons icon={IconsEnum.OUTLINE_HOME} />
                  </span>
                </Navigation>
                <Box mb="20px">
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    mt={"15px"}
                    flexWrap={"wrap"}
                  >
                    <Box>
                      <Typography
                        color="n-grey3"
                        fontWeight={500}
                        variant="n-text1"
                      >
                        Order ID:
                      </Typography>
                      <Typography fontWeight={400} variant="n-text2">
                        {orderSummary?.orderReference}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        color="n-grey3"
                        fontWeight={500}
                        variant="n-text1"
                        style={{ textAlign: "right" }}
                      >
                        Date:
                      </Typography>
                      <Typography
                        fontWeight={400}
                        variant="n-text2"
                        style={{ textAlign: "right" }}
                      >
                        {formatDateValue(
                          orderSummary?.orderDate as unknown as Date
                        )}
                      </Typography>
                    </Box>
                  </Flex>
                </Box>

                <Box>
                  <Box>
                    <Typography
                      color="n-grey3"
                      fontWeight={500}
                      variant="n-text1"
                    >
                      Destination :
                    </Typography>
                    <Typography
                      fontWeight={400}
                      variant="n-text2"
                      style={{ maxWidth: "80%" }}
                    >
                      {tableOrRoomNumberDetails?.data.destination.includes("+")
                        ? tableOrRoomNumberDetails?.data.destination
                            .split("+")
                            .map((item, i) => `${item}${i == 1 ? `,` : "."} `)
                        : tableOrRoomNumberDetails?.data.destination}
                    </Typography>
                  </Box>

                  {orderSummary?.items.map((item: any) => (
                    <Flex
                      justifyContent={"space-between"}
                      my="10px"
                      key={item.id}
                    >
                      <Typography
                        color="n-grey3"
                        fontWeight={400}
                        style={{
                          fontSize: "14px",
                          lineHeight: "22px",
                        }}
                      >
                        x {item.quantity} {item.name}
                      </Typography>
                      <Typography
                        color="n-grey3"
                        fontWeight={400}
                        style={{
                          fontSize: "16px",
                          lineHeight: "22px",
                        }}
                      >
                        {formatCurrency(item.price as unknown as string, false)}
                      </Typography>
                    </Flex>
                  ))}
                  <TotalWrapper justifyContent={"space-between"} my="10px">
                    <Typography
                      color="n-grey3"
                      fontWeight={400}
                      style={{
                        fontSize: "14px",
                        lineHeight: "22px",
                      }}
                    >
                      Subtotal
                    </Typography>
                    <Typography
                      color="n-grey3"
                      fontWeight={400}
                      style={{
                        fontSize: "16px",
                        lineHeight: "22px",
                      }}
                    >
                      {formatCurrency(
                        orderSummary?.subTotalAmount as unknown as string,
                        false
                      )}
                    </Typography>
                  </TotalWrapper>
                  <TotalWrapper justifyContent={"space-between"} my="10px">
                    <Typography
                      color="n-grey3"
                      fontWeight={400}
                      style={{
                        fontSize: "14px",
                        lineHeight: "22px",
                      }}
                    >
                      Tax
                    </Typography>
                    <Typography
                      color="n-grey3"
                      fontWeight={400}
                      style={{
                        fontSize: "16px",
                        lineHeight: "22px",
                      }}
                    >
                      {formatCurrency(
                        orderSummary?.taxOnOrder as unknown as string,
                        false
                      )}
                    </Typography>
                  </TotalWrapper>
                  <TotalWrapper justifyContent={"space-between"} my="10px">
                    <Typography
                      color="n-dark"
                      fontWeight={400}
                      style={{
                        fontSize: "14px",
                        lineHeight: "22px",
                      }}
                    >
                      TOTAL
                    </Typography>
                    <Typography
                      color="n-dark"
                      fontWeight={500}
                      style={{
                        fontSize: "16px",
                        lineHeight: "22px",
                      }}
                    >
                      {formatCurrency(
                        orderSummary?.totalAmount as unknown as string,
                        false
                      )}
                    </Typography>
                  </TotalWrapper>
                </Box>
                <Box mt="50px">
                  <Flex justifyContent={"space-between"}>
                    <Typography variant="n-text4">Order timeline</Typography>
                    <RefreshButton
                      variant="xsmall"
                      disabled={
                        orderSummary?.status === "CANCELLED" ||
                        orderSummary?.status === "COMPLETED"
                      }
                      onClick={() => {
                        getOrderCombinedDetails();
                      }}
                    >
                      Refresh Status
                    </RefreshButton>
                  </Flex>

                  <Flex my="2" flexDirection={"column"}>
                    <TrackStatus>
                      <Checkbox
                        name={"received"}
                        checked={true}
                        value={"received"}
                        onChange={() => {}}
                      >
                        {null}
                      </Checkbox>
                      <Flex flexDirection={"column"} ml={"20px"}>
                        <Typography
                          fontWeight={500}
                          style={{
                            fontSize: "14px",
                            lineHeight: "22px",
                          }}
                        >
                          Order Received
                        </Typography>

                        <Typography
                          fontWeight={400}
                          style={{
                            fontSize: "10px",
                            lineHeight: "16px",
                          }}
                          color="n-grey3"
                        >
                          Waiting for waiter to confirm your order
                        </Typography>
                      </Flex>
                      <TrackerDash />
                    </TrackStatus>
                    {orderSummary?.status === "CANCELLED" ? (
                      <TrackStatus mt="70px">
                        <CancelledBox>x</CancelledBox>
                        <Flex flexDirection={"column"} ml={"20px"}>
                          <Typography
                            fontWeight={500}
                            style={{
                              fontSize: "14px",
                              lineHeight: "22px",
                            }}
                          >
                            Order Canceled
                          </Typography>

                          <Typography
                            fontWeight={400}
                            style={{
                              fontSize: "10px",
                              lineHeight: "16px",
                            }}
                            color="n-grey3"
                          >
                            Your order has been canceled.
                          </Typography>
                        </Flex>
                      </TrackStatus>
                    ) : (
                      <>
                        <TrackStatus mt="70px">
                          <Checkbox
                            name={"received"}
                            checked={
                              orderSummary?.status === "IN_PROGRESS" ||
                              orderSummary?.status === "COMPLETED"
                                ? true
                                : false
                            }
                            value={"received"}
                            onChange={() => {}}
                          >
                            {null}
                          </Checkbox>
                          <Flex flexDirection={"column"} ml={"20px"}>
                            <Typography
                              fontWeight={500}
                              style={{
                                fontSize: "14px",
                                lineHeight: "22px",
                              }}
                            >
                              Preparing your meal
                            </Typography>

                            <Typography
                              fontWeight={400}
                              style={{
                                fontSize: "10px",
                                lineHeight: "16px",
                              }}
                              color="n-grey3"
                            >
                              Restaurant is currently preparing your meal - 40
                              mins
                            </Typography>
                          </Flex>
                          <TrackerDash />
                        </TrackStatus>
                        <TrackStatus mt="60px">
                          <Checkbox
                            name={"received"}
                            checked={
                              orderSummary?.status === "COMPLETED"
                                ? true
                                : false
                            }
                            value={"received"}
                            onChange={() => {}}
                          >
                            {null}
                          </Checkbox>
                          <Flex flexDirection={"column"} ml={"20px"}>
                            <Typography
                              fontWeight={500}
                              style={{
                                fontSize: "14px",
                                lineHeight: "22px",
                              }}
                            >
                              Order delivered
                            </Typography>

                            <Typography
                              fontWeight={400}
                              style={{
                                fontSize: "10px",
                                lineHeight: "16px",
                              }}
                              color="n-grey3"
                            >
                              We hope you enjoy your meal leaving no crumb
                            </Typography>
                          </Flex>
                        </TrackStatus>
                      </>
                    )}
                  </Flex>
                </Box>
              </Box>
            </MainPageWrapper>
          ) : (
            <Flex
              mt="150px"
              flexDirection={"column"}
              justifyContent="center"
              alignItems={"center"}
            >
              <Typography variant="n-text4">
                No result found for this order
              </Typography>
            </Flex>
          )}
        </>
      )}
    </PageBody>
  );
};

export default OrderSummaryPageSetup;
