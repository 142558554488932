import { Box, Flex } from "rebass";
import { Input } from "@rebass/forms";
import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Button, Typography } from "kudi-component-library";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useOrderData } from "../state/context";
import { useMixPanel } from "../hooks/useMixpanel";
import BottomSheetModal from "./BottomSheetModal";
import { UnderlinedButton } from "./Styles";

const PlaceOrderButton = styled(Button)`
  width: 100%;
`;

const PlaceOrderInput = styled(Input)`
  margin-top: 5px;
  font-size: 70px !important;
  line-height: 38px !important;
  font-weight: 600;
  color: ${({ theme }) => theme.nColors["n-dark"]} !important;
  border: none !important;
  border-bottom: solid 1px ${({ theme }) => theme.nColors["n-grey1"]} !important;
  outline: none !important;
  &:hover. &:active,
  &:focus,
  &:focus-visible {
    border: none !important;
    outline: none !important;
  }
  /* do not group these rules */
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.nColors["n-grey1"]};
  }
  &:-moz-placeholder {
    /* FF 4-18 */
    color: ${({ theme }) => theme.nColors["n-grey1"]};
    opacity: 1;
  }
  &::-moz-placeholder {
    /* FF 19+ */
    color: ${({ theme }) => theme.nColors["n-grey1"]};
    opacity: 1;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: ${({ theme }) => theme.nColors["n-grey1"]};
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme }) => theme.nColors["n-grey1"]};
  }
  &::placeholder {
    /* modern browser */
    color: ${({ theme }) => theme.nColors["n-grey1"]};
  }
`;

interface EnterTableNumberProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
}

const EnterTableNumber = ({
  isOpen,
  setIsOpen,
  onClose,
}: EnterTableNumberProps) => {
  const { setOrderData, orderData } = useOrderData();
  const [tableNumberValue, setTableNumberValue] = useState<string>("");
  const { track } = useMixPanel();

  const onEnterTableNumber = () => {
    setOrderData({
      ...orderData,
      tableNumber: tableNumberValue,
      deliveryArea: undefined,
    });
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  };
  const onSkip = () => {
    setOrderData({ ...orderData, deliveryArea: undefined, tableNumber: "-" });
    setIsOpen(false);
    track("MENU_WAITER_CLICKS_SKIPS_TABLE_NUMBER");
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (orderData?.tableNumber && !orderData?.deliveryArea) {
      setTableNumberValue(orderData?.tableNumber);
    }
  }, []);

  const handlechange = (e: FormEvent<HTMLInputElement>) => {
    let newValue = e.currentTarget.value;
    setTableNumberValue(newValue);
  };

  return (
    <BottomSheetModal
      title="Table/Room Number"
      subTitle="Enter the table/Room number where you are located."
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Box mt={40} justifyContent="center" alignItems={"center"}>
        <PlaceOrderInput
          id="tableNumber"
          name="tableNumber"
          type="text"
          placeholder="00"
          value={tableNumberValue}
          onChange={(e) => {
            handlechange(e);
          }}
        />

        <Flex
          mt="50px"
          mb="20px"
          justifyContent={"center"}
          alignItems={"center"}
          style={{ gap: "15px" }}
        >
          <PlaceOrderButton
            variant="medium"
            disabled={!tableNumberValue}
            onClick={() => {
              onEnterTableNumber();
              track("MENU_CUSTOMER_SELECTS_TABLE_NUMBER");
            }}
          >
            Continue
          </PlaceOrderButton>
        </Flex>
        <Box
          my={"20px"}
          onClick={() => {
            onSkip();
          }}
        >
          <UnderlinedButton>Skip</UnderlinedButton>
        </Box>
      </Box>
    </BottomSheetModal>
  );
};

export default EnterTableNumber;
