import * as React from "react";
const NoOrdersIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={80}
    height={91}
    fill="none"
    {...props}
  >
    <circle cx={45.723} cy={34} r={34} fill="#EEE" />
    <rect
      width={39.28}
      height={54.944}
      x={23.974}
      y={27.123}
      fill="#FAFAFA"
      stroke="#8C8C8C"
      rx={4.5}
      transform="rotate(2.5 23.974 27.123)"
    />
    <rect
      width={41.28}
      height={56.944}
      x={0.814}
      y={23.956}
      fill="#FAFAFA"
      stroke="#8C8C8C"
      rx={5.5}
      transform="rotate(-7.5 .814 23.956)"
    />
    <path
      fill="#EEE"
      d="M2.028 29.344a5 5 0 0 1 4.304-5.61l30.02-3.952a5 5 0 0 1 5.61 4.304l1.028 7.8-39.936 5.258-1.026-7.8Z"
    />
    <rect
      width={1.678}
      height={5.594}
      x={49.996}
      y={20.807}
      fill="#8C8C8C"
      rx={0.839}
      transform="rotate(82.5 49.996 20.807)"
    />
    <rect
      width={1.678}
      height={5.594}
      x={34.668}
      y={11.539}
      fill="#8C8C8C"
      rx={0.839}
      transform="rotate(-7.5 34.668 11.54)"
    />
    <rect
      width={1.678}
      height={5.594}
      x={44.504}
      y={13.066}
      fill="#8C8C8C"
      rx={0.839}
      transform="rotate(37.5 44.504 13.066)"
    />
    <rect
      width={32.448}
      height={2.238}
      x={7.23}
      y={38.852}
      fill="#C6C6C6"
      rx={1.119}
      transform="rotate(-7.5 7.23 38.852)"
    />
    <rect
      width={32.448}
      height={2.238}
      x={8.691}
      y={49.943}
      fill="#C6C6C6"
      rx={1.119}
      transform="rotate(-7.5 8.691 49.943)"
    />
    <rect
      width={32.448}
      height={2.238}
      x={10.152}
      y={61.037}
      fill="#C6C6C6"
      rx={1.119}
      transform="rotate(-7.5 10.152 61.037)"
    />
    <rect
      width={11.748}
      height={2.238}
      x={7.816}
      y={43.289}
      fill="#EEE"
      rx={1.119}
      transform="rotate(-7.5 7.816 43.29)"
    />
    <rect
      width={11.748}
      height={2.238}
      x={9.273}
      y={54.381}
      fill="#EEE"
      rx={1.119}
      transform="rotate(-7.5 9.273 54.38)"
    />
    <rect
      width={11.748}
      height={2.238}
      x={10.734}
      y={65.475}
      fill="#EEE"
      rx={1.119}
      transform="rotate(-7.5 10.734 65.475)"
    />
  </svg>
);
export default NoOrdersIcon;
