import { Box, Flex } from "rebass";
import Modal from "./components/kds-modal";
import OrderStatusDropdown from "./components/order-status-dropdown";
import styled from "styled-components";
import { Button, NTheme as theme, Typography } from "kudi-component-library";
import WaiterCustomerInfo from "./components/waiter-customer-info";
import OrderItemsList from "./components/order-items-list";
import { useAppSelector } from "../../../state/store";
import { formatDateValue } from "../../../utils";
import useGetCustomerDetails from "../../../hooks/useGetCustomerDetails";
import {
  MenuListProduct,
  MenuOrderCustomerData,
} from "../../../types/MenuList";

const OrderDetail = styled(Typography)`
  font-weight: 500;
`;

const ModalTitle = ({
  date,
  orderReference,
}: {
  date: Date;
  orderReference: string;
}) => {
  return (
    <Flex alignItems="center" style={{ gap: "24px" }}>
      <Box>
        <Typography
          variant="n-text2"
          style={{ color: theme.nColors["n-grey3a"] }}
          as="span"
        >
          Order#:
        </Typography>
        <OrderDetail variant="n-text2" color="grey5" as="span">
          {orderReference}
        </OrderDetail>
      </Box>
      <OrderDetail variant="n-text2" color="grey5">
        {formatDateValue(date)}
      </OrderDetail>
    </Flex>
  );
};

export default function ChangeOrderStatusModal({
  showModal,
  closeModal,
  openConfirmationModal,
}: {
  showModal: boolean;
  closeModal: () => void;
  openConfirmationModal: () => void
}) {

  const { selectedOrder } = useAppSelector((state) => state.kds);

  const { customerDetails } = useGetCustomerDetails({
    customerId: selectedOrder?.customerId as string,
    useHeaders: false,
  });

  const isCompletedOrCancelled = selectedOrder?.status === "CANCELLED" || selectedOrder?.status === "COMPLETED"

  return (
    <Modal
      showModal={showModal}
      onClose={closeModal}
      title={
        <ModalTitle
          orderReference={selectedOrder?.orderReference as string}
          date={selectedOrder?.orderDate as unknown as Date}
        />
      }
      padding="0px"
      headerPadding="13px 24px"
      showHeaderSeperator
    >
      <Flex flexDirection="column">
        <OrderStatusDropdown />
        <WaiterCustomerInfo
          customerInfo={customerDetails as MenuOrderCustomerData}
          destination={selectedOrder?.destination as string}
        />
        <OrderItemsList items={selectedOrder?.items as MenuListProduct[]} />
        <Flex
          justifyContent="space-between"
          padding="20px 30px 24px 30px;"
          backgroundColor={theme.nColors["n-grey0"]}
          style={{ borderRadius: "0px 0px 2rem 2rem" }}
        >
          <Box>
            <Button variant="medium" color="tertiary" onClick={closeModal}>
              Cancel
            </Button>
          </Box>
          <Flex style={{ gap: "20px" }}>
            <Button variant="medium" color="tertiary" disabled>
              Print Order
            </Button>
            <Button variant="medium" color="primary"
              onClick={openConfirmationModal}
            >
              Save
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
}
