import React from "react";

const EmptyCheckIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.128 5.53805C16.2907 5.70077 16.2907 5.96459 16.128 6.12731L7.79463 14.4606C7.63191 14.6234 7.36809 14.6234 7.20537 14.4606L3.87204 11.1273C3.70932 10.9646 3.70932 10.7008 3.87204 10.5381C4.03476 10.3753 4.29858 10.3753 4.46129 10.5381L7.5 13.5768L15.5387 5.53805C15.7014 5.37534 15.9652 5.37534 16.128 5.53805Z"
        fill="white"
      />
    </svg>
  );
};

export default EmptyCheckIcon;
