import { OrderSummaryResponse } from "../types/MenuList";
import { menuServiceInit } from "../utils";

const fetchOrderDetails = async ({ orderId }: { orderId: string }) => {
  const url = `/webbe/v1/menu-orders/${orderId}`;
  try {
    const result: OrderSummaryResponse = await menuServiceInit.get(url);
    return await Promise.resolve({ error: null, result: result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default fetchOrderDetails;
