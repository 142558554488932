import * as React from "react";

const LocationIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_63069_183275)">
      <path
        d="M14.7127 13.8807C13.8138 14.7797 11.7849 16.8086 10.7054 17.8881C10.3149 18.2786 9.68294 18.279 9.29242 17.8885C8.23061 16.8267 6.24533 14.8414 5.28465 13.8807C2.68116 11.2772 2.68116 7.05612 5.28465 4.45262C7.88815 1.84913 12.1092 1.84913 14.7127 4.45262C17.3162 7.05612 17.3162 11.2772 14.7127 13.8807Z"
        stroke="#121212"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4987 9.16667C12.4987 10.5474 11.3794 11.6667 9.9987 11.6667C8.61799 11.6667 7.4987 10.5474 7.4987 9.16667C7.4987 7.78596 8.61799 6.66667 9.9987 6.66667C11.3794 6.66667 12.4987 7.78596 12.4987 9.16667Z"
        stroke="#121212"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_63069_183275">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LocationIcon;
