import HomePage from "./home-page";
import MenuListPage from "./menu-list-page";
import Success from "./success";
import OrderSummary from "./order-summary";
import OrderDetails from "./order-details";
import OrderMealsDetails from "./order-meal-details";
import BankTransfer from "./bank-transfer";
import BankTransferCountdown from "./bank-transfer-countdown";
import DetailsPage from "./details";
import WaiterOrderList from "./waiter/waiter-order-list";
import WaiterMenuList from "./waiter/waiter-menu-list";
import WaiterFullOrderList from "./waiter/waiter-full-order-list";
import WaiterOrderDetails from "./waiter/waiter-order-details";
import WaiterSearch from "./waiter/waiter-search";
import WaiterEditOrder from "./waiter/waiter-edit-order";
import KdsOverview from "./kds";

export {
  HomePage,
  MenuListPage,
  Success,
  OrderSummary,
  OrderDetails,
  BankTransfer,
  BankTransferCountdown,
  OrderMealsDetails,
  DetailsPage,
  WaiterOrderList,
  WaiterMenuList,
  WaiterOrderDetails,
  WaiterFullOrderList,
  WaiterSearch,
  WaiterEditOrder,
  KdsOverview,
};
