import menuServiceInit from "./menuServiceInit";
import dayjs from "dayjs";

export const capitalizeFirstLetter = (value: string) => {
  const lowercaseWord = value.toLowerCase();

  return lowercaseWord.charAt(0).toUpperCase() + lowercaseWord.slice(1);
};

export const formatCurrency = (num: number | string, space = true) => {
  const localNumber = typeof num === "string" ? parseInt(num) : num;

  return Math.sign(localNumber || 0) === -1
    ? `-₦${space ? ` ` : ""}${localNumber
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
        .slice(1)}`
    : `₦${space ? ` ` : ""}${localNumber
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
};

export const formatDateValue = (date: Date) =>
  dayjs(date).add(1, "hour").format("MMM DD, YYYY  hh:mm a");

export const convertHexToRGBA = (hexCode: string, opacity?: number) => {
  let hex = hexCode.replace("#", "");
  let localOpacity = opacity || 1;

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (localOpacity > 1 && localOpacity <= 100) {
    localOpacity /= 100;
  }

  return `rgba(${r},${g},${b},${localOpacity})`;
};

export { menuServiceInit };
