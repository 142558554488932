import { Typography } from "kudi-component-library";
import { Flex } from "rebass";
import styled from "styled-components";
import { MenuOrderCustomerData } from "../../../../types/MenuList";

const WaiterCustomerInfoContainer = styled(Flex)`
  padding: 16px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: ${({ theme }) => theme.nColors["n-grey0"]};
`;

const WCValue = styled(Typography)`
  font-weight: 500;
`;

const WCRow = styled(Flex)`
  gap: 64px;
`;

export default function WaiterCustomerInfo({
  customerInfo,
  destination,
}: {
  customerInfo: MenuOrderCustomerData;
  destination: string;
}) {
  const waiterCustomerSchema = [
    {
      text: "Name",
      value: customerInfo?.data?.fullName,
    },
    { text: "Phone Number", value: customerInfo?.data?.phoneNumber },
    { text: "Table No", value: `Table ${destination}` },
  ];
  return (
    <WaiterCustomerInfoContainer>
      <Typography variant="n-text2" fontWeight="heavy">
        Waiter/Customer
      </Typography>
      {waiterCustomerSchema.map((item) => (
        <WCRow key={item.text}>
          <Typography
            variant="n-text1"
            color="grey5"
            style={{ width: "120px" }}
          >
            {item.text}:
          </Typography>
          <WCValue variant="n-text1">{item.value}</WCValue>
        </WCRow>
      ))}
    </WaiterCustomerInfoContainer>
  );
}
