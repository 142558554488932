import { Box } from "rebass";
import styled from "styled-components";

const BootomSheetIncDecWrapper = styled(Box)<{ buttonSize?: string }>`
  & button {
    height: ${({ buttonSize }) => (buttonSize ? buttonSize : "30px")};
    width: ${({ buttonSize }) => (buttonSize ? buttonSize : "30px")};
    border: 1px solid ${({ theme }) => theme.nColors["n-dark"]};
    border-radius: 4px;
    background: ${({ theme }) => theme.nColors["n-light"]};
    color: ${({ theme }) => theme.nColors["n-dark"]};
    &:disabled {
      color: ${({ theme }) => theme.nColors["n-grey2"]};
      border: 1px solid ${({ theme }) => theme.nColors["n-grey2"]};
    }
  }
  & span {
    margin-left: 10px;
    margin-right: 10px;
    color: ${({ theme }) => theme.nColors["n-dark"]};
    font-size: 16px;
  }
`;

interface BottomSheetIncProps {
  quantity: number;
  buttonSize?: string;
  decreaseQuantity: () => void;
  increaseQuantity: () => void;
}

const BottomSheetIncDec = ({
  decreaseQuantity,
  quantity,
  buttonSize,
  increaseQuantity,
}: BottomSheetIncProps) => {
  return (
    <BootomSheetIncDecWrapper buttonSize={buttonSize}>
      <button onClick={() => decreaseQuantity()} disabled={quantity <= 1}>
        -
      </button>
      <span>{quantity}</span>
      <button onClick={() => increaseQuantity()}>+</button>
    </BootomSheetIncDecWrapper>
  );
};

export default BottomSheetIncDec;
