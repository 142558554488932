import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fetchBankTransferDetails } from "../../queries";
import { MenuAccountDetailsBody } from "../../types/MenuList";
import { useMixPanel } from "../../hooks/useMixpanel";

const useBankTransferPage = (menuId: string | null) => {
  const [transferDetails, setTransferDetails] =
    useState<MenuAccountDetailsBody[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);
  const { track } = useMixPanel();

  const handleRefetch = () => setRefetch(!refetch);

  const handlefetchBankTransferDetails = async () => {
    setIsLoading(true);
    const { result, error: fetchError } = await fetchBankTransferDetails({
      menuId: menuId as unknown as number,
    });
    setIsLoading(false);
    if (result) {
      track("MENU_CUSTOMER_FETCH_ACCOUNT_DETAILS");
      setTransferDetails(result.data);
    }
    if (fetchError){
      track("MENU_CUSTOMER_ERROR_FETCHING_ACCOUNT_DETAILS", {error: JSON.stringify(fetchError)});
      toast.error("An error occured while fetching account details");
    }
  };

  useEffect(() => {
    if (!menuId) return;
    const request = new AbortController();
    handlefetchBankTransferDetails();
    return () => request.abort();
  }, [menuId]);

  return {
    transferDetails,
    isLoading,
    handleRefetch,
  };
};

export default useBankTransferPage;
