import { Box, Flex } from "rebass";
import { Dispatch, SetStateAction, useState } from "react";
import {
  Button,
  IconsEnum,
  NIcons,
  Radio,
  Typography,
} from "kudi-component-library";
import styled from "styled-components";
import BottomSheetModal from "./BottomSheetModal";

const PlaceOrderButton = styled(Button)`
  width: 100%;
`;

const IconElipse = styled(Flex)<{ active?: boolean }>`
  height: 60px;
  width: 60px;
  background: ${({ theme, active }) =>
    active ? theme.nColors["n-yellow"] : theme.nColors["n-grey1"]};
  border-radius: 50%;
`;

const SelectOrderTypeBody = styled(Flex)<{ active?: boolean }>`
  background: ${({ theme, active }) =>
    active ? theme.nColors["n-light"] : theme.nColors["n-grey0"]};
  border: solid 1px
    ${({ theme, active }) =>
      active ? theme.nColors["n-yellow"] : theme.nColors["n-grey1"]};
  border-radius: 10px;
`;

interface EnterOrderTypeModalProps {
  isOpen: boolean;
  selectDelivery: () => void;
  selectDineIn: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const EnterOrderTypeModal = ({
  isOpen,
  setIsOpen,
  selectDelivery,
  selectDineIn,
}: EnterOrderTypeModalProps) => {
  const [orderStatus, setOrderStatus] = useState<"dineIn" | "delivery">(
    "dineIn"
  );

  return (
    <BottomSheetModal
      title="Choose preferred service"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Box>
        <SelectOrderTypeBody
          p="20px"
          mb={"20px"}
          active={orderStatus === "dineIn"}
          onClick={() => {
            setOrderStatus("dineIn");
          }}
        >
          <Flex style={{gap: "10px"}}>
            <Box  width={'40%'}>
              <IconElipse
                justifyContent={"center"}
                alignItems={"center"}
                active={orderStatus === "dineIn"}
              >
                <NIcons icon={IconsEnum.OUTLINE_SHOP_1} />
              </IconElipse>
            </Box>
            <Box>
              <Flex justifyContent={"space-between"} mb={"5px"}>
                <Typography
                  fontWeight={700}
                  style={{
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  Dine-in
                </Typography>
                <Radio
                  defaultChecked={orderStatus === "dineIn"}
                  name="radio"
                  value="dineIn"
                >
                  {null}
                </Radio>
              </Flex>
              <Typography color="n-grey3" variant="n-text1">
                For customers ordering in the store, from a hotel room or to
                pick up their item.
              </Typography>
            </Box>
          </Flex>
        </SelectOrderTypeBody>
        <SelectOrderTypeBody
          p="20px"
          active={orderStatus === "delivery"}
          onClick={() => {
            setOrderStatus("delivery");
          }}
        >
          <Flex style={{gap: "10px"}}>
            <Box width={'40%'}>
              <IconElipse
                justifyContent={"center"}
                alignItems={"center"}
                mr={"20px"}
                active={orderStatus === "delivery"}
              >
                <NIcons icon={IconsEnum.OUTLINE_HOME} />
              </IconElipse>
            </Box>
            <Box>
              <Flex justifyContent={"space-between"} mb={"5px"}>
                <Typography
                  fontWeight={700}
                  style={{
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  Delivery
                </Typography>
                <Radio
                  defaultChecked={orderStatus === "delivery"}
                  name="radio"
                  value="delivery"
                >
                  {null}
                </Radio>
              </Flex>
              <Typography color="n-grey3" variant="n-text1">
                For customers who want their meals delivered to their homes,
                offices, etc
              </Typography>
            </Box>
          </Flex>
        </SelectOrderTypeBody>

        <Flex
          my="20px"
          justifyContent={"center"}
          alignItems={"center"}
          style={{ gap: "15px" }}
        >
          <PlaceOrderButton
            variant="medium"
            onClick={() => {
              if (orderStatus === "delivery") {
                selectDelivery();
                setIsOpen(false);
                return;
              }
              selectDineIn();
              setIsOpen(false);
            }}
          >
            Proceed
          </PlaceOrderButton>
        </Flex>
      </Box>
    </BottomSheetModal>
  );
};

export default EnterOrderTypeModal;
