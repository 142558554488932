import { fetchStationOrdersResponse } from "../../types/stations";
import { menuServiceInit } from "../../utils";

const fetchStationOrders = async ({
  stationId,
  accessToken,
  params,
}: {
  stationId: string;
  params?: URLSearchParams;
  accessToken: string;
}) => {
  const url = `/webbe/v1/menu-stations/${stationId}/filter`;
  try {
    const result: fetchStationOrdersResponse = await menuServiceInit.get(url, {
      headers: {
        "iam-authorization": `Bearer ${accessToken}`,
        authorization: `Bearer ${accessToken}`,
      },
      params,
    });
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default fetchStationOrders;
