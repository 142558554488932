import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../state/store";
import { OrderSummaryBody } from "../../../types/MenuList";
import { setSelectedOrder } from "../../../state/kds/slice";
import useUpdateOrderStatus from "./useUpdateOrderStatus";
import useFetchMenuStationOrders from "./useFetchMenuStationOrders";
import { StationType } from "../../../types/stations";
import useFilterKdsOrders from "./useFilterKdsOrders";

export default function useKdsOrderCardModel() {

  const dispatch = useAppDispatch();

  const { proposedOrderStatus, selectedStation } = useAppSelector((state) => state.kds);

  const { params } = useFilterKdsOrders()

  const [showOrderModal, setShowOrderModal] = useState<boolean>(false);

  const [showCompleteOrderModal, setShowCompleteOrderModal] =
    useState<boolean>(false);

  const [showCancelOrderModal, setShowCancelOrderModal] =
    useState<boolean>(false);


  const { handleFetchStationOrders } = useFetchMenuStationOrders();

  const { changeMenuOrderStatus } = useUpdateOrderStatus({
    onSuccess: () => {
      handleFetchStationOrders(selectedStation as StationType, params);
      dispatch(setSelectedOrder(null));
      setShowOrderModal(false);
    },
    onFailure: () => {
      dispatch(setSelectedOrder(null));
      setShowOrderModal(false);
    }
  })



  const openOrderModal = (order: OrderSummaryBody) => {
    dispatch(setSelectedOrder(order));
    setShowOrderModal(true);
  };
  const closeOrderModal = () => {
    dispatch(setSelectedOrder(null));
    setShowOrderModal(false);
  };

  const openCompleteOrderModal = () => setShowCompleteOrderModal(true);
  const closeCompleteOrderModal = () => setShowCompleteOrderModal(false);

  const openCancelOrderModal = () => setShowCancelOrderModal(true);
  const closeCancelOrderModal = () => setShowCancelOrderModal(false);

  const saveUpdateChanges = () => {
    if (proposedOrderStatus) {
      switch (proposedOrderStatus) {
        case "COMPLETED":
          openCompleteOrderModal();
          break;
        case "CANCELLED":
          openCancelOrderModal();
          break;
        default:
          changeMenuOrderStatus();
          break;
      }
    };
    setShowOrderModal(false);
  }

  return {
    showOrderModal,
    openOrderModal,
    closeOrderModal,
    showCompleteOrderModal,
    openCompleteOrderModal,
    closeCompleteOrderModal,
    showCancelOrderModal,
    openCancelOrderModal,
    closeCancelOrderModal,
    saveUpdateChanges,
  };
}
