import {
  NavFilterWrapper,
  NavFilterHeaderWrapper,
  NavContextMenuWrapper,
  NavContextMenuItem,
} from "./styles";
import { Typography } from "kudi-component-library";
import FilterDropdownIcon from "../Icons/FilterDropdownIcon";
import CheckIcon from "../Icons/CheckIcon";
import EmptyCheckIcon from "../Icons/EmptyCheckIcon";
import { Box } from "rebass";
import useNavFilterModel from "./useNavFilterModel";

const NavbarFilter = () => {
  const {
    selectedStation,
    dropdownRef,
    dropdownButtonRef,
    showDropdown,
    setShowDropdown,
    handleSelectFilter,
    stations,
  } = useNavFilterModel();

  return stations.length ? (
    <NavFilterWrapper>
      <NavFilterHeaderWrapper
        ref={dropdownButtonRef}
        onClick={() => setShowDropdown((state) => !state)}
      >
        <Typography variant="n-text5" fontWeight="light" as="div">
          {selectedStation?.name}
        </Typography>
        <Box>
          <FilterDropdownIcon />
        </Box>
      </NavFilterHeaderWrapper>
      {showDropdown ? (
        <NavContextMenuWrapper ref={dropdownRef}>
          {stations.map((station) => {
            const isSelected = selectedStation?.name === station.name;
            return (
              <NavContextMenuItem
                key={`${station.name}${Math.random()}`}
                selected={isSelected}
                onClick={() => handleSelectFilter(station)}
              >
                {isSelected ? <CheckIcon /> : <EmptyCheckIcon />}
                <Typography variant="n-text2">{station.name}</Typography>
                {/* {schema?.icon} */}
              </NavContextMenuItem>
            );
          })}
        </NavContextMenuWrapper>
      ) : null}
    </NavFilterWrapper>
  ) : null;
};

export default NavbarFilter;
