import { IconsEnum, NIcons, NTheme as theme } from "kudi-component-library";
import React, { ReactNode } from "react";
import {
  ModalWrapper,
  ModalBackground,
  ModalContentWrapper,
  ModalContent,
  ModalClose,
} from "./styles";
import { Flex } from "rebass";

export interface ModalProps {
  showModal: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string | ReactNode;
  noCloseIcon?: boolean;
  customDesktopWidth?: string;
  customTabletWidth?: string;
  modalContentStyles?: React.CSSProperties;
  padding?: string;
  closeBg?: string;
  padCloseButton?: boolean;
  maxHeight?: string;
  headerPadding?: string;
  showHeaderSeperator?: boolean;
}

const Content = ({
  onClose,
  showModal,
  children,
  title,
  customDesktopWidth,
  customTabletWidth,
  modalContentStyles,
  padding,
  closeBg,
  noCloseIcon,
  padCloseButton,
  maxHeight,
  headerPadding,
  showHeaderSeperator,
}: ModalProps) =>
  showModal ? (
    <ModalWrapper>
      <ModalBackground onClick={onClose}>
        <ModalContentWrapper
          customTabletWidth={customTabletWidth}
          customDesktopWidth={customDesktopWidth}
          padding={padding}
          style={{ ...modalContentStyles }}
        >
          <ModalContent
            onClick={(e) => e.stopPropagation()}
            padding={padding}
            maxHeight={maxHeight}
          >
            <Flex
              display="flex"
              alignItems="center"
              justifyContent={title ? "space-between" : "flex-end"}
              style={{
                borderBottom: showHeaderSeperator
                  ? `1px solid ${theme.nColors["n-grey1"]}`
                  : undefined,
                padding: headerPadding,
              }}
            >
              {title ?? null}
              {noCloseIcon ? null : (
                <ModalClose
                  onClick={onClose}
                  closeBg={closeBg}
                  padCloseButton={padCloseButton}
                >
                  <NIcons height="20" width="20" icon={IconsEnum.OUTLINE_X} />
                </ModalClose>
              )}
            </Flex>
            {children}
          </ModalContent>
        </ModalContentWrapper>
      </ModalBackground>
    </ModalWrapper>
  ) : null;

const Modal = ({ ...props }: ModalProps) => <Content {...{ ...props }} />;

export default Modal;
