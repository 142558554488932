import { Box, Flex } from "rebass";
import {
  BackButton,
  Button,
  IconsEnum,
  NIcons,
  TextArea,
  Typography,
} from "kudi-component-library";
import styled from "styled-components";
import BottomSheetCustomerDetails from "../BottomSheetCustomerDetails";
import { useEffect, useState } from "react";
import BottomSheetIncDec from "../BottomSheetIncDec";
import { toast, ToastContentProps } from "react-toastify";
import { MainPageWrapper } from "../Layout";
import { useOrderData } from "../../state/context";
import { formatCurrency } from "../../utils";
import { Link, useNavigate } from "react-router-dom";
import { useMixPanel } from "../../hooks/useMixpanel";
import { MenuListProduct } from "../../types";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { EllipsedText, NotesBox, PriceSummarySection } from "../Styles";

const AddOrderButton = styled(Button)`
  color: ${({ theme }) => theme.nColors["n-dark"]};
  background: ${({ theme }) => theme.nColors["n-grey1"]};
  border-radius: 20px;
  height: 38px;
  text-decoration: none;
`;
const PlaceOrderButton = styled(Button)`
  width: 100%;
`;
const DeleteIcon = styled(Flex)`
  width: 50px;
  height: 40px;
  background: ${({ theme }) => theme.nColors["n-grey1"]};
  border-radius: 50%;
  margin-right: 10px !important;
  justify-content: center;
  align-items: center;
`;

const DescriptionText = styled(EllipsedText)`
  max-width: 80%;
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const OrderDetailsPageSetup = () => {
  const { orderData, setOrderData } = useOrderData();
  const navigate = useNavigate();
  const { track } = useMixPanel();

  const [savedOrder, setSavedOrder] = useLocalStorage("savedOrder");

  const [savedBusinessData] = useLocalStorage("savedBusinessData");
  const [fromTerminal] = useLocalStorage("fromTerminal");

  useEffect(() => {
    const navigatePage = () => {
      if (!orderData) {
        if (savedBusinessData) {
          navigate(
            `/${savedBusinessData?.alias}/${fromTerminal ? "waiter" : ""}`
          );
          return;
        }
        navigate(`/`);
        return;
      }
    };
    navigatePage();
  }, []);

  const [openBottomBar, setOpenBottomBar] = useState(false);
  let recentUndo: { meal: MenuListProduct; quantity: number }[] | undefined =
    undefined;

  const qtyMinusChanged = (selectedItem: any) => {
    let tempOrderData = orderData;

    let selectedProduct = tempOrderData?.selectedMeals?.map((item) => {
      if (item.meal.id === selectedItem.id) {
        if (item.quantity <= 1) {
          toast.error("Quantity can not be less than 1");
          return item;
        }
        item.quantity = item.quantity - 1;
      }

      return item;
    });

    setSavedOrder({ ...orderData, selectedMeals: selectedProduct });
    setOrderData({ ...orderData, selectedMeals: selectedProduct });
  };
  const qtyPlusChanged = (selectedItem: any) => {
    let tempOrderData = orderData;

    let selectedProduct = tempOrderData?.selectedMeals?.map((item) => {
      if (item.meal.id === selectedItem.id) {
        if (item.quantity >= 10) {
          toast.error("Quantity can not be greater than 10");
          return item;
        }
        item.quantity = item.quantity + 1;
      }

      return item;
    });

    setSavedOrder({ ...orderData, selectedMeals: selectedProduct });
    setOrderData({ ...orderData, selectedMeals: selectedProduct });
  };

  const onUndo = () => {
    setSavedOrder({ ...orderData, selectedMeals: recentUndo });
    setOrderData({ ...orderData, selectedMeals: recentUndo });
  };

  const RenderItemDeleteToast = ({ closeToast }: ToastContentProps) => (
    <Flex>
      <DeleteIcon>
        <NIcons icon={IconsEnum.OUTLINE_TRASH} color="n-red" width="13" />
      </DeleteIcon>
      <Flex justifyContent={"space-between"} alignItems={"center"} width="100%">
        <Typography
          color="n-dark"
          style={{
            fontSize: "16px",
            lineHeight: "22px",
            fontWeight: "400",
          }}
        >
          Item removed!
        </Typography>
        <Typography
          color="n-dark"
          style={{
            fontSize: "14px",
            lineHeight: "22px",
            fontWeight: "400",
          }}
        >
          <span
            onClick={() => {
              if (closeToast) {
                onUndo();
                closeToast();
              }
            }}
          >
            Undo
          </span>
        </Typography>
      </Flex>
    </Flex>
  );

  const deleteItem = (selectedItem: any) => {
    let tempOrderData = orderData;

    recentUndo = orderData?.selectedMeals;
    let selectedProduct = tempOrderData?.selectedMeals?.filter(
      (item) => item.meal.id !== selectedItem.id
    );

    setSavedOrder({ ...orderData, selectedMeals: selectedProduct });
    setOrderData({ ...orderData, selectedMeals: selectedProduct });

    toast(RenderItemDeleteToast);
  };

  const getSelectedMealTotalPrice = () => {
    let totalPrice = 0;
    let delivery = 0;
    orderData?.selectedMeals?.forEach((item) => {
      totalPrice = totalPrice + item.meal.price * item.quantity;
    });

    if (orderData?.deliveryArea) {
      delivery = Number(orderData?.deliveryArea?.price);
    }

    // const vat = (7.5 / 100) * totalPrice;

    const totalAmount = delivery + totalPrice;

    return {
      totalPrice,
      // vat,
      delivery,
      totalAmount,
    };
  };

  const addAdditionalNotes = (notes: string) => {
    setOrderData({
      ...orderData,
      additionalNotes: notes,
    });
  };

  return (
    <MainPageWrapper>
      <div className="pageSetup">
        <Flex py="30px" px="20px" flexDirection="column">
          <Flex>
            <span
              style={{
                textDecoration: "none",
              }}
              onClick={() => {
                track("MENU_CUSTOMER_CLICKS_BACK_BUTTON_FROM_ORDER_DETAILS");
                navigate(-1);
              }}
            >
              <BackButton
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  textDecoration: "none",
                }}
              >
                Back
              </BackButton>
            </span>
          </Flex>
          <Flex
            justifyContent={"space-between"}
            alignItems="center"
            mb="20px"
            width="100%"
          >
            <Typography color="n-grey4" variant="n-text4">
              Your Order ({orderData?.selectedMeals?.length})
            </Typography>
            {orderData &&
              orderData?.selectedMeals &&
              orderData?.selectedMeals?.length > 0 && (
                <Link
                  to={`/`}
                  style={{ textDecoration: "none" }}
                  onClick={() =>
                    track(
                      "MENU_CUSTOMER_CLICKS_ADD_NEW_ITEM_FROM_ORDER_DETAILS"
                    )
                  }
                >
                  <AddOrderButton variant="medium" color="secondary">
                    + Add new item
                  </AddOrderButton>
                </Link>
              )}
          </Flex>
          <Box>
            {orderData &&
              orderData.selectedMeals &&
              orderData?.selectedMeals?.length > 0 ? (
              orderData.selectedMeals.map((item, index) => (
                <>
                  <Box mb="30px" key={item.meal.id}>
                    <Flex justifyContent={"space-between"} mt="20px">
                      <EllipsedText variant="n-text3" fontWeight="heavy">
                        {item.meal.name}
                      </EllipsedText>
                      <Button
                        variant="xsmall"
                        style={{ background: "transparent" }}
                        onClick={() => deleteItem(item.meal)}
                      >
                        <NIcons
                          icon={IconsEnum.OUTLINE_TRASH}
                          color="n-red"
                          width="13"
                        />
                      </Button>
                    </Flex>
                    <Flex>
                      <DescriptionText variant="n-text2">
                        {item.meal.description}
                      </DescriptionText>
                    </Flex>
                    <Flex
                      justifyContent={"space-between"}
                      mb="10px"
                      mt="10px"
                      alignItems="end"
                    >
                      <BottomSheetIncDec
                        buttonSize={"22px"}
                        decreaseQuantity={() => qtyMinusChanged(item.meal)}
                        quantity={item.quantity}
                        increaseQuantity={() => qtyPlusChanged(item.meal)}
                      />

                      <Typography variant="n-text3" fontWeight={500}>
                        {formatCurrency(
                          (item.meal.price *
                            item.quantity) as unknown as string,
                          false
                        )}
                      </Typography>
                    </Flex>
                  </Box>

                </>
              ))
            ) : (
              <Flex
                mt="150px"
                flexDirection={"column"}
                justifyContent="center"
                alignItems={"center"}
              >
                <Typography variant="n-text4">No Orders found!</Typography>

                <Button
                  variant="medium"
                  style={{ marginTop: "20px", width: "100%" }}
                  onClick={() => navigate(-1)}
                >
                  View Meals
                </Button>
              </Flex>
            )}
          </Box>
          {orderData?.selectedMeals?.length ? (
            <PriceSummarySection>
              <Flex justifyContent={"space-between"} mb="5px">
                <Typography
                  color="n-grey4"
                  fontWeight={400}
                  style={{
                    fontSize: "14px",
                    lineHeight: "22px",
                  }}
                >
                  Subtotal
                </Typography>
                <Typography
                  color="n-dark"
                  fontWeight={400}
                  style={{
                    fontSize: "14px",
                    lineHeight: "22px",
                  }}
                >
                  {formatCurrency(
                    getSelectedMealTotalPrice().totalPrice as unknown as string,
                    false
                  )}
                </Typography>
              </Flex>
              {orderData?.deliveryArea && (
                <Flex justifyContent={"space-between"} mb="5px">
                  <Typography
                    color="n-grey4"
                    fontWeight={400}
                    style={{
                      fontSize: "14px",
                      lineHeight: "22px",
                    }}
                  >
                    Delivery
                  </Typography>
                  <Typography
                    color="n-dark"
                    fontWeight={400}
                    style={{
                      fontSize: "14px",
                      lineHeight: "22px",
                    }}
                  >
                    {formatCurrency(
                      getSelectedMealTotalPrice().delivery as unknown as string,
                      false
                    )}
                  </Typography>
                </Flex>
              )}
              <Flex justifyContent={"space-between"} mb="5px">
                <Typography
                  color="n-dark"
                  fontWeight={500}
                  style={{
                    fontSize: "14px",
                    lineHeight: "38px",
                  }}
                >
                  Total
                </Typography>
                <Typography
                  color="n-dark"
                  fontWeight={600}
                  style={{
                    fontSize: "14px",
                    lineHeight: "22px",
                  }}
                >
                  {formatCurrency(
                    getSelectedMealTotalPrice()
                      .totalAmount as unknown as string,
                    false
                  )}
                </Typography>
              </Flex>
              {orderData && orderData.selectedMeals && (
                <Box mt="20px">
                  <PlaceOrderButton
                    variant="medium"
                    onClick={() => {
                      track(
                        "MENU_CUSTOMER_CLICKS_PLACE_ORDER_TO_INPUT_CUSTOMER_DETAILS"
                      );
                      setOpenBottomBar(true);
                    }}
                  >
                    {" "}
                    Place order{" "}
                  </PlaceOrderButton>
                </Box>
              )}
            </PriceSummarySection>
          ) : null}
        </Flex>
      </div>

      <BottomSheetCustomerDetails
        setIsOpen={setOpenBottomBar}
        isOpen={openBottomBar}
      />
    </MainPageWrapper>
  );
};

export default OrderDetailsPageSetup;
