import { MenuOrderCustomerData } from "../types/MenuList";
import { menuServiceInit } from "../utils";

const fetchCustomerDetails = async (
  { customerId, useHeaders }: { customerId: string; useHeaders?: boolean },
  accessToken: string,
  businessId: string
) => {
  try {
    const result: MenuOrderCustomerData = await menuServiceInit.get(
      `/webbe/v1/customer/customer-ref/${customerId}`,
      {
        headers: useHeaders
          ? {
              "IAM-Authorization": `Bearer ${accessToken}`,
              businessId: businessId,
            }
          : {},
      }
    );
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default fetchCustomerDetails;
