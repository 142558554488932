import { NavWrapper } from "./styles";
import NombaLogo from "../Icons/NombaLogo";
import NavbarFilter from "./navbar-filter";
import NavbarActions from "./navbar-actions";

export default function Navbar() {
  return (
    <NavWrapper>
      <NombaLogo />
      <NavbarFilter />
      <NavbarActions />
    </NavWrapper>
  );
}
