import {
  KdsHeaderContentWrapper,
  KdsHeaderRow,
  KdsHeaderWrapper,
} from "./styles";
import { Typography } from "kudi-component-library";
import LocationIcon from "../Icons/LocationIcon";
import HeaderDateFilter from "./header-date-filter";
import { useAppSelector } from "../../state/store";
import { useOrderData } from "../../state/context";

const KdsHeader = () => {
  const { businessData } = useOrderData();
  const { menuInformation } = useAppSelector((state) => state.menu);

  return (
    <KdsHeaderWrapper>
      <KdsHeaderContentWrapper>
        <Typography variant="n-text4" fontWeight="light">
          {menuInformation?.menuStoreResponse?.storeName ||
            businessData?.menuStoreResponse?.storeName}
        </Typography>
        <KdsHeaderRow>
          <LocationIcon />
          <Typography variant="n-text4" fontWeight="light" color="n-grey4">
            {menuInformation?.menuStoreResponse?.storeLocation ||
              businessData?.menuStoreResponse?.storeLocation}
          </Typography>
        </KdsHeaderRow>
      </KdsHeaderContentWrapper>

      <KdsHeaderContentWrapper>
        <HeaderDateFilter />
      </KdsHeaderContentWrapper>
    </KdsHeaderWrapper>
  );
};

export default KdsHeader;
