import { createContext, useContext } from "react";
import { MenuDataBody, MenuListProduct } from "../types";
import { MenuCreatedResponse, MenuDeliveryLocations } from "../types/MenuList";

interface orderDataSetup {
  setOrderData: React.Dispatch<React.SetStateAction<{}>>;
  setBusinessData: React.Dispatch<
    React.SetStateAction<MenuDataBody | undefined>
  >;
  businessData?: MenuDataBody | undefined;
  orderReference?: MenuCreatedResponse | undefined;
  setOrderReference?: React.Dispatch<
    React.SetStateAction<MenuCreatedResponse | undefined>
  >;
  paidStatus?: boolean | undefined;
  setPaidStatus?: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  orderData?: {
    tableNumber?: string;
    deliveryArea?: MenuDeliveryLocations;
    selectedMeals?: { meal: MenuListProduct; quantity: number }[];
    customerName?: string;
    customerPhone?: string;
    additionalNotes?: string;
  };
}

export const OrderDataContext = createContext<orderDataSetup>({
  setBusinessData: () => {},
  setOrderData: () => {},
});

export const OrderDataContextProvider = OrderDataContext.Provider;

export const useOrderData = () => {
  return useContext(OrderDataContext);
};