import { BottomSheetBackdrop, PageLoaderWrapper } from "./styles";

const PageLoad = ({ height }: { height?: string }) => (
  <PageLoaderWrapper height={height}>
    <svg
      width="18"
      className="page-wrapper-loader"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.6958 9V13.0133L17.4047 18.0133V14.0133L8.6958 9Z"
        fill="#121212"
      />
      <path
        d="M17.3911 0L17.4045 4.00001L8.69556 9.00001V5.00001L17.3911 0Z"
        fill="#121212"
      />
      <path
        d="M0 1.98657V5.98658L8.69556 10.9999V6.98658L0 1.98657Z"
        fill="#121212"
      />
      <path d="M8.69556 11V15L0 20V16L8.69556 11Z" fill="#121212" />
    </svg>
    <BottomSheetBackdrop />
  </PageLoaderWrapper>
);

export default PageLoad;
