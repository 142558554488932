import { Box, Flex } from "rebass";
import { useEffect, useState } from "react";
import {
  BackButton,
  Button,
  IconsEnum,
  NIcons,
  Typography,
} from "kudi-component-library";
import styled from "styled-components";
import { MainPageWrapper } from "../Layout";
import { Category, MenuListProduct } from "../../types";
import { toast } from "react-toastify";
import { useOrderData } from "../../state/context";
import { formatCurrency } from "../../utils";
import BottomSheetIncDec from "../BottomSheetIncDec";
import {
  HeadBox,
  MealTypeSwitch,
  PriceSummarySection,
  SearchBarBox,
  SearchMealsInput,
} from "../Styles";
import CartImage from "../Icons/CartImage";
import { useNavigate } from "react-router-dom";
import BottomSheetCustomerDetails from "../BottomSheetCustomerDetails";
import BottomSheetMeal from "../BottomSheetMeal";

const MealsFlex = styled(Flex)`
  transition: all 0.15s ease;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.nColors["n-grey1"]};
  padding: 10px;
  cursor: pointer;
`;

const MenuList = styled.section`
  margin: 20px 0;
  width: 100%;
`;

const MealTypeBox = styled.div`
  background: ${({ theme }) => theme.nColors["n-light"]};
  border-bottom: 1px solid ${({ theme }) => theme.nColors["n-grey1"]};
  z-index: 2;
`;

const MealImageText = styled(Typography)`
  text-overflow: ellipsis;
  max-width: 80%;
  overflow: hidden;
  white-space: nowrap;
`;

const PageBody = styled(Flex)`
  width: 100%;
  @media (min-width: 1023px) {
    max-width: 500px;
  }
`;
const PlaceOrderButton = styled(Button)`
  width: 100%;
`;

const WaiterMenuListSetup = () => {
  const [activeMenu, setActiveMenu] = useState(0);
  const navigate = useNavigate();
  const [categories, setCategories] = useState<Category[]>([]);
  const [activeFood, setActiveFood] = useState<MenuListProduct>();
  const [showActiveScreen, setShowActiveScreen] = useState<"list" | "summary">(
    "list"
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [meals, setMeals] = useState<MenuListProduct[]>();
  const [searchMeals, setSearchMeals] = useState<MenuListProduct[]>();
  const [quantity, setQuantity] = useState<number>(1);
  const [openBottomBar, setOpenBottomBar] = useState(false);
  const [openMealBar, setOpenMealBar] = useState(false);

  const { orderData, businessData, setOrderData } = useOrderData();

  const clearSearch = () => {
    setSearchMeals(businessData?.items);
    setMeals(businessData?.items);
    setSearchValue("");
  };

  const categoriesFilter = (id: number, mealId?: number) => {
    if (id === 0) {
      setActiveMenu(id);
      clearSearch();
      return;
    }
    const newData = searchMeals?.filter(
      (item: MenuListProduct) => item.category.id === mealId
    );
    setMeals(newData);
    setActiveMenu(id);
  };

  const searchFilter = (text: string) => {
    categoriesFilter(0);
    const newData = searchMeals?.filter(
      (item: MenuListProduct) =>
        item.name.toUpperCase().indexOf(text.toUpperCase()) > -1
    );

    setMeals(newData);
    setSearchValue(text);
  };

  const addToCart = (activeFoodItem: any, quantityVal: number) => {
    setOrderData((prev: any) => {
      const foodData = { meal: activeFoodItem, quantity: quantityVal };

      let orderObj = {
        ...prev,
        selectedMeals: [{ ...foodData }],
      };

      if (prev && prev.selectedMeals) {
        const index = prev.selectedMeals.findIndex(
          (mealsVal: any) => mealsVal.meal.id === activeFoodItem.id
        );
        if (index !== -1) {
          const updatedMeals = [...prev.selectedMeals];
          updatedMeals[index] = { ...foodData };
          orderObj = {
            ...prev,
            selectedMeals: [...updatedMeals],
          };
        } else {
          orderObj = {
            ...prev,
            selectedMeals: [...prev?.selectedMeals, { ...foodData }],
          };
        }
      }
      return orderObj;
    });
  };

  function extractUniqueCategories(listProduct?: MenuListProduct[]) {
    const uniqueCategories: Category[] = [];

    if (listProduct) {
      listProduct.forEach((item) => {
        if (
          item.category.hasOwnProperty("id") &&
          !uniqueCategories.some((uniqs) => uniqs.id === item.category.id)
        ) {
          uniqueCategories.push(item.category);
        }
      });
    }

    return uniqueCategories;
  }

  useEffect(() => {
    if (businessData) {
      const uniqueCategoriesArray = extractUniqueCategories(
        businessData?.items
      );
      setMeals(businessData?.items);
      setSearchMeals(businessData?.items);
      setCategories(uniqueCategoriesArray);
    }
  }, [businessData]);

  let recentUndo: { meal: MenuListProduct; quantity: number }[] | undefined;

  const qtyMinusChanged = (selectedItem: MenuListProduct) => {
    const tempOrderData = orderData;

    const selectedProduct = tempOrderData?.selectedMeals?.map((item) => {
      if (item.meal.id === selectedItem.id) {
        if (item.quantity <= 1) {
          toast.error("Quantity can not be less than 1");
          return item;
        }
        item.quantity -= 1;
      }

      return item;
    });
    setOrderData({ ...orderData, selectedMeals: selectedProduct });
  };
  const qtyPlusChanged = (selectedItem: MenuListProduct) => {
    const tempOrderData = orderData;

    const selectedProduct = tempOrderData?.selectedMeals?.map((item) => {
      if (item.meal.id === selectedItem.id) {
        if (item.quantity >= 10) {
          toast.error("Quantity can not be greater than 10");
          return item;
        }
        item.quantity += 1;
      }

      return item;
    });

    setOrderData({ ...orderData, selectedMeals: selectedProduct });
  };

  const onUndo = () => {
    setOrderData({ ...orderData, selectedMeals: recentUndo });
  };

  const deleteItem = (selectedItem: MenuListProduct) => {
    const tempOrderData = orderData;

    recentUndo = orderData?.selectedMeals;
    const selectedProduct = tempOrderData?.selectedMeals?.filter(
      (item) => item.meal.id !== selectedItem.id
    );
    setOrderData({ ...orderData, selectedMeals: selectedProduct });
  };

  const getSelectedMealTotalPrice = () => {
    let totalPrice = 0;
    const delivery = 0;
    orderData?.selectedMeals?.forEach((item) => {
      totalPrice += item.meal.price * item.quantity;
    });

    // const vat = (7.5 / 100) * totalPrice;

    const totalAmount = delivery + totalPrice;

    return {
      totalPrice,
      // vat,
      delivery,
      totalAmount,
    };
  };

  const categoryRefs: { [key: string]: HTMLDivElement | null } = {};

  useEffect(() => {
    const handleScroll = () => {
      if (businessData) {
        const mealSections = document.querySelectorAll(".meal-category");
        const uniqueCategoriesArray = extractUniqueCategories(
          businessData.items
        );
        let currentCategoryInView = uniqueCategoriesArray[0]?.id;

        mealSections.forEach((section) => {
          const rect = section.getBoundingClientRect();
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            currentCategoryInView = Number(
              section.getAttribute("data-category")
            );
          }
        });

        setActiveMenu(currentCategoryInView);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [businessData]);

  const scrollToCategory = ({ name, id }: Category) => {
    if (categoryRefs !== null && categoryRefs[name] !== null) {
      categoryRefs[name]!.scrollIntoView({ behavior: "smooth", block: "end" });
      setActiveMenu(id);
    }
  };

  return (
    <MainPageWrapper>
      <div className="pageSetup">
        {showActiveScreen === "list" && (
          <Box p="30px" mb="200px">
            <HeadBox>
              <Flex justifyContent="space-between">
                <BackButton
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "none",
                  }}
                  onClick={() => navigate(-1)}
                >
                  Back
                </BackButton>
                <Box onClick={() => setShowActiveScreen("summary")}>
                  <CartImage />
                </Box>
              </Flex>
              <Box my="20px">
                <Typography variant="n-text5">Choose Menu</Typography>
              </Box>
              <SearchBarBox my="20px">
                <SearchMealsInput
                  placeholder="Search menu items.."
                  name="search"
                  type="text"
                  style={{ borderRadius: "30px", paddingLeft: "10px" }}
                  value={searchValue}
                  onChange={(e) => {
                    searchFilter(e.target.value);
                  }}
                />
              </SearchBarBox>
              {meals && meals.length > 0 && (
                <MealTypeBox>
                  <MealTypeSwitch
                    width={"100%"}
                    alignItems="center"
                    pb="20px"
                    px="5px"
                  >
                    {categories &&
                      categories.map((mealType, index) => (
                        <span
                          key={mealType.id}
                          className={
                            activeMenu === mealType.id ? "activeButton" : ""
                          }
                          onClick={() => scrollToCategory(mealType)}
                        >
                          {mealType.name}
                        </span>
                      ))}
                  </MealTypeSwitch>
                </MealTypeBox>
              )}
            </HeadBox>
            <MenuList>
              {categories && meals && meals.length > 0 ? (
                categories.map((mealType, index) => (
                  <div
                    key={mealType.id}
                    className="meal-category"
                    data-category={mealType.id}
                    ref={(ref) => {
                      categoryRefs[mealType.name] = ref;
                    }}
                  >
                    <Box my="20px">
                      <Typography color="n-grey4" fontWeight={700}>
                        {mealType.name}
                      </Typography>
                    </Box>
                    {meals
                      .filter(
                        (meal: MenuListProduct) =>
                          meal.category.id === mealType.id
                      )
                      .map((meal: MenuListProduct) => (
                        <MealsFlex
                          key={meal.id}
                          mb="10px"
                          onClick={() => {
                            setActiveFood(meal);
                            setOpenMealBar(true);
                          }}
                        >
                          <Flex flexDirection={"column"} width="100%">
                            <Box mb="5px">
                              <MealImageText
                                color="n-dark"
                                fontWeight={500}
                                fontSize="14px"
                              >
                                {meal.name}
                              </MealImageText>
                            </Box>
                            <Box mb="5px">
                              <MealImageText
                                variant="n-text1"
                                color="n-grey4"
                                fontWeight={400}
                                fontSize="12px"
                              >
                                {meal.description}
                              </MealImageText>
                            </Box>
                            <Box mb="5px">
                              <Typography
                                color="n-dark"
                                fontSize="12px"
                                fontWeight={500}
                              >
                                {formatCurrency(
                                  meal.price as unknown as string,
                                  false
                                )}
                              </Typography>
                            </Box>
                          </Flex>
                        </MealsFlex>
                      ))}
                  </div>
                ))
              ) : (
                <Flex
                  mt="150px"
                  flexDirection={"column"}
                  justifyContent="center"
                  alignItems={"center"}
                >
                  <Typography variant="n-text4">No result found!</Typography>
                  {searchValue && (
                    <Button
                      variant="medium"
                      color="secondary"
                      style={{ color: "white", marginTop: "20px" }}
                      onClick={() => clearSearch()}
                    >
                      Clear Search
                    </Button>
                  )}
                </Flex>
              )}
            </MenuList>

            {!!orderData?.selectedMeals?.length && (
              <PriceSummarySection>
                <Button
                  variant="medium"
                  style={{
                    width: "100%",
                  }}
                  onClick={() => {
                    setShowActiveScreen("summary");
                  }}
                >
                  Go to Checkout ({orderData?.selectedMeals?.length} items for ₦
                  {getSelectedMealTotalPrice().totalAmount})
                </Button>
              </PriceSummarySection>
            )}
          </Box>
        )}{" "}
        {showActiveScreen === "summary" && (
          <Flex justifyContent="center" py="30px">
            <PageBody flexDirection="column">
              <Box px="30px">
                <Flex>
                  <BackButton
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      textDecoration: "none",
                    }}
                    onClick={() => setShowActiveScreen("list")}
                  >
                    Back
                  </BackButton>
                </Flex>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  mb="20px"
                >
                  <Typography variant="n-text4">Order Summary</Typography>
                </Flex>
              </Box>
              <Box mb="300px" px="30px">
                {orderData &&
                orderData.selectedMeals &&
                orderData?.selectedMeals?.length > 0 ? (
                  orderData.selectedMeals.map(
                    (item: { meal: MenuListProduct; quantity: number }) => (
                      <Flex
                        pb="12px"
                        mb="12px"
                        key={item.meal.id}
                        style={{ borderBottom: "solid 1px #eee" }}
                      >
                        <Box width="100%">
                          <Flex justifyContent="space-between" mb="12px">
                            <Typography
                              color="n-dark"
                              fontWeight={500}
                              variant="n-text2"
                            >
                              {item.meal.name}
                            </Typography>

                            <Flex style={{ gap: "20px" }}>
                              <Box
                                style={{ cursor: "pointer" }}
                                onClick={() => deleteItem(item.meal)}
                              >
                                <NIcons
                                  color="n-grey3"
                                  width="16"
                                  icon={IconsEnum.OUTLINE_TRASH}
                                />
                              </Box>
                              <Box
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setActiveFood(item.meal);
                                  setOpenMealBar(true);
                                }}
                              >
                                <NIcons
                                  color="n-grey3"
                                  width="16"
                                  icon={IconsEnum.OUTLINE_EDIT_V}
                                />
                              </Box>
                            </Flex>
                          </Flex>
                          <Flex justifyContent="space-between">
                            <BottomSheetIncDec
                              decreaseQuantity={() =>
                                qtyMinusChanged(item.meal)
                              }
                              quantity={item.quantity}
                              buttonSize="20px"
                              increaseQuantity={() => qtyPlusChanged(item.meal)}
                            />

                            <Typography fontWeight="heavy" variant="n-text3">
                              {formatCurrency(
                                (item.meal.price *
                                  item.quantity) as unknown as string,
                                false
                              )}
                            </Typography>
                          </Flex>
                        </Box>
                      </Flex>
                    )
                  )
                ) : (
                  <Flex
                    mt="150px"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="n-text4">No Orders found!</Typography>

                    <Button
                      onClick={() => setShowActiveScreen("list")}
                      variant="medium"
                      style={{ marginTop: "20px", width: "100%" }}
                    >
                      View Meals
                    </Button>
                  </Flex>
                )}
              </Box>
              <PriceSummarySection>
                <Flex justifyContent="space-between" mb="5px">
                  <Typography
                    color="n-grey4"
                    fontWeight={400}
                    style={{
                      fontSize: "14px",
                      lineHeight: "22px",
                    }}
                  >
                    Subtotal
                  </Typography>
                  <Typography
                    color="n-dark"
                    fontWeight={400}
                    style={{
                      fontSize: "14px",
                      lineHeight: "22px",
                    }}
                  >
                    {formatCurrency(
                      getSelectedMealTotalPrice()
                        .totalPrice as unknown as string,
                      false
                    )}
                  </Typography>
                </Flex>
                <Flex justifyContent="space-between" mb="5px">
                  <Typography
                    color="n-dark"
                    fontWeight={500}
                    style={{
                      fontSize: "14px",
                      lineHeight: "38px",
                    }}
                  >
                    Total
                  </Typography>
                  <Typography
                    color="n-dark"
                    fontWeight={600}
                    style={{
                      fontSize: "14px",
                      lineHeight: "22px",
                    }}
                  >
                    {formatCurrency(
                      getSelectedMealTotalPrice()
                        .totalAmount as unknown as string,
                      false
                    )}
                  </Typography>
                </Flex>
                {orderData && orderData.selectedMeals && (
                  <Box mt="20px">
                    <PlaceOrderButton
                      variant="medium"
                      disabled={!orderData.selectedMeals.length}
                      onClick={() => {
                        setOpenBottomBar(true);
                      }}
                    >
                      Place order
                    </PlaceOrderButton>
                  </Box>
                )}
              </PriceSummarySection>
            </PageBody>
          </Flex>
        )}
      </div>

      <BottomSheetMeal
        isOpen={openMealBar}
        setIsOpen={setOpenMealBar}
        activeFood={activeFood}
        addToCart={addToCart}
        noImage
      />
      <BottomSheetCustomerDetails
        setIsOpen={(val) => {
          setShowActiveScreen("list");
          setOpenBottomBar(val);
        }}
        isOpen={openBottomBar}
      />
    </MainPageWrapper>
  );
};

export default WaiterMenuListSetup;
