import React from "react";

const DeleteAlertIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="32" cy="32" r="32" fill="#FFF4F2" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30 16C29.2425 16 28.5499 16.428 28.2111 17.1056L26.7639 20H20C18.8954 20 18 20.8954 18 22C18 23.1046 18.8954 24 20 24L20 44C20 46.2091 21.7909 48 24 48H40C42.2091 48 44 46.2091 44 44V24C45.1046 24 46 23.1046 46 22C46 20.8954 45.1046 20 44 20H37.2361L35.7889 17.1056C35.4501 16.428 34.7575 16 34 16H30ZM26 28C26 26.8954 26.8954 26 28 26C29.1046 26 30 26.8954 30 28V40C30 41.1046 29.1046 42 28 42C26.8954 42 26 41.1046 26 40V28ZM36 26C34.8954 26 34 26.8954 34 28V40C34 41.1046 34.8954 42 36 42C37.1046 42 38 41.1046 38 40V28C38 26.8954 37.1046 26 36 26Z"
      fill="#FF2200"
    />
  </svg>
);

export default DeleteAlertIcon;
