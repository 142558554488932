import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import {
  BankTransfer,
  BankTransferCountdown,
  DetailsPage,
  HomePage,
  MenuListPage,
  OrderDetails,
  OrderMealsDetails,
  OrderSummary,
  Success,
  WaiterOrderList,
  WaiterMenuList,
  WaiterFullOrderList,
  WaiterOrderDetails,
  WaiterSearch,
  KdsOverview,
} from "../pages";
import WaiterEditOrder from "../pages/waiter/waiter-edit-order";

const PageRoutes = () => (
  <Routes>
    <Route path={`/`} element={<HomePage />} />
    <Route path={`/:businessId`} element={<MenuListPage />} />

    <Route path={`/:businessId/waiter`}>
      <Route index element={<Navigate to="order-list" replace />} />
      <Route path={`order-list`} element={<WaiterOrderList />} />
      <Route path={`order-details/:orderId`} element={<WaiterOrderDetails />} />
      <Route path={`menu-list`} element={<WaiterMenuList />} />
      <Route path={`order-full-list`} element={<WaiterFullOrderList />} />
      <Route path={`order-search`} element={<WaiterSearch />} />
      <Route path={`edit-order/:orderId`} element={<WaiterEditOrder />} />
      <Route path={`*`} element={<Navigate to="order-list" replace />} />
    </Route>
    <Route path={`/:businessId/kds`} element={<KdsOverview />} />

    <Route path={`/:businessId/details`} element={<DetailsPage />} />
    <Route path={`/order-details`} element={<OrderDetails />} />
    <Route path={`/order-meal-details`} element={<OrderMealsDetails />} />
    <Route path={`/order-summary/:orderId`} element={<OrderSummary />} />
    <Route path={`/bank-transfer`} element={<BankTransfer />} />
    <Route path={`/success`} element={<Success />} />
    <Route
      path={`/bank-transfer-countdown`}
      element={<BankTransferCountdown />}
    />
    <Route path={`*`} element={<HomePage />} />
  </Routes>
);

export default PageRoutes;
