import styled from 'styled-components';

export const BottomSheetBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  transition: all 0.2s ease-in-out;
  opacity: 1;
  pointer-events: none;
`;

export const PageLoaderWrapper = styled.div<{ height?: string }>`
  width: 100%;
  height: ${({ height }) => height || '100%'};
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  svg.page-wrapper-loader {
    width: 75px;
    height: 75px;
    path {
      animation-name: changeSvgFill;
      animation-duration: 1.5s;
      animation-timing-function: ease-out;
      animation-direction: alternate;
      animation-iteration-count: infinite;
      animation-play-state: running;
    }
  }
  ${({ theme }) => `
  @keyframes changeSvgFill {
    0% {
      fill: ${theme.nColors['n-dark']};
      border-radius: 100%;
    }

    50% {
      fill: ${theme.nColors['n-grey2']};
    }

    100% {
      fill: ${theme.nColors['n-dark']};
    }
  }
  `}
`;
