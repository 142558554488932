import { Typography } from "kudi-component-library";
import styled, { css } from "styled-components";

export const KdsOverviewWrapper = styled.div`
  background-color: ${({ theme }) => theme.nColors["n-grey1"]};
  height: 100vh;
  overflow-y: hidden;
  display: flex;
  max-width: 1600px;
`;

export const KdsContentWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 94px 30px 0px;
  flex-direction: column;
`;

// kds header
export const KdsHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 23px;
  width: 100%;
`;

export const KdsHeaderDateText = styled(Typography)`
  font-weight: 500;
`;

export const KdsHeaderContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const KdsHeaderRow = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

// kds date filter

export const KdsDateFilterWrapper = styled.div`
  width: fit-content;
  position: relative;
`;

export const KdsDateFilterButtonWrapper = styled.div<{
  isButtonActive?: boolean;
}>`
  display: flex;
  cursor: pointer;
  padding: 14px 20px;
  gap: 10px;
  border-radius: 5px;
  border-color: 1px solid ${({ theme }) => theme.nColors["n-grey3"]};
  background-color: ${({ theme }) => theme.nColors["n-light"]};

  &:hover {
    svg {
      stroke-width: 2px;
    }
  }

  svg {
    stroke-width: ${({ isButtonActive = false }) =>
      isButtonActive ? "2px" : "1.5px"};
  }

  svg {
    ${({ isButtonActive = false }) => {
      return css`
        -webkit-transform: ${isButtonActive
          ? `rotate(180deg)`
          : `rotate(0deg)`};
        -moz-transform: ${isButtonActive ? `rotate(180deg)` : `rotate(0deg)`};
        -ms-transform: ${isButtonActive ? `rotate(180deg)` : `rotate(0deg)`};
        -o-transform: ${isButtonActive ? `rotate(180deg)` : `rotate(0deg)`};
        transform: ${isButtonActive ? `rotate(180deg)` : `rotate(0deg)`};
      `;
    }}
  }
`;

export const KdsDateWrapper = styled.div`
  display: flex;
  gap: 10px;
  alignitems: center;
  align-items: center;
  align-content: center;
`;

export const KdsDateFilterContextMenuWrapper = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  background-color: ${({ theme }) => theme.nColors["n-light"]};
  z-index: 1;
  top: 60px;
  width: 100%;
  border-radius: 5px;
`;

export const KdsDateFilterContextItem = styled.div`
  display: flex;
  padding: 10px 20px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.nColors["n-light"]};
  &:hover {
    background-color: ${({ theme }) => theme.nColors["n-grey2"]};
  }
  &:first-of-type {
    border-radius: 5px 5px 0px 0px;
  }
  &:last-of-type {
    border-radius: 0px 0px 5px 5px;
    border-bottom: none;
  }
`;
