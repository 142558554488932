import NProgress from 'nprogress';

// Configuration for nProgress Loader
NProgress.configure({ showSpinner: false });

class Loader {
  _counter: number;
  /**
   * Creates a loader off an nprogress instance and tracks the number of http requests made to efficiently toggle loader
   */

  constructor() {
    this._counter = 0;
  }

  show() {
    NProgress.start();
    this._counter += 1;
  }

  hide() {
    this._counter -= 1;
    if (this._counter === 0) {
      NProgress.done();
    }
  }
}

/**
 * The public singleton instance of the loader class.
 */
export default new Loader();
