import { MenuListResponse } from "../types";
import { menuServiceInit } from "../utils";

const fetchMenuListProducts = async ({
  businessId,
}: {
  businessId: number;
}) => {
  const url = `/webbe/v1/menus/alias/${businessId}`;
  try {
    const result: MenuListResponse = await menuServiceInit.get(url);
    return await Promise.resolve({ error: null, result: result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default fetchMenuListProducts;
