import React from "react";

const ExclaimationCircleIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="32" cy="32" r="32" fill="#FFF4F2" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M48 32C48 40.8366 40.8366 48 32 48C23.1634 48 16 40.8366 16 32C16 23.1634 23.1634 16 32 16C40.8366 16 48 23.1634 48 32ZM34 40C34 41.1046 33.1046 42 32 42C30.8954 42 30 41.1046 30 40C30 38.8954 30.8954 38 32 38C33.1046 38 34 38.8954 34 40ZM32 22C30.8954 22 30 22.8954 30 24V32C30 33.1046 30.8954 34 32 34C33.1046 34 34 33.1046 34 32V24C34 22.8954 33.1046 22 32 22Z"
      fill="#FF2200"
    />
  </svg>
);

export default ExclaimationCircleIcon;
