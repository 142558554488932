import { Box, Flex } from "rebass";
import { Typography } from "kudi-component-library";
import styled from "styled-components";
import PageLoad from "../PageLoad";
import { Link, useNavigate, useParams } from "react-router-dom";
import useMenuListProductsModel from "../MenuListPageSetup/useMenuListProducts";
import BackImage from "../Icons/BackImage";
import { MenuDataBody } from "../../types";
import { EllipsedText } from "../Styles";
import { formatCurrency } from "../../utils";

const MainPageWrapper = styled.main`
  min-height: 100vh;
  background: #fff;
  position: relative;
`;

const PageHeader = styled.header<{ coverImageUrl?: string }>`
  height: 200px;
  min-height: 100%;
  position: relative;
  ${({ coverImageUrl }) => {
    if (coverImageUrl) {
      return `background: url(${coverImageUrl});`;
    }
    return `background: url(/logo-background.png);`;
  }}
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
`;

const CartWrapper = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
`;

const MenuStoreTitle = styled(Flex)`
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background: ${({ theme }) => theme.nColors["n-light"]};
  border-bottom: 1px solid ${({ theme }) => theme.nColors["n-grey1"]};
`;

const DescriptionBox = styled(Flex)`
  background: ${({ theme }) => theme.nColors["n-grey0"]};
  padding: 20px;
`;

export default function DetailsPageSetup() {
  const navigate = useNavigate();
  const { businessId } = useParams();
  const { productBody, isLoading, handleRefetch } = useMenuListProductsModel(
    businessId as string
  );

  const daysOfWeek = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];

  const returnClosingTime = (body: MenuDataBody | undefined) => {
    const date = new Date();
    const todaysDay = date.getDay();
    let selectedClosingTime = "Store Closed";
    body?.businessHours.map((item) => {
      if (item.dayOfWeek === daysOfWeek[todaysDay - 1]) {
        selectedClosingTime = `Closes at  ${item.closeTime}`;
      }
    });

    return selectedClosingTime;
  };

  return (
    <>
      {!isLoading ? (
        <MainPageWrapper>
          <PageHeader
            coverImageUrl={productBody?.menuStoreResponse?.coverImageUrl}
          >
            <CartWrapper onClick={() => navigate(-1)}>
              <BackImage />
            </CartWrapper>
          </PageHeader>

          <Flex flexDirection="column" alignItems={"center"}>
            <MenuStoreTitle p="20px" flexDirection={"column"} width={"100%"}>
              <Typography variant="n-text4">
                {productBody?.menuStoreResponse?.storeName}
              </Typography>
              <Flex style={{ gap: 5 }}>
                <Flex
                  style={{ gap: 5 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_63069_183275)">
                      <path
                        d="M14.7127 13.8807C13.8138 14.7797 11.7849 16.8086 10.7054 17.8881C10.3149 18.2786 9.68294 18.279 9.29242 17.8885C8.23061 16.8267 6.24533 14.8414 5.28465 13.8807C2.68116 11.2772 2.68116 7.05612 5.28465 4.45262C7.88815 1.84913 12.1092 1.84913 14.7127 4.45262C17.3162 7.05612 17.3162 11.2772 14.7127 13.8807Z"
                        stroke="#121212"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.4987 9.16667C12.4987 10.5474 11.3794 11.6667 9.9987 11.6667C8.61799 11.6667 7.4987 10.5474 7.4987 9.16667C7.4987 7.78596 8.61799 6.66667 9.9987 6.66667C11.3794 6.66667 12.4987 7.78596 12.4987 9.16667Z"
                        stroke="#121212"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_63069_183275">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <Typography variant="n-text2" color="n-grey4">
                    {productBody?.menuStoreResponse?.storeLocation}
                  </Typography>
                </Flex>
                <Typography fontSize="20px">.</Typography>
                <Typography variant="n-text2" color="n-grey4">
                  {returnClosingTime(productBody)}
                </Typography>
              </Flex>
            </MenuStoreTitle>

            <DescriptionBox width={"100%"}>
              <Typography color="n-grey5" variant="n-text2">
                {productBody?.menuStoreResponse?.description}
              </Typography>
            </DescriptionBox>

            <Box width={"100%"} p={"20px"}>
              <Flex
                justifyContent={"space-between"}
                alignItems={"center"}
                my={"15px"}
                flexWrap={"wrap"}
              >
                <Box>
                  <Typography color="n-grey3" variant="n-text1">
                    Services
                  </Typography>
                  <Typography style={{ fontWeight: 500 }} variant="n-text2">
                    {productBody?.menuStoreResponse?.inHouseService &&
                      `Dine-in`}{" "}
                    {productBody?.menuStoreResponse?.inHouseService &&
                      productBody?.menuStoreResponse?.deliveryService &&
                      `&`}{" "}
                    {productBody?.menuStoreResponse?.deliveryService &&
                      `Delivery`}
                  </Typography>
                </Box>
                {productBody?.menuStoreResponse?.phoneNumber && (
                  <Box>
                    <Typography
                      color="n-grey3"
                      fontWeight={500}
                      variant="n-text1"
                    >
                      Phone Number
                    </Typography>
                    <Typography style={{ fontWeight: 500 }} variant="n-text2">
                      {productBody?.menuStoreResponse?.phoneNumber}
                    </Typography>
                  </Box>
                )}
              </Flex>
              {productBody?.businessHours.length && (
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  my={"15px"}
                  flexWrap={"wrap"}
                >
                  <Box>
                    <Typography
                      color="n-grey3"
                      fontWeight={500}
                      variant="n-text1"
                    >
                      Business Hours
                    </Typography>
                    <Flex flexWrap="wrap">
                      {productBody?.businessHours.map((item) => (
                        <Typography
                          fontWeight={400}
                          variant="n-text2"
                          style={{ fontWeight: 500 }}
                        >
                          {item.dayOfWeek.substring(0, 3)} ({item.openTime}–
                          {item.closeTime}),{" "}
                        </Typography>
                      ))}
                    </Flex>
                  </Box>
                </Flex>
              )}
              {productBody?.deliveryLocations.length && (
                <Box my={"15px"}>
                  <Box>
                    <Typography
                      color="n-grey3"
                      fontWeight={500}
                      variant="n-text1"
                    >
                      Delivery Location(s)
                    </Typography>
                    <Box>
                      {productBody?.deliveryLocations.map((item) => (
                        <Flex
                          justifyContent="space-between"
                          width="100%"
                          mb="5px"
                        >
                          <EllipsedText
                            style={{ width: "80%", fontWeight: 500 }}
                            variant="n-text2"
                          >
                            {item.area.split("+")[0]}, {item.area.split("+")[1]}
                          </EllipsedText>
                          <Typography
                            fontWeight="heavy"
                            variant="n-text2"
                            color="n-grey3a"
                          >
                            {formatCurrency(item.price)}
                          </Typography>
                        </Flex>
                      ))}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Flex>
        </MainPageWrapper>
      ) : (
        <PageLoad />
      )}
    </>
  );
}
