import { Box, Flex } from "rebass";
import { useState } from "react";
import { Button, IconsEnum, NIcons, Typography } from "kudi-component-library";
import styled from "styled-components";
import PageLoad from "../PageLoad";
import { useOrderData } from "../../state/context";
import {
  capitalizeFirstLetter,
  formatCurrency,
  formatDateValue,
} from "../../utils";
import { useNavigate, useParams } from "react-router-dom";
import { useMixPanel } from "../../hooks/useMixpanel";
import { MenuDataBody, OrderStatus } from "../../types/MenuList";
import {
  EllipsedText,
  OrderItem,
  OrderStatusBadge,
  ViewMoreButton,
} from "../Styles";
import useMenuListProductsModel from "../MenuListPageSetup/useMenuListProducts";
import { MainPageWrapper } from "../Layout";
import EnterTableNumber from "../EnterTableNumber";
import useWaiterOrderList from "./models/useWaiterOrderList";
import NoOrdersIcon from "../Icons/NoOrders";
import { useAuth0 } from "@auth0/auth0-react";
import WhiteLocationIcon from "../Icons/WhiteLocationIcon";
import Select from "../Select";
import { Oval } from "react-loader-spinner";

const PageHeader = styled.header<{ coverImageUrl?: string }>`
  height: 200px;
  min-height: 100%;
  position: relative;
  ${({ coverImageUrl }) => {
    if (coverImageUrl) {
      return `background: url(${coverImageUrl});`;
    }
    return `background: url(/logo-background.png);`;
  }}
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
`;

const CartWrapper = styled.div`
  background: ${({ theme }) => theme.nColors["n-grey0"]};
  border: solid 1px ${({ theme }) => theme.nColors["n-grey1"]};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
`;

const SearchWrapper = styled.div`
  background: ${({ theme }) => theme.nColors["n-grey0"]};
  border: solid 1px ${({ theme }) => theme.nColors["n-grey1"]};
  border-radius: 30px;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 6px 16px;
  cursor: pointer;
  min-width: 200px;
`;

const MenuFirstHead = styled(Flex)`
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background: ${({ theme }) => theme.nColors["n-light"]};
  border-bottom: 1px solid ${({ theme }) => theme.nColors["n-grey1"]};
  z-index: 2;
`;

const MenuStoreTitle = styled(Box)`
  background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
  padding: 20px 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export default function WaiterOrderList() {
  const navigate = useNavigate();
  const { businessId } = useParams();
  const [openTableOrTrackNumber, setOpenTableOrTrackNumber] = useState(false);
  const { productBody, isLoading } = useMenuListProductsModel(
    businessId as string
  );

  const { businessData } = useOrderData();

  const {
    orders,
    isLoading: isLoadingOrders,
    isLastPage,
    handleOnchangePagination,
    selectedFormat,
    handleSelect,
    reloadMode,
    handleScrollToTop,
  } = useWaiterOrderList();
  const { user } = useAuth0();

  const { track } = useMixPanel();

  const daysOfWeek = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];

  const returnClosingTime = (body: MenuDataBody | undefined) => {
    const date = new Date();
    const todaysDay = date.getDay();
    let selectedClosingTime = "Store Closed";
    body?.businessHours?.map((item) => {
      if (item.dayOfWeek === daysOfWeek[todaysDay - 1]) {
        selectedClosingTime = `Closes at  ${item.closeTime}`;
      }
    });

    return selectedClosingTime;
  };

  const returnOrderNames = (status: OrderStatus) => {
    let orderStatusVal: string = status;
    if (status === "INITIATED") {
      orderStatusVal = "New order";
    }
    if (status === "IN_PROGRESS") {
      orderStatusVal = "Processing";
    }
    return orderStatusVal;
  };

  return (
    <>
      {!isLoading ? (
        <MainPageWrapper>
          <div className="pageSetup">
            <MenuFirstHead
              bg="white"
              px="20px"
              py="10px"
              justifyContent="space-between"
              alignItems="center"
            >
              <EllipsedText style={{ maxWidth: "30%" }}>
                Hi, {user?.name}
              </EllipsedText>
              <Flex style={{ gap: "10px" }}>
                <SearchWrapper
                  onClick={() => {
                    track("MENU_CUSTOMER_USER_ORDER_SEARCH_FROM_HEADER");
                    navigate(`/${businessData?.alias}/waiter/order-search/`);
                  }}
                >
                  <NIcons
                    height="16"
                    width="16"
                    color="n-grey3a"
                    icon={IconsEnum.OUTLINE_SEARCH}
                  />
                  <Typography color="n-grey3a" variant="n-text1">
                    Search
                  </Typography>
                </SearchWrapper>
                <CartWrapper
                  onClick={() => {
                    track("MENU_CUSTOMER_USER_ORDER_FULL_LIST_FROM_HEADER");
                    navigate(`/${businessData?.alias}/waiter/order-full-list/`);
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2.91797 5.00065C2.91797 4.77053 3.10452 4.58398 3.33464 4.58398H16.668C16.8981 4.58398 17.0846 4.77053 17.0846 5.00065C17.0846 5.23077 16.8981 5.41732 16.668 5.41732H3.33464C3.10452 5.41732 2.91797 5.23077 2.91797 5.00065ZM2.91797 8.33398C2.91797 8.10387 3.10452 7.91732 3.33464 7.91732H16.668C16.8981 7.91732 17.0846 8.10387 17.0846 8.33398C17.0846 8.5641 16.8981 8.75065 16.668 8.75065H3.33464C3.10452 8.75065 2.91797 8.5641 2.91797 8.33398ZM2.91797 11.6673C2.91797 11.4372 3.10452 11.2507 3.33464 11.2507H16.668C16.8981 11.2507 17.0846 11.4372 17.0846 11.6673C17.0846 11.8974 16.8981 12.084 16.668 12.084H3.33464C3.10452 12.084 2.91797 11.8974 2.91797 11.6673ZM2.91797 15.0007C2.91797 14.7705 3.10452 14.584 3.33464 14.584H16.668C16.8981 14.584 17.0846 14.7705 17.0846 15.0007C17.0846 15.2308 16.8981 15.4173 16.668 15.4173H3.33464C3.10452 15.4173 2.91797 15.2308 2.91797 15.0007Z"
                      fill="#121212"
                    />
                  </svg>
                </CartWrapper>
              </Flex>
            </MenuFirstHead>
            <PageHeader coverImageUrl={productBody?.menuStoreResponse?.coverImageUrl}>
              <MenuStoreTitle>
                <Typography variant="n-text4" color="n-light">
                  {productBody?.menuStoreResponse?.storeName}
                </Typography>
                <Flex justifyContent={"space-between"}>
                  <Flex style={{ gap: 5 }} alignItems="center">
                    <Flex style={{ gap: 5 }} alignItems="center">
                      <WhiteLocationIcon />
                      <EllipsedText
                        variant="n-text2"
                        color="n-light"
                        style={{ maxWidth: "120px" }}
                      >
                        {productBody?.menuStoreResponse?.storeLocation}
                      </EllipsedText>
                    </Flex>
                    <span>
                      <svg
                        width="6"
                        height="6"
                        viewBox="0 0 6 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.9375 5.13068C2.53646 5.13068 2.17022 5.03291 1.83878 4.83736C1.50734 4.63849 1.24219 4.37334 1.04332 4.0419C0.847775 3.71046 0.75 3.34422 0.75 2.94318C0.75 2.53883 0.847775 2.17259 1.04332 1.84446C1.24219 1.51302 1.50734 1.24953 1.83878 1.05398C2.17022 0.855113 2.53646 0.755682 2.9375 0.755682C3.34186 0.755682 3.7081 0.855113 4.03622 1.05398C4.36766 1.24953 4.63116 1.51302 4.8267 1.84446C5.02557 2.17259 5.125 2.53883 5.125 2.94318C5.125 3.34422 5.02557 3.71046 4.8267 4.0419C4.63116 4.37334 4.36766 4.63849 4.03622 4.83736C3.7081 5.03291 3.34186 5.13068 2.9375 5.13068Z"
                          fill="#fff"
                        />
                      </svg>
                    </span>
                    <Typography variant="n-text2" color="n-light">
                      {returnClosingTime(productBody)}
                    </Typography>
                  </Flex>
                </Flex>
              </MenuStoreTitle>
            </PageHeader>

            <Flex flexDirection="column" alignItems={"center"} mb="100px">
              <MenuFirstHead
                p="20px"
                width={"100%"}
                style={{ top: "63px" }}
                justifyContent="space-between"
              >
                <Button
                  variant="small"
                  style={{ minWidth: "200px" }}
                  onClick={() => {
                    setOpenTableOrTrackNumber(true);
                  }}
                >
                  Take/Add order
                </Button>
                <Select
                  value={selectedFormat}
                  name="selectArea"
                  options={[
                    {
                      text: "All",
                      value: "all",
                    },
                    {
                      text: "Today",
                      value: "today",
                    },
                    {
                      text: "Yesterday",
                      value: "yesterday",
                    },
                  ]}
                  placeholder="All"
                  onChange={(e) => {
                    handleSelect(e.target);
                  }}
                  width="120px"
                />
              </MenuFirstHead>
              <Box p="20px" width="100%">
                {isLoadingOrders || reloadMode ? (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    mt="120px"
                  >
                    <Oval
                      height={60}
                      width={60}
                      color="#ffcc00"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#332900"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </Flex>
                ) : (
                  <>
                    {orders.length === 0 ? (
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        mt="120px"
                        style={{ gap: "20px" }}
                      >
                        <NoOrdersIcon />
                        <Typography color="n-grey3a">
                          You have not taken an order on this day.
                        </Typography>
                      </Flex>
                    ) : (
                      <>
                        {orders && orders.map((item) => {
                          // if (item.orderType !== "DINE_IN") {
                          //   return;
                          // }
                          return (
                            <OrderItem
                              key={item.orderReference}
                              mb="10px"
                              onClick={() => {
                                navigate(
                                  `/${businessId}/waiter/order-details/${item.orderReference}`
                                );
                              }}
                            >
                              <Flex
                                justifyContent="space-between"
                                style={{ borderBottom: "solid 1px #EEE" }}
                                mb="5px"
                                px="15px"
                                py="10px"
                              >
                                <Typography variant="n-text1" color="n-grey3a">
                                  Table {item.destination}
                                </Typography>
                                {item.paymentStatus === "PAYMENT_PENDING" ? (
                                  <Typography variant="n-text1" color="n-red">
                                    Not Paid
                                  </Typography>
                                ) : (
                                  <Typography variant="n-text1" color="n-green">
                                    Paid by{" "}
                                    {capitalizeFirstLetter(item.paymentMethod)}
                                  </Typography>
                                )}
                              </Flex>
                              <Box px="15px" py="10px">
                                <Box mb="5px">
                                  <EllipsedText
                                    style={{ maxWidth: "80%" }}
                                    variant="n-text2"
                                  >
                                    {item.itemSummary.replaceAll(
                                      "Item name: ",
                                      ""
                                    ) || item.orderReference}
                                  </EllipsedText>
                                </Box>
                                <Typography
                                  variant="n-text2"
                                  fontWeight="heavy"
                                >
                                  {formatCurrency(item.totalAmount)}
                                </Typography>
                                <Flex
                                  justifyContent="space-between"
                                  flexWrap="wrap"
                                >
                                  <Flex
                                    style={{ gap: "5px" }}
                                    mb="5px"
                                    mr="10px"
                                    alignItems="center"
                                    flexWrap="wrap"
                                  >
                                    <Typography
                                      variant="n-text2"
                                      color="n-grey3a"
                                    >
                                      {item.orderReference}
                                    </Typography>
                                    <span>
                                      <svg
                                        width="6"
                                        height="6"
                                        viewBox="0 0 6 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M2.9375 5.13068C2.53646 5.13068 2.17022 5.03291 1.83878 4.83736C1.50734 4.63849 1.24219 4.37334 1.04332 4.0419C0.847775 3.71046 0.75 3.34422 0.75 2.94318C0.75 2.53883 0.847775 2.17259 1.04332 1.84446C1.24219 1.51302 1.50734 1.24953 1.83878 1.05398C2.17022 0.855113 2.53646 0.755682 2.9375 0.755682C3.34186 0.755682 3.7081 0.855113 4.03622 1.05398C4.36766 1.24953 4.63116 1.51302 4.8267 1.84446C5.02557 2.17259 5.125 2.53883 5.125 2.94318C5.125 3.34422 5.02557 3.71046 4.8267 4.0419C4.63116 4.37334 4.36766 4.63849 4.03622 4.83736C3.7081 5.03291 3.34186 5.13068 2.9375 5.13068Z"
                                          fill="#8C8C8C"
                                        />
                                      </svg>
                                    </span>
                                    <Typography
                                      variant="n-text2"
                                      color="n-grey3a"
                                    >
                                      {formatDateValue(
                                        item.orderDate as unknown as Date
                                      )}
                                    </Typography>
                                  </Flex>
                                  <OrderStatusBadge status={item.status}>
                                    {capitalizeFirstLetter(
                                      returnOrderNames(item.status)
                                    )}
                                  </OrderStatusBadge>
                                </Flex>
                              </Box>
                            </OrderItem>
                          );
                        })}
                        {isLastPage && orders.length > 5 ? (
                          <ViewMoreButton
                            variant="medium"
                            color="secondary"
                            onClick={handleScrollToTop}
                          >
                            Scroll Back Up
                          </ViewMoreButton>
                        ) : (
                          <ViewMoreButton
                            disabled={isLastPage}
                            loading={isLoadingOrders}
                            variant="medium"
                            color="secondary"
                            onClick={handleOnchangePagination}
                          >
                            View more
                          </ViewMoreButton>
                        )}
                      </>
                    )}
                  </>
                )}
              </Box>
            </Flex>
          </div>
        </MainPageWrapper>
      ) : (
        <PageLoad />
      )}
      <EnterTableNumber
        isOpen={openTableOrTrackNumber}
        setIsOpen={setOpenTableOrTrackNumber}
        onClose={() => {
          navigate(`/${productBody?.alias}/waiter/menu-list`);
        }}
      />
    </>
  );
}
