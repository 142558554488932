import styled from "styled-components";
import { useOrderData } from "../../state/context";

const CartNumber = styled.span`
  background: ${({ theme }) => theme.nColors["n-yellow"]};
  width: 14px;
  height: 14px;
  font-weight: 500;
  font-size: 11.6667px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${({ theme }) => theme.nColors["n-dark"]};
  border-radius: 50%;
  position: absolute;
  left: 20px;
  top: 22px;
`;

const CartWrapper = styled.div`
  position: relative;
`;

const CartImage = () => {
  const { orderData } = useOrderData();
  return (
    <CartWrapper>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20" cy="20" r="20" fill="white" />
        <path
          d="M22.6667 17.3335V16.0002H26V26.6668H14V16.0002H17.3333C17.3333 14.5275 18.5273 13.3335 20 13.3335C21.4733 13.3335 22.6667 14.5275 22.6667 16.0002H17.3333V17.3335"
          stroke="#121212"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      {orderData &&
        orderData.selectedMeals &&
        orderData.selectedMeals.length > 0 && (
          <CartNumber>{orderData.selectedMeals.length}</CartNumber>
        )}
    </CartWrapper>
  );
};

export default CartImage;
