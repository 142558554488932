import { useParams } from "react-router-dom";
import Navbar from "../Navbar";
import { KdsOverviewWrapper, KdsContentWrapper } from "./styles";
import KdsHeader from "./header";
import { Filter } from "kudi-component-library";
import { KdsFilterConfig } from "./config";
import KdsCardsOverview from "./kdsCards";
import { Flex } from "rebass";
import useIsMobile from "../../hooks/useIsMobile";
import useFilterKdsOrders from "./models/useFilterKdsOrders";
import { AppliedFilter } from "../newFilter/defs";

export default function KdsOverview() {
  const { businessId } = useParams();
  const isMediumScreen = useIsMobile(1600);
  const { handleClearFilters, handleFilterOrders, handleSearchOrders, } = useFilterKdsOrders();
  const content = (
    <KdsOverviewWrapper>
      <Navbar />
      <KdsContentWrapper>
        <KdsHeader />
        <Filter
          searchPlaceholder="Search by item summary"
          filterConfig={KdsFilterConfig}
          onSearch={(searchTerm: string) => handleSearchOrders(searchTerm)}
          onClearSearch={handleClearFilters}
          onApplyFilters={(filters: AppliedFilter[]) => handleFilterOrders(filters)}
          onClearFilters={handleClearFilters}
        />
        <KdsCardsOverview />
      </KdsContentWrapper>
    </KdsOverviewWrapper>
  );
  return isMediumScreen ? (
    content
  ) : (
    <Flex display="flex" justifyContent="center">
      {content}
    </Flex>
  );
}
