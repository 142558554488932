import HomePageSetup from "./HomePageSetup";
import OrderDetailsPageSetup from "./OrderDetailsPageSetup";
import SucessPageSetup from "./SuccessPageSetup";
import OrderMealDetailsPageSetup from "./OrderMealDetailsSetup";
import BankTransferPageSetup from "./BankTransferPageSetup";
import OrderSummaryPageSetup from "./OrderSummaryPageSetup";
import BankTransferCountdownPageSetup from "./BankTransferCountdownPageSetup";
import MenuListPageSetup from "./MenuListPageSetup";
import DetailsPageSetup from "./DetailsPageSetup";

export {
  HomePageSetup,
  OrderDetailsPageSetup,
  SucessPageSetup,
  OrderMealDetailsPageSetup,
  BankTransferPageSetup,
  OrderSummaryPageSetup,
  BankTransferCountdownPageSetup,
  MenuListPageSetup,
  DetailsPageSetup
};
