"use client";
import { useState, useEffect } from "react";
import { Flex } from "rebass";
import { Button, Typography } from "kudi-component-library";
import styled, { useTheme } from "styled-components";
import { MainPageWrapper } from "../Layout";
import { useNavigate } from "react-router-dom";
import useBankTransferCountdown from "./useBankTransferCountdown";
import { useOrderData } from "../../state/context";
import { formatCurrency } from "../../utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";

const TimerText = styled.text<{ seconds: number }>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.04em;
  font-feature-settings: "cv11" on;

  fill: ${({ theme, seconds }) =>
    seconds > 240 ? theme.nColors["n-red"] : theme.nColors["n-dark"]};
`;

const CountdownBox = styled.svg`
  max-width: 300px;
`;

interface CountdownLoaderProps {
  seconds: number;
  circleColor: string;
}

const CountdownLoader: React.FC<CountdownLoaderProps> = ({
  seconds,
  circleColor,
}) => {
  const circleRadius = 80;
  const circleCircumference = 2 * Math.PI * circleRadius;
  const strokeDasharray = `${circleCircumference} ${circleCircumference}`;
  const strokeDashoffset = ((seconds % 300) / 300) * circleCircumference;

  const timeLeft = 300 - (seconds % 300);

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? "0" + minutes : minutes}:${
      seconds < 10 ? "0" + seconds : seconds
    }`;
  };

  return (
    <CountdownBox viewBox="0 0 200 200">
      <circle
        cx="100"
        cy="100"
        r={circleRadius}
        fill="none"
        stroke={circleColor}
        strokeWidth="2"
        strokeDasharray={strokeDasharray}
        strokeDashoffset={strokeDashoffset}
      />
      <TimerText x="100" y="110" textAnchor="middle" seconds={seconds}>
        -{seconds >= 300 ? "-" : formatTime(timeLeft)}
      </TimerText>
    </CountdownBox>
  );
};

const SummaryButton = styled(Button)`
  width: 100%;
`;

const AmountTitle = styled(Typography)`
  font-weight: 500;
  margin-top: 10px;
`;

const BankTransferCountdownPageSetup = () => {
  const theme = useTheme();

  const { orderSummary, handleGetOrderSummary } = useBankTransferCountdown();
  const { businessData, orderReference } = useOrderData();
  const [savedBusinessData] = useLocalStorage("savedBusinessData");
  const [fromTerminal] = useLocalStorage("fromTerminal");

  const [seconds, setSeconds] = useState(0);
  const [showDelayed, setShowDelayed] = useState(false);
  const [circleColor, setCircleColor] = useState("lightblue");

  const getRandomColor = () => {
    const colors = Object.values(theme.nColors);
    return colors[Math.floor(Math.random() * colors.length)];
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);

    const colorTimer = setInterval(() => {
      setCircleColor(getRandomColor());
    }, 5000);

    const serverCallTimer = setInterval(() => {
      handleGetOrderSummary(false);
    }, 30000);

    if (seconds >= 300) {
      clearInterval(timer);
      clearInterval(colorTimer);
      clearInterval(serverCallTimer);
    }

    return () => {
      clearInterval(timer);
      clearInterval(colorTimer);
      clearInterval(serverCallTimer);
    };
  }, []);

  useEffect(() => {
    if (seconds >= 60) {
      setShowDelayed(true);
    }
  }, [seconds]);

  const navigate = useNavigate();

  useEffect(() => {
    const navigatePage = () => {
      if (!businessData || !orderReference) {
        if (savedBusinessData) {
          navigate(
            `/${savedBusinessData?.alias}/${fromTerminal ? "waiter" : ""}`
          );
          return;
        }
        navigate(`/`);
        return;
      }
    };
    navigatePage();
  }, []);

  return (
    <MainPageWrapper>
      <div className="pageSetup">
        <Flex justifyContent={"center"} alignItems="center" mb="20px">
          <Typography
            color="n-dark"
            fontWeight={500}
            style={{
              fontSize: "20px",
              lineHeight: "38px",
            }}
          >
            Bank Transfer
          </Typography>
        </Flex>

        <Flex
          mt={"50px"}
          mb={"50px"}
          flexDirection={"column"}
          alignItems={"center"}
          style={{ textAlign: "center" }}
        >
          <CountdownLoader seconds={seconds} circleColor={circleColor} />
          <Typography variant="n-text4" style={{ marginBottom: "15px" }}>
            {showDelayed
              ? "Delayed Payment Confirmation"
              : "We are confirming payment"}
          </Typography>
          <Typography variant="n-text3">
            {showDelayed
              ? "Click on the button below to confirm your payment"
              : "Please do not close or refresh this window"}
          </Typography>
          {orderReference && (
            <AmountTitle variant="n-text3" color="n-grey5">
              {formatCurrency(
                (orderReference?.totalAmount as unknown as string) || "",
                false
              )}
            </AmountTitle>
          )}
        </Flex>

        {showDelayed ? (
          <>
            <Flex mt={"50px"} mb={"50px"} justifyContent={"center"}>
              <Typography variant="n-text3">Payment Status:</Typography>
              <Typography
                variant="n-text3"
                color="n-red"
                style={{ marginLeft: "5px" }}
              >
                {orderSummary?.status}
              </Typography>
            </Flex>

            <SummaryButton
              variant="medium"
              onClick={() => {
                handleGetOrderSummary(true);
              }}
            >
              Confirm Payment
            </SummaryButton>
          </>
        ) : null}
      </div>
    </MainPageWrapper>
  );
};

export default BankTransferCountdownPageSetup;
