import { BadgeStatus } from "kudi-component-library";
import { css } from "styled-components";

export const handleBadgeStyling = (status: BadgeStatus) => {
  switch (status) {
    case "default":
      return css`
        border: 1px solid #aad4e8;
        background: #e6f2f8;
      `;
    case "success":
      return css`
        border: 1px solid ${({ theme }) => theme.nColors["n-green3"]};
        background: #e7f8e6;
      `;
    case "warning":
      return css`
        border: 1px solid #fea;
        background: #fffae6;
      `;
    case "error":
      return css`
        border: 1px solid ${({ theme }) => theme.nColors["n-red3"]};
        background: ${({ theme }) => theme.nColors["n-red1"]};
      `;
    default:
      return css`
        border: 1px solid #e8aaab;
      `;
  }
};
