import { ApiResponse } from "../types/ApiResponse";
import { OrderEditPayload } from "../types/MenuList";
import { menuServiceInit } from "../utils";

const putEditOrder = async (payload: OrderEditPayload, accessToken: string) => {
  const url = `/webbe/v1/storehouse/orders/items`;
  try {
    const result: ApiResponse = await menuServiceInit.put(url, payload, {
      headers: {
        "iam-authorization": `Bearer ${accessToken}`,
        coreUserId: "businessId",
      },
    });
    return await Promise.resolve({ error: null, result: result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default putEditOrder;
