import * as React from "react";

const ErrorBoundaryIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={168}
    height={180}
    fill="none"
    {...props}
  >
    <circle cx={88.564} cy={100.499} r={78.599} fill="#D9D9D9" />
    <path
      stroke="#CCC"
      strokeWidth={2}
      d="M157.75 68.83c-3.086 11.159-13.201 36.025-31.392 40.648-18.192 4.623-26.831-.266-28.575-2.59"
    />
    <mask id="a" fill="#fff">
      <path
        fillRule="evenodd"
        d="M68.267 44.566 50.983 63.144v93.288h75.165V63.144l-16.816-18.578H68.267Zm15.092 31.22a7.862 7.862 0 1 0 0-15.726 7.862 7.862 0 0 0 0 15.725Z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M68.267 44.566 50.983 63.144v93.288h75.165V63.144l-16.816-18.578H68.267Zm15.092 31.22a7.862 7.862 0 1 0 0-15.726 7.862 7.862 0 0 0 0 15.725Z"
      clipRule="evenodd"
    />
    <path
      fill="#CCC"
      d="M50.982 63.144 49.86 62.1l-.41.441v.603h1.532Zm17.285-18.578v-1.533H67.6l-.455.49 1.123 1.043ZM50.983 156.432H49.45v1.533h1.533v-1.533Zm75.165 0v1.533h1.533v-1.533h-1.533Zm0-93.288h1.533v-.59l-.397-.439-1.136 1.029Zm-16.816-18.578 1.137-1.028-.457-.505h-.68v1.533ZM52.105 64.188 69.39 45.611l-2.245-2.089L49.86 62.1l2.245 2.088Zm.41 92.244V63.144H49.45v93.288h3.066Zm73.632-1.533H50.982v3.066h75.165v-3.066Zm-1.533-91.755v93.288h3.066V63.144h-3.066Zm-16.42-17.549 16.816 18.578 2.273-2.058-16.815-18.577-2.274 2.057Zm-39.927.504h41.064v-3.066H68.267V46.1Zm21.42 21.824a6.33 6.33 0 0 1-6.328 6.33v3.065a9.395 9.395 0 0 0 9.395-9.395h-3.066Zm-6.328-6.33a6.33 6.33 0 0 1 6.329 6.33h3.066a9.395 9.395 0 0 0-9.395-9.396v3.066Zm-6.33 6.33a6.33 6.33 0 0 1 6.33-6.33v-3.066a9.395 9.395 0 0 0-9.396 9.396h3.066Zm6.33 6.33a6.33 6.33 0 0 1-6.33-6.33h-3.066a9.395 9.395 0 0 0 9.396 9.395v-3.066Z"
      mask="url(#a)"
    />
    <path
      fill="#CCC"
      d="M78.096 100.499a7.731 7.731 0 0 1-7.73 7.73 7.734 7.734 0 0 1-7.141-4.772 7.73 7.73 0 0 1-.588-2.958h1.704a6.027 6.027 0 0 0 8.331 5.567 6.038 6.038 0 0 0 3.261-3.261 6.027 6.027 0 0 0 .459-2.306h1.704ZM91.088 120.968a7.73 7.73 0 0 0-15.46 0h1.705a6.023 6.023 0 0 1 8.331-5.567 6.038 6.038 0 0 1 3.261 3.261c.303.731.459 1.514.459 2.306h1.704ZM104.08 100.499a7.739 7.739 0 0 1-2.264 5.466 7.738 7.738 0 0 1-5.465 2.264 7.72 7.72 0 0 1-5.466-2.264 7.731 7.731 0 0 1-2.264-5.466h1.704a6.027 6.027 0 0 0 8.331 5.567 6.03 6.03 0 0 0 3.72-5.567h1.704Z"
    />
    <path
      fill="#FAFAFA"
      stroke="#CCC"
      strokeWidth={1.533}
      d="M51.937 137.836h73.258v18.333H51.937z"
    />
    <circle cx={81.454} cy={22.638} r={4.268} fill="#C4C4C4" />
    <circle cx={136.012} cy={24.554} r={1.916} fill="#C4C4C4" />
    <circle cx={125.85} cy={54.704} r={1.916} fill="#C4C4C4" />
    <circle cx={153.659} cy={75.128} r={2.491} fill="#C4C4C4" />
    <circle cx={36.995} cy={15.879} r={2.491} fill="#C4C4C4" />
    <path
      fill="#E6E6E6"
      d="m112.793 4.776 2.293-2.293 1.658 1.658-2.293 2.293 2.591 2.592-1.746 1.746-2.592-2.591-2.299 2.3-1.658-1.659 2.299-2.299-2.483-2.483 1.747-1.747 2.483 2.483ZM52.752 27.719l1.846-1.846 1.335 1.335-1.846 1.846 2.086 2.086-1.406 1.406-2.086-2.086-1.851 1.85-1.335-1.334 1.851-1.85-1.999-2 1.406-1.406 2 1.999ZM28.053 59.07l1.176-1.176.85.85-1.176 1.177 1.33 1.33-.896.896-1.33-1.33-1.18 1.18-.85-.85 1.18-1.18-1.274-1.274.896-.897 1.274 1.275ZM153.758 47.498l1.176-1.176.851.85-1.177 1.177 1.33 1.33-.896.895-1.33-1.33-1.179 1.18-.851-.85 1.18-1.18-1.274-1.274.896-.896 1.274 1.274Z"
    />
    <path
      stroke="#CCC"
      strokeWidth={2}
      d="M1 131.025c4.508-16.303 19.288-52.637 45.869-59.392 26.58-6.754 39.204.388 41.752 3.785"
    />
  </svg>
);
export default ErrorBoundaryIcon;
