import { Box, Flex } from "rebass";
import { BackButton, Button, Typography } from "kudi-component-library";
import styled from "styled-components";
import { MainPageWrapper } from "../Layout";
import { useOrderData } from "../../state/context";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useBankTransferPage from "./useBankTransferPage";
import { useEffect } from "react";
import PageLoad from "../PageLoad";
import { formatCurrency } from "../../utils";
import { useMixPanel } from "../../hooks/useMixpanel";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { PriceSummarySection, UnderlinedButton } from "../Styles";

const AccountNameBox = styled(Box)`
  background: ${({ theme }) => theme.nColors["n-light"]};
  border: 1px solid ${({ theme }) => theme.nColors["n-grey1"]};
  border-radius: 5px;
  padding: 20px 50px;
  text-align: center;
  width: 100%;
`;

const AccountNameTitle = styled(Typography)`
  border-bottom: 1px solid ${({ theme }) => theme.nColors["n-grey1"]};
  padding-bottom: 20px;
`;

const AmountTitle = styled(Typography)`
  padding-bottom: 15px;
  font-weight: 500;
`;

const SummaryButton = styled(Button)`
  width: 100%;
`;

const BankTransferPageSetup = () => {
  const { businessData, orderReference, setOrderData } = useOrderData();
  const navigate = useNavigate();
  const { track } = useMixPanel();
  const [savedBusinessData] = useLocalStorage("savedBusinessData");
  const [fromTerminal] = useLocalStorage("fromTerminal");
  const location = useLocation();

  useEffect(() => {
    const navigatePage = () => {
      if (!businessData || !orderReference) {
        if (savedBusinessData) {
          navigate(
            `/${savedBusinessData?.alias}/${fromTerminal ? "waiter" : ""}`
          );
          return;
        }
        navigate(`/`);
        return;
      }
    };
    navigatePage();
  }, []);

  const { transferDetails, isLoading } = useBankTransferPage(
    businessData?.menuId as string
  );

  const showBackButton = location.state?.prevPath.includes(
    "/waiter/order-details"
  );

  return (
    <>
      {!isLoading ? (
        <MainPageWrapper>
          <div className="pageSetup">
            <Box mx="20px" mt="20px">
              {showBackButton ? (
                <Flex>
                  <span
                    style={{
                      textDecoration: "none",
                    }}
                    onClick={() => {
                      track(
                        "MENU_CUSTOMER_GOES_BACK_FROM_BANK_TRANSFER_DETAILS"
                      );
                      navigate(-1);
                    }}
                  >
                    <BackButton
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        textDecoration: "none",
                      }}
                    >
                      Back
                    </BackButton>
                  </span>
                </Flex>
              ) : null}
              <Flex justifyContent={"center"} alignItems="center" mb="20px">
                <Typography
                  color="n-dark"
                  fontWeight={500}
                  style={{
                    fontSize: "20px",
                    lineHeight: "38px",
                  }}
                >
                  Bank Transfer
                </Typography>
              </Flex>

              <Flex justifyContent={"center"} mt={"100px"} mb={"150px"}>
                <AccountNameBox>
                  {orderReference && (
                    <AmountTitle variant="n-text5">
                      {formatCurrency(
                        orderReference?.totalAmount as unknown as string,
                        false
                      )}
                    </AmountTitle>
                  )}
                  <AccountNameTitle variant="n-text2">
                    Kindly transfer money using this account details
                  </AccountNameTitle>
                  {transferDetails && (
                    <>
                      <Box mt={"20px"}>
                        <Typography variant="n-text3">
                          Account Name: {transferDetails[0].accountName}
                        </Typography>
                      </Box>
                      <Box mt={"20px"}>
                        <Typography
                          variant="n-text6"
                          style={{ fontSize: "25px" }}
                        >
                          {transferDetails[0].accountNumber}
                        </Typography>
                      </Box>
                      <Box mt={"20px"}>
                        <Typography variant="n-text3">
                          Bank Name: {transferDetails[0].bankName}
                        </Typography>
                      </Box>
                    </>
                  )}
                </AccountNameBox>
              </Flex>

              <PriceSummarySection>
                <Flex
                  justifyContent="center"
                  flexDirection="column"
                  style={{ gap: "20px" }}
                  alignItems="center"
                >
                  <SummaryButton
                    variant="medium"
                    disabled={!transferDetails}
                    onClick={() => {
                      navigate("/bank-transfer-countdown");
                      track("MENU_CUSTOMER_CLICKS_CONFIRM_BANK_TRANSFER");
                    }}
                  >
                    Confirm bank transfer
                  </SummaryButton>
                  <Box
                    onClick={() => {
                      localStorage.removeItem("savedOrder");
                      setOrderData({});
                      navigate(
                        `/${savedBusinessData?.alias}/${
                          fromTerminal ? "waiter" : ""
                        }`
                      );
                    }}
                  >
                    <UnderlinedButton>Go Home</UnderlinedButton>
                  </Box>
                </Flex>
              </PriceSummarySection>
            </Box>
          </div>
        </MainPageWrapper>
      ) : (
        <PageLoad />
      )}
    </>
  );
};

export default BankTransferPageSetup;
