import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useMixPanel } from "../../hooks/useMixpanel";
import { fetchOrderDetails } from "../../queries";
import { useOrderData } from "../../state/context";
import { ErrorResponse } from "../../types";
import { OrderSummaryBody } from "../../types/MenuList";

const useBankTransferCountdown = () => {
  const navigate = useNavigate();
  const [orderSummary, setOrderSummary] = useState<OrderSummaryBody>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { orderReference, setPaidStatus } = useOrderData();
  const { track } = useMixPanel();

  const handleGetOrderSummary = async (initiate: boolean) => {
    setIsLoading(true);
    const { result, error: fetchError } = await fetchOrderDetails({
      orderId: orderReference?.orderReference as string,
    });
    setIsLoading(false);
    if (result) {
      setOrderSummary(result.data);
      if (result.data?.status === "COMPLETED") {
        track("MENU_CUSTOMER_COMPLETE_PAY_BY_TRANSFER");
        setPaidStatus && setPaidStatus(true);
        navigate("/success");
      } else {
        initiate ? toast.warning("Payment Pending Confirmation") : null;
      }
    }
    if (fetchError){
      track("MENU_CUSTOMER_ERROR_COMPLETING_PAY_BY_TRANSFER", {error: JSON.stringify(fetchError)});
      toast.error(
        (fetchError as ErrorResponse)?.data.message ||
          "An error occured while fetching order details"
      );
    }
  };

  useEffect(() => {
    if (!orderReference) return;
    handleGetOrderSummary(false);
  }, [orderReference]);

  return {
    orderSummary,
    handleGetOrderSummary,
    isLoading,
  };
};

export default useBankTransferCountdown;
