import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { ErrorResponse } from "react-router-dom";
import { toast } from "react-toastify";
import fetchCustomerDetails from "../queries/fetchCustomerDetails";
import { MenuOrderCustomerData } from "../types/MenuList";

function useGetCustomerDetails({
  setIsLoading,
  customerId,
  useHeaders = true,
}: {
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  customerId: string;
  useHeaders?: boolean;
}) {
  const { user, getAccessTokenSilently } = useAuth0();
  const [customerDetails, setCustomerDetails] =
    useState<MenuOrderCustomerData | null>();
  const [fetchError, setFetchError] = useState<Object>();

  const handleFetchCustomerDetails = async () => {
    setIsLoading?.(true);
    const accessToken = await getAccessTokenSilently();
    const { result, error } = await fetchCustomerDetails(
      {
        customerId,
        useHeaders,
      },
      accessToken,
      user?.group
    );
    setIsLoading?.(false);
    if (result?.data) setCustomerDetails(result);
    if (error) {
      setFetchError(error);
      toast.error(
        (error as ErrorResponse)?.data.message ||
          "An error occured while fetching item customer details"
      );
    }
  };

  useEffect(() => {
    if (customerId) handleFetchCustomerDetails();
  }, [customerId]);

  return {
    customerDetails,
    fetchError,
  };
}

export default useGetCustomerDetails;
