import {
  Typography,
  NIcons,
  IconsEnum,
  NTheme as theme,
} from "kudi-component-library";
import { Box, Flex } from "rebass";
import { CardsBodyHeader } from "./styles";
import React from "react";

interface KdsCardColumInterface {
  header: string;
  numberOfOrders: number;
  children?: React.ReactNode;
}

export default function KdsCardsColumn({
  header,
  children,
  numberOfOrders,
}: KdsCardColumInterface) {
  return (
    <Box
      bg={"white"}
      style={{
        borderRadius: 8,
        minWidth: 330,
        flex: "1",
        border: "4px solid rgb(238, 238, 238)",
        borderLeft: "none",
        borderTop: "none",
        boxShadow: `4px 4px ${theme.nColors["n-grey2"]}`,
        margin: "0 4px 4px 0",
        background: theme.nColors["n-grey0"],
      }}
    >
      <CardsBodyHeader
        justifyContent={"space-between"}
        alignItems={"center"}
        px={20}
        py={10}
      >
        <Typography variant="n-text3" fontWeight="heavy">
          {`${header} (${numberOfOrders})`}
        </Typography>
        <NIcons icon={IconsEnum.OUTLINE_DOTS_HORIZONTAL} />
      </CardsBodyHeader>
      <Flex
        flexDirection="column"
        style={{ gap: 10, height: "60vh", overflowY: "auto" }}
        p={15}
      >
        {children}
      </Flex>
    </Box>
  );
}
