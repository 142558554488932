import * as React from "react"
const TrackOrderIcon: React.FC<React.SVGProps<SVGSVGElement>>  = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#a)"
    >
      <path d="M8.001 11.333a3.333 3.333 0 1 0 0-6.666 3.333 3.333 0 0 0 0 6.666Z" />
      <path strokeMiterlimit={10} d="M14.667 12v2.667H12" />
      <path
        strokeMiterlimit={10}
        d="M14.667 12v2.667H12M12 1.333h2.667V4M1.333 12v2.667H4M4 1.333H1.333V4"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default TrackOrderIcon
