import { toast } from 'react-toastify';
import fetchStationOrders from '../../../queries/kds/fetchStationOrders';
import { setOrders } from '../../../state/kds/slice';
import { StationType } from '../../../types/stations';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch } from '../../../state/store';

const useFetchMenuStationOrders = () => {
    const dispatch = useAppDispatch();

    const { getAccessTokenSilently } = useAuth0();


    const handleFetchStationOrders = async (selectedStation: StationType, params?: URLSearchParams) => {
        const accessToken = await getAccessTokenSilently();
        const { result, error } = await fetchStationOrders({
            stationId: selectedStation.stationId,
            accessToken,
            params
        });
        if (result) {
            dispatch(setOrders(result.data.list));
        }
        if (error) {
            dispatch(setOrders([]));
            toast.error("An error occured while fetching station orders");
        }
    };



    return {
        handleFetchStationOrders
    }
}

export default useFetchMenuStationOrders;
