import { BadgeStatus, Typography } from "kudi-component-library";
import { Flex } from "rebass";
import styled from "styled-components";
import { handleBadgeStyling } from "./util";

export const KdsCardsOverViewWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  margin-bottom: 32px;
  gap: 12px;
`;

export const CardsBody = styled(Flex)`
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.nColors["n-grey1"]};
  background: ${({ theme }) => theme.nColors["n-grey0"]};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  gap: 10px;
  &:hover {
    background: ${({ theme }) => theme.nColors["n-grey1"]};
    border: 1px solid ${({ theme }) => theme.nColors["n-grey2"]};
  }
  cursor: pointer;
  svg path {
    fill: none;
  }
`;

export const CardBadge = styled.div<{ variant: BadgeStatus }>`
  display: flex;
  padding: 4px 8px 4px 12px;
  gap: 5px;
  cursor: pointer;
  height: 24px;
  border-radius: 100px;
  align-items: center;
  ${({ variant }) => handleBadgeStyling(variant as BadgeStatus)}
  svg {
    stroke-width: 2px;
  }
  &:hover {
    svg {
      stroke-width: 2.5px;
    }
  }
`;

export const CardStatusText = styled(Typography)`
  font-weight: 500;
  font-size: 10px;
  font-style: normal;
  line-height: 16px;
`;

export const CardsBodyHeader = styled(Flex)`
  border-radius: 5px 5px 0px 0px;
  border: 1px solid ${({ theme }) => theme.nColors["n-grey0"]};
  background: ${({ theme }) => theme.nColors["n-light"]};
`;

export const CardsBodyDropdown = styled(Flex)`
  border-radius: 100px;
  border: 1px solid #aad4e8;
  background: #e6f2f8;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

export const CardGreyText = styled(Typography)`
  color: #555;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const SplitOrderText = styled(Typography)`
  color: ${({ theme }) => theme.nColors["n-red6"]};
  font-weight: 500;
`;
