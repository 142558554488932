import { useState, useEffect } from "react";

const getStorageValue = (key: string, defaultValue?: string) => {
  const saved = localStorage.getItem(key);
  if (saved) {
    const initial = JSON.parse(saved);
    return initial || defaultValue;
  }
  return defaultValue;
};

export const useLocalStorage = (key: string, defaultValue?: string) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });
  useEffect(() => {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  return [value, setValue];
};
