import { Typography } from "kudi-component-library";
import { Box, Flex } from "rebass";
import styled from "styled-components";
import { MenuListProduct } from "../../../../types";
import { formatCurrency } from "../../../../utils";

const OrderItemsTableWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
  table {
    width: 100%;
    border-top: 2px solid ${({ theme }) => theme.nColors["n-grey0"]};
  }
  tbody {
    display: flex;
    flex-wrap: wrap;
  }
  tr {
    box-sizing: border-box;
    width: 50%;
    border-bottom: 2px solid ${({ theme }) => theme.nColors["n-grey0"]};
    padding: 16px 24px;
  }
  tr: nth-child(odd) {
    border-right: 2px solid ${({ theme }) => theme.nColors["n-grey0"]};
  }
  tr: nth-child(even) {
    padding-left: 17px;
  }
`;

const OrderItemContainer = styled(Flex)`
  gap: 8px;
  align-items: center;
`;

const ItemName = styled(Typography)`
  font-weight: 500;
`;

export default function OrderItemsList({
  items,
}: {
  items: MenuListProduct[];
}) {
  const sampleImage =
    "https://media.istockphoto.com/id/1463973381/photo/abstract-blue-splashes-and-black-background.jpg?s=2048x2048&w=is&k=20&c=ny1xplxmVBtBQi8MN0yBkoBgQkwKwaJVgAkbd3BJfl4=";
  return (
    <Box>
      <Box mb="8px" padding="24px 24px 0px">
        <Typography variant="n-text2" fontWeight="heavy">
          Items({items?.length || 0})
        </Typography>
      </Box>
      <OrderItemsTableWrapper>
        <table>
          <tbody>
            {items?.map((item) => (
              <tr key={item.id}>
                <td>
                  <OrderItemContainer>
                    <Box>
                      <img
                        src={item.imageUrl.length ? item.imageUrl : sampleImage}
                        height="40px"
                        width="40px"
                        style={{ borderRadius: "8px", flexShrink: 0 }}
                      />
                    </Box>
                    <Box>
                      <ItemName
                        variant="n-text1"
                        fontWeight="light"
                        color="grey5"
                      >
                        {item.name}
                        {item.quantity > 1 ? `(${item.quantity})` : ""}
                      </ItemName>
                      <Typography
                        variant="n-text1"
                        fontWeight="heavy"
                        color="grey5"
                      >
                        {formatCurrency(item.price, false)}
                      </Typography>
                    </Box>
                  </OrderItemContainer>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </OrderItemsTableWrapper>
    </Box>
  );
}
