import { Box, Flex } from "rebass";
import { Button, IconsEnum, NIcons, Typography } from "kudi-component-library";
import styled from "styled-components";
import { MainPageWrapper } from "../Layout";
import { useEffect } from "react";
import { useOrderData } from "../../state/context";
import { useNavigate } from "react-router-dom";
import { useMixPanel } from "../../hooks/useMixpanel";
import { formatCurrency, formatDateValue } from "../../utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { ModalClose, PriceSummarySection, ViewMoreButton } from "../Styles";
import { useAuth0 } from "@auth0/auth0-react";
import GooglePlayIcon from "./GooglePlay";
import AppStoreIcon from "./AppStore";

const SucessButton = styled(Box)`
  align-items: center;
  text-align: center;
  border: none;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  border-radius: 5px;
  padding: 20px;
  font-size: 14px;
  line-height: 22px;
  background-color: ${({ theme }) => theme.nColors["n-yellow"]};
  color: ${({ theme }) => theme.nColors["n-dark"]};
  gap: 12px;
  width: 100%;
`;

const ViewButton = styled(Button)`
  width: 100%;
  margin-bottom: 20px;
`;
const DotMiddle = styled.span`
  font-size: 20px;
  margin: 0 10px;
  color: ${({ theme }) => theme.nColors["n-dark"]};
`;
const ViewOrderTrackingButton = styled.button`
  color: ${({ theme }) => theme.nColors["n-dark"]};
  padding-bottom: 5px;
  border: none;
  border-bottom: solid 1px ${({ theme }) => theme.nColors["n-yellow"]};
  textalign: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  background: ${({ theme }) => theme.nColors["n-light"]};
`;

const SucessPageSetup = () => {
  const navigate = useNavigate();
  const { orderData, orderReference, paidStatus, setOrderData, businessData } =
    useOrderData();
  const { track } = useMixPanel();
  const [savedOrder, setSavedOrder] = useLocalStorage("savedOrder");
  const [savedBusinessData] = useLocalStorage("savedBusinessData");
  const [fromTerminal] = useLocalStorage("fromTerminal");
  const { user } = useAuth0();

  useEffect(() => {
    const navigatePage = () => {
      if (!orderData) {
        if (savedBusinessData) {
          navigate(
            `/${savedBusinessData?.alias}/${fromTerminal ? "waiter" : ""}`
          );
          return;
        }
        navigate(`/`);
        return;
      }
    };
    navigatePage();
  }, []);

  const goToHome = () => {
    track("MENU_CUSTOMER_HOME_FROM_SUMMARY_PAGE");
    localStorage.removeItem("savedOrder");
    setOrderData({});
    setSavedOrder(undefined);
    if (savedBusinessData) {
      navigate(`/${savedBusinessData?.alias}/${fromTerminal ? "waiter" : ""}`);
    }
  };

  const getSelectedMealTotalPrice = () => {
    let totalPrice = 0;
    orderData?.selectedMeals?.forEach((item) => {
      totalPrice = totalPrice + item.meal.price * item.quantity;
    });

    const vat = (7.5 / 100) * totalPrice;

    const totalAmount = vat + totalPrice;

    return {
      totalPrice,
      vat,
      totalAmount,
    };
  };

  return (
    <MainPageWrapper>
      <div className="pageSetup">
        {Boolean(fromTerminal) && (
          <ModalClose onClick={() => goToHome()}>
            <NIcons
              icon={IconsEnum.OUTLINE_X}
              color="n-grey4"
              height="20"
              width="20"
            />
          </ModalClose>
        )}
        <Box mt="140px" p="20px">
          <Flex justifyContent={"center"} alignItems={"center"} height="100%">
            <img loading="lazy" src="/order-placed.svg" alt="13" />
          </Flex>
          <Flex
            justifyContent={"center"}
            mt="20px"
            flexDirection={"column"}
            alignItems="center"
          >
            <Typography
              color="n-dark"
              style={{
                fontSize: "24px",
                lineHeight: "48px",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Order Success!
            </Typography>
            <Flex
              mt={"20px"}
              mb={"5px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography variant="n-text2">
                Order#: {orderReference?.orderReference}
              </Typography>
              <DotMiddle>•</DotMiddle>
              <Typography variant="n-text2">
                {formatDateValue(orderReference?.orderDate as unknown as Date)}
              </Typography>
            </Flex>

            <Flex>
              <Typography
                color="n-grey3"
                variant="n-text3"
                style={{ textAlign: "center" }}
              >
                Thank you! {paidStatus ? "Your payment is successful and " : ""}
                You have successfully placed an order of{" "}
                {orderData?.selectedMeals?.length} items worth{" "}
                <Typography style={{ display: "inline" }}>
                  {formatCurrency(
                    getSelectedMealTotalPrice().totalPrice,
                    false
                  )}
                </Typography>{" "}
                today.
                {Boolean(fromTerminal) ? (
                  "Kindly inform customer that his/her order would be available shortly. You will be alerted when the order is ready to be served."
                ) : (
                  <>
                    {paidStatus
                      ? ""
                      : ", kindly make payment to the attendant near you."}
                  </>
                )}
              </Typography>
            </Flex>

            <PriceSummarySection>
              {Boolean(fromTerminal) ? (
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box mb="20px" width="100%">
                    <ViewMoreButton
                      variant="medium"
                      color="secondary"
                      onClick={() => {
                        navigate(
                          `/${businessData?.alias}/waiter/edit-order/${orderReference?.orderReference}`
                        );
                      }}
                    >
                      Edit this order
                    </ViewMoreButton>
                  </Box>

                  <ViewButton
                    variant="medium"
                    onClick={() => {
                      track("MENU_CUSTOMER_PROCEED_TO_TRACK_ORDER");
                      localStorage.removeItem("savedOrder");
                      setOrderData({});
                      navigate(
                        `/${businessData?.alias}/waiter/order-details/${orderReference?.orderReference}`
                      );
                    }}
                  >
                    View Order Detail
                  </ViewButton>

                  <ViewOrderTrackingButton
                    onClick={() => {
                      const itemsToSend = orderData?.selectedMeals?.map(
                        (item) => ({
                          name: item.meal.name,
                          quantity: item.quantity,
                          price: item.meal.price * item.quantity,
                          pricePerItem: item.meal.price,
                        })
                      );
                      window.location.href = `intent://com.nomba.pro#Intent?scheme=cardPaymentScheme;action=order_printing;S.totalAmount=${
                        orderReference?.totalAmount
                      };S.orderReference={${
                        orderReference?.orderReference
                      }};S.table={${orderReference?.destination}};S.orderFor={${
                        orderData?.customerName
                      }};S.paymentStatus={${
                        orderReference?.paymentStatus
                      }};S.data=${JSON.stringify(
                        itemsToSend
                      )};s.date={${formatDateValue(
                        orderReference?.orderDate as unknown as Date
                      )}};s.orderStatus={${orderReference?.status}};end`;
                    }}
                  >
                    Print out this Order
                  </ViewOrderTrackingButton>
                </Flex>
              ) : (
                <>
                  {!savedBusinessData?.menuStoreResponse?.inHouseService &&
                  !savedBusinessData?.menuStoreResponse?.deliveryService ? (
                    <Flex flexDirection="column" justifyContent="center" 
                    alignItems="center" flexWrap="wrap">
                      <SucessButton
                        p="20px"
                        mb="20px"
                        onClick={() => {
                          track("MENU_CUSTOMER_PROCEED_TO_TRACK_ORDER");
                          localStorage.removeItem("savedOrder");
                          setOrderData({});
                          navigate(
                            `/order-summary/${orderReference?.orderReference}`
                          );
                        }}
                      >
                        <Typography>Download the Nomba QR App</Typography>
                        <Flex style={{ gap: "10px" }} flexWrap="wrap" justifyContent="center" alignItems="center">
                          <AppStoreIcon />
                          <GooglePlayIcon />
                        </Flex>
                      </SucessButton>

                      <ViewOrderTrackingButton
                        onClick={() => {
                          track("MENU_CUSTOMER_PROCEED_TO_TRACK_ORDER");
                          localStorage.removeItem("savedOrder");
                          setOrderData({});
                          navigate(
                            `/order-summary/${orderReference?.orderReference}`
                          );
                        }}
                      >
                        Track my order
                      </ViewOrderTrackingButton>
                    </Flex>
                  ) : (
                    <Flex flexDirection="column" justifyContent="center" 
                    alignItems="center">
                      <SucessButton
                        variant="medium"
                        onClick={() => {
                          track("MENU_CUSTOMER_PROCEED_TO_TRACK_ORDER");
                          localStorage.removeItem("savedOrder");
                          setOrderData({});
                          navigate(
                            `/order-summary/${orderReference?.orderReference}`
                          );
                        }}
                      >
                        Track your Order
                      </SucessButton>

                      <ViewOrderTrackingButton
                        onClick={() => {
                          goToHome();
                        }}
                      >
                        Go back to Menu
                      </ViewOrderTrackingButton>
                    </Flex>
                  )}
                </>
              )}
            </PriceSummarySection>
          </Flex>
        </Box>
      </div>
    </MainPageWrapper>
  );
};

export default SucessPageSetup;
