export const phoneNumberConvert = (phone: string, type: '+234' | '080') => {
  const numberSegments = phone.split('');

  if (numberSegments[0] === '0') {
    delete numberSegments[0];
  }

  if (
    numberSegments[0] === '2' &&
    numberSegments[1] === '3' &&
    numberSegments[2] === '4' &&
    numberSegments[3] !== '0'
  ) {
    delete numberSegments[0];
    delete numberSegments[1];
    delete numberSegments[2];
  }

  if (
    numberSegments[0] === '2' &&
    numberSegments[1] === '3' &&
    numberSegments[2] === '4' &&
    numberSegments[3] === '0'
  ) {
    delete numberSegments[0];
    delete numberSegments[1];
    delete numberSegments[2];
    delete numberSegments[3];
  }

  if (type === '080') {
    return `0${numberSegments.join('')}`;
  }

  if (type === '+234') {
    return `234${numberSegments.join('')}`;
  }
};
