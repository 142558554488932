import { Box, Flex } from "rebass";
import { useEffect, useState } from "react";
import {
  Button,
  IconsEnum,
  NIcons,
  Typography,
} from "kudi-component-library";
import styled from "styled-components";
import BottomSheetMeal from "../BottomSheetMeal";
import EnterTableNumber from "../EnterTableNumber";
import EnterOrderIdNumber from "../EnterOrderIdNumber";
import CartImage from "../Icons/CartImage";
import useMenuListProductsModel from "./useMenuListProducts";
import PageLoad from "../PageLoad";
import { useOrderData } from "../../state/context";
import { formatCurrency } from "../../utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMixPanel } from "../../hooks/useMixpanel";
import { Category, MenuListProduct } from "../../types";
import EnterOrderTypeModal from "../EnterOrderTypeModal";
import EnterDeliveryArea from "../EnterDeliveryAreaModal";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { MenuDataBody, MenuDeliveryLocations } from "../../types/MenuList";
import LocationIcon from "../Icons/LocationIcon";
import {
  EllipsedText,
  MealTypeSwitch,
  ProceedToViewOrder,
  SearchBarBox,
  SearchMealsInput,
} from "../Styles";
import TrackOrderIcon from "../Icons/TrackOrderIcon";
import { MainPageWrapper } from "../Layout";

const MealsFlex = styled(Flex)`
  transition: all 0.15s ease;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.nColors["n-grey1"]};
  padding: 10px;
`;

const PageHeader = styled.header<{ coverImageUrl?: string }>`
  height: 200px;
  min-height: 100%;
  position: relative;
  ${({ coverImageUrl }) => {
    if (coverImageUrl) {
      return `background: url(${coverImageUrl});`;
    }
    return `background: url(/logo-background.png);`;
  }}
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
`;

const CartWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const TrackButton = styled.span`
  border-radius: 30px;
  background: #fff;
  display: flex;
  padding: 10px 15px;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

const MenuList = styled.section`
  padding: 0 20px 30px 20px;
  margin: 20px 0;
  max-width: 100%;
  width: 100%;
`;

const MealTypeBox = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 90px;
  width: 100%;
  padding: 15px 20px;
  background: ${({ theme }) => theme.nColors["n-light"]};
  border-bottom: 1px solid ${({ theme }) => theme.nColors["n-grey1"]};
  z-index: 2;
`;

const MenuStoreTitle = styled(Flex)`
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background: ${({ theme }) => theme.nColors["n-light"]};
  border-bottom: 1px solid ${({ theme }) => theme.nColors["n-grey1"]};
  z-index: 2;
`;

const MealImageWrapper = styled.div<{ quantity: boolean }>`
  margin-right: 20px;
  display: block;
  & img {
    border-radius: 10px;
    width: 64px;
    height: 64px;
    filter: ${({ quantity }) => (!quantity ? "none" : "grayscale(100%)")};
  }
`;
const MealImageText = styled(Typography)`
  text-overflow: ellipsis;
  max-width: 80%;
  overflow: hidden;
  white-space: nowrap;
`;

export default function MenuListPageSetup() {
  const { orderData, setOrderData } = useOrderData();
  const { businessId } = useParams();
  const { productBody, isLoading, handleRefetch } = useMenuListProductsModel(
    businessId as string
  );
  const navigate = useNavigate();

  useEffect(() => {
    track("MENU_CUSTOMER_VIEWS_MENULIST_PAGE", {
      alias: businessId as string,
    });
  }, []);

  const [activeMenu, setActiveMenu] = useState<number>();
  const [categories, setCategories] = useState<Category[]>([]);
  const [openBottomBar, setOpenBottomBar] = useState(false);
  const [openTableOrTrackNumber, setOpenTableOrTrackNumber] = useState(false);
  const [openDeliveryArea, setOpenDeliveryArea] = useState(false);
  const [openSelectOrderType, setOpenSelectOrderType] = useState(false);
  const [openOrderIdNumber, setOpenOrderIdNumber] = useState(false);
  const [activeFood, setActiveFood] = useState<MenuListProduct>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [meals, setMeals] = useState<MenuListProduct[]>();
  const [searchMeals, setSearchMeals] = useState<MenuListProduct[]>();
  const { track } = useMixPanel();
  const [savedOrder, setSavedOrder] = useLocalStorage("savedOrder");

  const searchFilter = (text: string) => {
    track("MENU_CUSTOMER_USER_SEARCH");
    const newData = searchMeals?.filter(
      (item: any) => item.name.toUpperCase().indexOf(text.toUpperCase()) > -1
    );

    setMeals(newData);
    setSearchValue(text);
  };

  const clearSearch = () => {
    track("MENU_CUSTOMER_USER_CLEAR_CART");
    setSearchMeals(productBody?.items);
    setMeals(productBody?.items);
    setSearchValue("");
  };

  const addToCart = (activeFood: any, quantity: number) => {
    track("MENU_CUSTOMER_ADD_ITEM_TO_CART");
    setOrderData((prev: any) => {
      let foodData = { meal: activeFood, quantity: quantity };

      let orderObj = {
        ...prev,
        selectedMeals: [{ ...foodData }],
      };

      if (prev && prev.selectedMeals) {
        const index = prev.selectedMeals.findIndex(
          (meals: any) => meals.meal.id === activeFood.id
        );
        if (index !== -1) {
          const updatedMeals = [...prev.selectedMeals];
          updatedMeals[index] = { ...foodData };
          orderObj = {
            ...prev,
            selectedMeals: [...updatedMeals],
          };
        } else {
          orderObj = {
            ...prev,
            selectedMeals: [...prev?.selectedMeals, { ...foodData }],
          };
        }
      }

      setSavedOrder(orderObj);

      return orderObj;
    });
  };

  const getSelectedMealTotalPrice = () => {
    let totalPrice = 0;
    orderData?.selectedMeals?.forEach((item) => {
      totalPrice = totalPrice + item.meal.price * item.quantity;
    });

    return totalPrice;
  };

  function extractUniqueCategories(listProduct: MenuListProduct[]) {
    const uniqueCategories: Category[] = [];

    listProduct.forEach((item) => {
      if (
        item.category.hasOwnProperty("id") &&
        !uniqueCategories.some((uniqs) => uniqs.id === item.category.id)
      ) {
        uniqueCategories.push(item.category);
      }
    });

    return uniqueCategories;
  }

  useEffect(() => {
    if (productBody) {
      const uniqueCategoriesArray = extractUniqueCategories(productBody.items);
      setMeals(productBody?.items);
      setSearchMeals(productBody?.items);
      setCategories(uniqueCategoriesArray);
      if (uniqueCategoriesArray.length > 0) {
        setActiveMenu(uniqueCategoriesArray[0].id);
      }
    }
  }, [productBody]);

  useEffect(() => {
    if (productBody?.menuStoreResponse?.inHouseService && productBody?.menuStoreResponse?.deliveryService) {
      if (!orderData?.selectedMeals || orderData?.selectedMeals?.length === 0) {
        setOpenSelectOrderType(true);
      }
      return;
    }
    if (productBody?.menuStoreResponse?.inHouseService && !productBody?.menuStoreResponse?.deliveryService) {
      setOpenTableOrTrackNumber(true);
      return;
    }
    if (!productBody?.menuStoreResponse?.inHouseService && productBody?.menuStoreResponse?.deliveryService) {
      setOpenDeliveryArea(true);
      return;
    }
  }, [productBody]);

  const confirmSelectedService = () => {
    if (!orderData?.tableNumber) {
      if (productBody?.menuStoreResponse?.inHouseService && productBody.menuStoreResponse?.deliveryService) {
        setOpenSelectOrderType(true);
        return;
      }
      if (productBody?.menuStoreResponse?.inHouseService && !productBody?.menuStoreResponse?.deliveryService) {
        setOpenTableOrTrackNumber(true);
        return;
      }
      if (!productBody?.menuStoreResponse?.inHouseService && productBody?.menuStoreResponse?.deliveryService) {
        setOpenDeliveryArea(true);
        return;
      }
    }
    setSavedOrder(undefined);
    navigate("/order-details");
  };

  const daysOfWeek = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];

  const returnClosingTime = (body: MenuDataBody | undefined) => {
    const date = new Date();
    const todaysDay = date.getDay();
    let selectedClosingTime = "Store Closed";
    body?.businessHours?.map((item) => {
      if (item.dayOfWeek === daysOfWeek[todaysDay - 1]) {
        selectedClosingTime = `Closes at  ${item.closeTime}`;
      }
    });

    return selectedClosingTime;
  };

  const categoryRefs: { [key: string]: HTMLDivElement | null } = {};

  useEffect(() => {
    const handleScroll = () => {
      if (productBody) {
        const mealSections = document.querySelectorAll(".meal-category");
        const uniqueCategoriesArray = extractUniqueCategories(
          productBody.items
        );
        let currentCategoryInView = uniqueCategoriesArray[0].id;

        mealSections.forEach((section) => {
          const rect = section.getBoundingClientRect();
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            currentCategoryInView = Number(
              section.getAttribute("data-category")
            );
          }
        });

        setActiveMenu(currentCategoryInView);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [productBody]);

  const scrollToCategory = ({ name, id }: Category) => {
    track("MENU_CUSTOMER_USER_EDITS_BY_CATEGORY");
    if (categoryRefs !== null && categoryRefs[name] !== null) {
      categoryRefs[name]!.scrollIntoView({ behavior: "smooth", block: "end" });
      setActiveMenu(id);
    }
  };

  return (
    <>
      {!isLoading ? (
        <>
          <MainPageWrapper>
            <div className="pageSetup">
              <PageHeader coverImageUrl={productBody?.menuStoreResponse?.coverImageUrl}>
                <CartWrapper>
                  <TrackButton onClick={() => setOpenOrderIdNumber(true)}>
                    <TrackOrderIcon />
                    Track Order
                  </TrackButton>
                  <Link
                    to={"/order-details"}
                    onClick={() =>
                      track("MENU_CUSTOMER_USER_ORDER_DETAILS_FROM_HEADER")
                    }
                  >
                    <CartImage />
                  </Link>
                </CartWrapper>
              </PageHeader>

              <Flex flexDirection="column" alignItems={"center"} mb="100px">
                <MenuStoreTitle
                  p="20px"
                  flexDirection={"column"}
                  width={"100%"}
                >
                  <Typography variant="n-text4">
                    {productBody?.menuStoreResponse?.storeName}
                  </Typography>
                  <Flex justifyContent={"space-between"}>
                    <Flex style={{ gap: 5 }} alignItems="center">
                      <Flex style={{ gap: 5 }} alignItems="center">
                        <LocationIcon />
                        <EllipsedText
                          variant="n-text2"
                          color="n-grey4"
                          style={{ maxWidth: "120px" }}
                        >
                          {productBody?.menuStoreResponse?.storeLocation}
                        </EllipsedText>
                      </Flex>
                      <span>
                        <svg
                          width="6"
                          height="6"
                          viewBox="0 0 6 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.9375 5.13068C2.53646 5.13068 2.17022 5.03291 1.83878 4.83736C1.50734 4.63849 1.24219 4.37334 1.04332 4.0419C0.847775 3.71046 0.75 3.34422 0.75 2.94318C0.75 2.53883 0.847775 2.17259 1.04332 1.84446C1.24219 1.51302 1.50734 1.24953 1.83878 1.05398C2.17022 0.855113 2.53646 0.755682 2.9375 0.755682C3.34186 0.755682 3.7081 0.855113 4.03622 1.05398C4.36766 1.24953 4.63116 1.51302 4.8267 1.84446C5.02557 2.17259 5.125 2.53883 5.125 2.94318C5.125 3.34422 5.02557 3.71046 4.8267 4.0419C4.63116 4.37334 4.36766 4.63849 4.03622 4.83736C3.7081 5.03291 3.34186 5.13068 2.9375 5.13068Z"
                            fill="#8C8C8C"
                          />
                        </svg>
                      </span>
                      <Typography variant="n-text2" color="n-grey4">
                        {returnClosingTime(productBody)}
                      </Typography>
                    </Flex>
                    <Link
                      to={`/${businessId}/details`}
                      style={{
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <Typography color="n-grey3a" variant="n-text1">
                        More
                      </Typography>
                      <NIcons
                        color="n-grey3a"
                        height="14"
                        width="14"
                        icon={IconsEnum.OUTLINE_CHEVRON_RIGHT}
                      />
                    </Link>
                  </Flex>
                </MenuStoreTitle>
                <SearchBarBox my={"20px"} px="20px">
                  <SearchMealsInput
                    placeholder="Search menu items.."
                    name="search"
                    type="text"
                    style={{ borderRadius: "30px", paddingLeft: "10px" }}
                    value={searchValue}
                    onChange={(e) => {
                      searchFilter(e.target.value);
                    }}
                  />
                </SearchBarBox>
                {meals && meals.length > 0 && (
                  <MealTypeBox>
                    <MealTypeSwitch
                      width={"100%"}
                      alignItems="center"
                      pb="20px"
                      px="5px"
                    >
                      {categories &&
                        categories.map((mealType, index) => (
                          <span
                            key={mealType.id}
                            className={
                              activeMenu === mealType.id ? "activeButton" : ""
                            }
                            onClick={() => scrollToCategory(mealType)}
                          >
                            {mealType.name}
                          </span>
                        ))}
                    </MealTypeSwitch>
                  </MealTypeBox>
                )}
                <MenuList>
                  {categories && meals && meals.length > 0 ? (
                    categories.map((mealType, index) => (
                      <div
                        key={mealType.id}
                        className="meal-category"
                        data-category={mealType.id}
                        ref={(ref) => {
                          categoryRefs[mealType.name] = ref;
                        }}
                      >
                        <Box my="20px">
                          <Typography color="n-grey4" fontWeight={700}>
                            {mealType.name}
                          </Typography>
                        </Box>
                        {meals
                          .filter(
                            (meal: MenuListProduct) =>
                              meal.category.id === mealType.id
                          )
                          .map((meal: MenuListProduct) => (
                            <MealsFlex
                              key={meal.id}
                              mb="10px"
                              onClick={() => {
                                track("MENU_CUSTOMER_USER_OPEN_MEAL", {
                                  mealName: meal.toString(),
                                });
                                setActiveFood(meal);
                                setOpenBottomBar(true);
                              }}
                            >
                              <MealImageWrapper quantity={meal.quantity === 0}>
                                <img
                                  loading="lazy"
                                  alt="meal image"
                                  src={meal.imageUrl}
                                  width="64"
                                  height="64"
                                />
                              </MealImageWrapper>
                              <Flex flexDirection={"column"} width="100%">
                                <Box mb="5px">
                                  <MealImageText
                                    color="n-dark"
                                    fontWeight={500}
                                    fontSize="14px"
                                  >
                                    {meal.name}
                                  </MealImageText>
                                </Box>
                                <Box mb="5px">
                                  <MealImageText
                                    variant="n-text1"
                                    color="n-grey4"
                                    fontWeight={400}
                                    fontSize="12px"
                                  >
                                    {meal.description}
                                  </MealImageText>
                                </Box>
                                <Box mb="5px">
                                  <Typography
                                    color="n-dark"
                                    fontSize="12px"
                                    fontWeight={500}
                                  >
                                    {formatCurrency(
                                      meal.price as unknown as string,
                                      false
                                    )}
                                  </Typography>
                                </Box>
                              </Flex>
                            </MealsFlex>
                          ))}
                      </div>
                    ))
                  ) : (
                    <Flex
                      mt="150px"
                      flexDirection={"column"}
                      justifyContent="center"
                      alignItems={"center"}
                    >
                      <Typography variant="n-text4">
                        No result found!
                      </Typography>
                      {searchValue && (
                        <Button
                          variant="medium"
                          color="secondary"
                          style={{ color: "white", marginTop: "20px" }}
                          onClick={() => clearSearch()}
                        >
                          Clear Search
                        </Button>
                      )}
                    </Flex>
                  )}
                </MenuList>
              </Flex>

              {!!orderData?.selectedMeals?.length && (
                <ProceedToViewOrder
                  variant="medium"
                  onClick={() => {
                    track("MENU_CUSTOMER_USER_TO_CHECKOUT");
                    confirmSelectedService();
                  }}
                >
                  Go to Checkout ({orderData?.selectedMeals?.length} items for ₦
                  {getSelectedMealTotalPrice()})
                </ProceedToViewOrder>
              )}
            </div>
          </MainPageWrapper>
          <BottomSheetMeal
            isOpen={openBottomBar}
            setIsOpen={setOpenBottomBar}
            activeFood={activeFood}
            addToCart={addToCart}
          />
          <EnterTableNumber
            isOpen={openTableOrTrackNumber}
            setIsOpen={setOpenTableOrTrackNumber}
          />
          <EnterDeliveryArea
            isOpen={openDeliveryArea}
            setIsOpen={setOpenDeliveryArea}
            deliveryAreas={
              productBody?.deliveryLocations as unknown as MenuDeliveryLocations[]
            }
          />
          <EnterOrderIdNumber
            isOpen={openOrderIdNumber}
            setIsOpen={setOpenOrderIdNumber}
          />
          <EnterOrderTypeModal
            selectDelivery={() => {
              setOpenDeliveryArea(true);
            }}
            selectDineIn={() => {
              setOpenTableOrTrackNumber(true);
            }}
            isOpen={openSelectOrderType}
            setIsOpen={setOpenSelectOrderType}
          />
        </>
      ) : (
        <PageLoad />
      )}
    </>
  );
}
