import { MenuListResponse } from "../types";
import { OrderStatus } from "../types/MenuList";
import { ReduxAction } from "../types/redux";
import { menuServiceInit } from "../utils";

interface UpdateOrderStatus extends ReduxAction {
  orderStatus: OrderStatus;
  orderId: string;
  businessId: string;
  accessToken: string;
}

const putChangeMenuOrderStatus = async ({
  orderStatus,
  orderId,
  businessId,
  accessToken,
  onFailure,
  onSuccess,
}: UpdateOrderStatus) => {
  const url = `/webbe/v1/storehouse/orders/${orderId}/update-status/${orderStatus}`;
  try {
    const result: MenuListResponse = await menuServiceInit.put(
      url,
      {},
      {
        headers: {
          "IAM-Authorization": `Bearer ${accessToken}`,
          authorization: `Bearer ${accessToken}`,
          businessId: businessId,
        },
      }
    );
    onSuccess?.(result);
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    onFailure?.(error);
    return Promise.resolve({ error, result: null });
  }
};

export default putChangeMenuOrderStatus;
