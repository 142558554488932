import { useEffect, useState } from 'react';
import useDropdown from '../../hooks/useDropdown';
import { StationType } from '../../types/stations';
import fetchMenuStations from '../../queries/kds/fetchMenuStations';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import useMenuListProductsModel from '../MenuListPageSetup/useMenuListProducts';
import { toast } from 'react-toastify';
import { MenuDataBody } from '../../types';
import { useAppDispatch, useAppSelector } from '../../state/store';
import { setStations, setSelectedStation } from '../../state/kds/slice';
import useFetchMenuStationOrders from '../Kds/models/useFetchMenuStationOrders';
import useFilterKdsOrders from '../Kds/models/useFilterKdsOrders';

export default function useNavFilterModel() {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { stations, selectedStation } = useAppSelector((state) => state.kds);
  const [refresh, toggleRefresh] = useState<boolean>(false);

  const { params } = useFilterKdsOrders();

  const { businessId: businessAlias } = useParams();

  const { productBody } = useMenuListProductsModel(businessAlias as string);

  const { getAccessTokenSilently } = useAuth0();

  const { dropdownRef, dropdownButtonRef, showDropdown, setShowDropdown } = useDropdown();

  const { handleFetchStationOrders } = useFetchMenuStationOrders();

  const handleSelectFilter = (selectedItem: StationType) => {
    dispatch(setSelectedStation(selectedItem));
    setShowDropdown(false);
  };

  const handleFetchMenuStations = async () => {
    const accessToken = await getAccessTokenSilently();
    const { menuId, businessId } = productBody as MenuDataBody;
    setIsLoading(true);
    const { result, error } = await fetchMenuStations({
      menuId,
      accessToken,
      businessId,
    });
    setIsLoading(false);
    if (result) {
      const activeStations = result.data.filter((station) => station.active);
      dispatch(setStations(activeStations));
      if (activeStations.length > 0 && !selectedStation) {
        dispatch(setSelectedStation(activeStations[0]));
      }
    }
    if (error) {
      toast.error('An error occured while fetching stations');
    }
  };

  // continuously fetch orders
  useEffect(() => {
    let interval = setInterval(() => {
      toggleRefresh((prev) => !prev);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  // fetch stations when menu product changes
  useEffect(() => {
    if (!productBody) return;
    const request = new AbortController();
    handleFetchMenuStations();
    return () => request.abort();
  }, [productBody]);

  // fetch orders when station changes
  useEffect(() => {
    if (!selectedStation) return;
    const request = new AbortController();
    handleFetchStationOrders(selectedStation, params);
    return () => request.abort();
  }, [selectedStation, params, refresh]);

  return {
    selectedStation,
    dropdownRef,
    dropdownButtonRef,
    showDropdown,
    setShowDropdown,
    handleSelectFilter,
    stations,
  };
}
