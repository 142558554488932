import { TableOrRoomResponse } from "../types/MenuList";
import { menuServiceInit } from "../utils";

const fetchTableOrRoomNumberDetails = async ({ orderId }: { orderId: string }) => {
  const url = `/webbe/v1/menus/orders/destination/${orderId}`;
  try {
    const result: TableOrRoomResponse = await menuServiceInit.get(url);
    return await Promise.resolve({ error: null, result: result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default fetchTableOrRoomNumberDetails;
