import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  MenuOrderCustomerData,
  OrderSummaryBody,
} from "../../../types/MenuList";
import { useMixPanel } from "../../../hooks/useMixpanel";
import { ErrorResponse } from "../../../types";
import { fetchOrderDetails } from "../../../queries";
import postPayByCash from "../../../queries/postPayByCash";
import { useAuth0 } from "@auth0/auth0-react";
import fetchCustomerDetails from "../../../queries/fetchCustomerDetails";

const useWaiterOrderDetails = ({ orderId }: { orderId: string | null }) => {
  const [orderSummary, setOrderSummary] = useState<OrderSummaryBody>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customerDetails, setCustomerDetails] =
    useState<MenuOrderCustomerData | null>();
  const { user, getAccessTokenSilently } = useAuth0();
  const { track } = useMixPanel();

  const handleFetchCustomerDetails = async (customerId: string) => {
    setIsLoading(true);
    const accessToken = await getAccessTokenSilently();
    const { result, error: fetchError } = await fetchCustomerDetails(
      {
        customerId,
      },
      accessToken,
      user?.group
    );
    setIsLoading(false);
    if (result?.data) setCustomerDetails(result);
    if (fetchError) {
      toast.error(
        (fetchError as ErrorResponse)?.data.message ||
          "An error occured while fetching item customer details"
      );
    }
  };

  const handleGetOrderSummary = async () => {
    setIsLoading(true);

    const response = await fetchOrderDetails({ orderId: orderId as string });
    if (response?.result) {
      track("MENU_WAITER_GET_ORDER_SUMMARY_SUCCESS");
      setOrderSummary(response?.result.data);
      handleFetchCustomerDetails(response?.result?.data?.customerId as string);
      setIsLoading(false);
    }
    if (response?.error) {
      track("MENU_WAITER_ERROR_GETTING_ORDER_SUMMARY");
      toast.error(
        (response?.error as ErrorResponse)?.data.message ||
          "An error occured while fetching order details"
      );
      setIsLoading(false);
    }
  };

  const payByCash = async () => {
    setIsLoading(true);
    const accessToken = await getAccessTokenSilently();
    const response = await postPayByCash(
      {
        orderReference: orderId as string,
        paymentStatus: "PAID",
        paymentMethod: "CASH",
      },
      accessToken
    );
    if (response?.result) {
      track("MENU_WAITER_PAID_BY_CASH_SUCCESS");
      setIsLoading(false);
      handleGetOrderSummary();
    }
    if (response?.error) {
      track("MENU_WAITER_ERROR_PAYING_BY_CASH");
      console.log(response.error);
      toast.error(
        (response?.error as ErrorResponse)?.data.message ||
          "An error occured while fetching order details"
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetOrderSummary();
  }, []);

  return {
    orderSummary,
    handleGetOrderSummary,
    isLoading,
    payByCash,
    customerDetails,
  };
};

export default useWaiterOrderDetails;
