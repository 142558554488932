interface AppConfig {
  menuBaseUrl?: string;
  dashboardBaseUrl?: string;
  mixPanelToken?: string;
  auth0Domain: string;
  auth0ClientId: string;
  auth0Audience: string;
  auth0Scope: string;
  auth0Redirect: string;
}

const config: AppConfig = {
  menuBaseUrl: process.env.REACT_APP_MENU_BASEURL,
  dashboardBaseUrl: process.env.REACT_APP_DASHBOARD_BASEURL,
  mixPanelToken: process.env.REACT_APP_MIXPANEL_API_TOKEN,
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENTID || "",
  auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
  auth0Scope: process.env.REACT_APP_AUTH0_SCOPE || "",
  auth0Redirect: process.env.REACT_APP_AUTH0_REDIRECT || "",
};

export default config;
