import { fetchMenuStationsResponse } from "../../types/stations";
import { menuServiceInit } from "../../utils";

const fetchMenuStations = async ({
  menuId,
  accessToken,
  businessId,
}: {
  menuId: string;
  accessToken: string;
  businessId: string;
}) => {
  const url = `/webbe/v1/menu-stations/menus/${menuId}`;
  try {
    const result: fetchMenuStationsResponse = await menuServiceInit.get(url, {
      headers: {
        "iam-authorization": `Bearer ${accessToken}`,
        authorization: `Bearer ${accessToken}`,
        businessId,
      },
    });
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default fetchMenuStations;
