import { Box, Flex } from "rebass";
import {
  BackButton,
  Button,
  IconsEnum,
  NIcons,
  Typography,
} from "kudi-component-library";
import styled from "styled-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import BottomSheetIncDec from "../BottomSheetIncDec";
import { toast, ToastContentProps } from "react-toastify";
import { MainPageWrapper } from "../Layout";
import { useOrderData } from "../../state/context";
import { formatCurrency } from "../../utils";
import { useNavigate, useParams } from "react-router-dom";
import { useMixPanel } from "../../hooks/useMixpanel";
import { Category, MenuListProduct } from "../../types";
import {
  EllipsedText,
  ModalClose,
  PriceSummarySection,
  ViewMoreButton,
  HeadBox,
  MealTypeSwitch,
  SearchBarBox,
  SearchMealsInput,
} from "../Styles";
import useEditOrder from "./models/useEditOrder";
import CartImage from "../Icons/CartImage";
import BottomSheetMeal from "../BottomSheetMeal";
import { Oval } from "react-loader-spinner";

const MealsFlex = styled(Flex)`
  transition: all 0.15s ease;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.nColors["n-grey1"]};
  padding: 10px;
  cursor: pointer;
`;

const MenuList = styled.section`
  margin: 20px 0;
  width: 100%;
`;

const MealTypeBox = styled.div`
  background: ${({ theme }) => theme.nColors["n-light"]};
  border-bottom: 1px solid ${({ theme }) => theme.nColors["n-grey1"]};
  z-index: 2;
`;

const MealImageText = styled(Typography)`
  text-overflow: ellipsis;
  max-width: 80%;
  overflow: hidden;
  white-space: nowrap;
`;

const PageBody = styled(Flex)`
  width: 100%;
  @media (min-width: 1023px) {
    max-width: 500px;
  }
`;
const PlaceOrderButton = styled(Button)`
  width: 100%;
`;

const DeleteIcon = styled(Flex)`
  width: 50px;
  height: 40px;
  background: ${({ theme }) => theme.nColors["n-grey1"]};
  border-radius: 50%;
  margin-right: 10px !important;
  justify-content: center;
  align-items: center;
`;

const DescriptionText = styled(EllipsedText)`
  max-width: 80%;
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
const AddNewItemButton = styled(ViewMoreButton)`
  height: inherit;
  width: inherit;
  span {
    padding: 6px 12px;
  }
`;

const PageHeadBox = styled(Box)`
  border-bottom: solid 1px ${({ theme }) => theme.nColors["n-grey2"]};
  padding-bottom: 20px;
`;

const WaiterEditOrder = () => {
  const navigate = useNavigate();
  const { track } = useMixPanel();
  const { orderId } = useParams();

  const {
    editOrderBody,
    orderSummary,
    setEditOrderBody,
    handleEditOrder,
    isEditLoading,
  } = useEditOrder({
    orderId: orderId as string,
  });

  let recentUndo: MenuListProduct[] | undefined = ({} = []);

  const qtyMinusChanged = (selectedItem: any) => {
    let tempOrderData = { ...editOrderBody! };

    let selectedProduct = tempOrderData.items.map((item) => {
      if (item.id === selectedItem.id) {
        if (item.quantity <= 1) {
          toast.error("Quantity can not be less than 1");
          return item;
        }
        item.quantity = item.quantity - 1;
      }

      return item;
    });

    setEditOrderBody({
      ...editOrderBody!,
      items: selectedProduct,
    });
  };

  const qtyPlusChanged = (selectedItem: any) => {
    let tempOrderData = { ...editOrderBody! };

    let selectedProduct = tempOrderData.items.map((item) => {
      if (item.id === selectedItem.id) {
        if (item.quantity >= 10) {
          toast.error("Quantity can not be greater than 10");
          return item;
        }
        item.quantity = item.quantity + 1;
      }

      return item;
    });

    setEditOrderBody({ ...editOrderBody!, items: selectedProduct });
  };

  const onUndo = () => {
    setEditOrderBody({
      ...editOrderBody!,
      items: recentUndo as MenuListProduct[],
    });
  };

  const RenderItemDeleteToast = ({ closeToast }: ToastContentProps) => (
    <Flex>
      <DeleteIcon>
        <NIcons icon={IconsEnum.OUTLINE_TRASH} color="n-red" width="13" />
      </DeleteIcon>
      <Flex justifyContent={"space-between"} alignItems={"center"} width="100%">
        <Typography
          color="n-dark"
          style={{
            fontSize: "16px",
            lineHeight: "22px",
            fontWeight: "400",
          }}
        >
          Item removed!
        </Typography>
        <Typography
          color="n-dark"
          style={{
            fontSize: "14px",
            lineHeight: "22px",
            fontWeight: "400",
            cursor: "pointer",
          }}
        >
          <span
            onClick={() => {
              if (closeToast) {
                onUndo();
                closeToast();
              }
            }}
          >
            Undo
          </span>
        </Typography>
      </Flex>
    </Flex>
  );

  const deleteItem = (selectedItem: MenuListProduct) => {
    const updatedOrderSummary = { ...editOrderBody! };
    recentUndo = orderSummary?.items;
    updatedOrderSummary.items = updatedOrderSummary.items.filter(
      (item) => item.id !== selectedItem.id
    );

    setEditOrderBody(updatedOrderSummary);

    toast(RenderItemDeleteToast);
  };

  const addToOrderSummary = (
    selectedItem: MenuListProduct,
    quantity: number
  ) => {
    const updatedOrderSummary = { ...editOrderBody! };
    const existingItemIndex = updatedOrderSummary.items.findIndex(
      (item) => item.id === selectedItem.id
    );

    if (existingItemIndex !== -1) {
      updatedOrderSummary.items[existingItemIndex].quantity += quantity;
    } else {
      updatedOrderSummary?.items?.push({
        ...selectedItem,
        quantity: quantity,
      });
    }

    console.log(updatedOrderSummary);
    console.log(orderSummary);

    // setEditOrderBody(updatedOrderSummary);
  };

  const addToCart = (activeFoodItem: MenuListProduct, quantityVal: number) => {
    addToOrderSummary(activeFoodItem, quantityVal);
  };

  const [activeMenu, setActiveMenu] = useState(0);
  const [categories, setCategories] = useState<Category[]>([]);
  const [activeFood, setActiveFood] = useState<MenuListProduct>();
  const [showActiveScreen, setShowActiveScreen] = useState<"list" | "summary">(
    "summary"
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [meals, setMeals] = useState<MenuListProduct[]>();
  const [searchMeals, setSearchMeals] = useState<MenuListProduct[]>();
  const [openMealBar, setOpenMealBar] = useState(false);

  const { businessData } = useOrderData();

  const clearSearch = () => {
    setSearchMeals(businessData?.items);
    setMeals(businessData?.items);
    setSearchValue("");
  };

  const categoriesFilter = (id: number, mealId?: number) => {
    if (id === 0) {
      setActiveMenu(id);
      clearSearch();
      return;
    }
    const newData = searchMeals?.filter(
      (item: MenuListProduct) => item.category.id === mealId
    );
    setMeals(newData);
    setActiveMenu(id);
  };

  const searchFilter = (text: string) => {
    categoriesFilter(0);
    const newData = searchMeals?.filter(
      (item: MenuListProduct) =>
        item.name.toUpperCase().indexOf(text.toUpperCase()) > -1
    );

    setMeals(newData);
    setSearchValue(text);
  };

  function extractUniqueCategories(listProduct?: MenuListProduct[]) {
    const uniqueCategories: Category[] = [];

    if (listProduct) {
      listProduct.forEach((item) => {
        if (
          item.category.hasOwnProperty("id") &&
          !uniqueCategories.some((uniqs) => uniqs.id === item.category.id)
        ) {
          uniqueCategories.push(item.category);
        }
      });
    }

    return uniqueCategories;
  }

  useEffect(() => {
    if (businessData) {
      const uniqueCategoriesArray = extractUniqueCategories(
        businessData?.items
      );
      setMeals(businessData?.items);
      setSearchMeals(businessData?.items);
      setCategories(uniqueCategoriesArray);
    }
  }, [businessData]);

  const getSelectedMealTotalPrice = () => {
    let subTotalPrice = 0;
    editOrderBody?.items?.forEach((item) => {
      subTotalPrice += item.price * item.quantity;
    });

    const taxOnOrder = (7.5 / 100) * subTotalPrice;

    const totalAmount = subTotalPrice + taxOnOrder;

    return {
      subTotalPrice,
      taxOnOrder,
      totalAmount,
    };
  };

  const categoryRefs: { [key: string]: HTMLDivElement | null } = {};

  useEffect(() => {
    const handleScroll = () => {
      if (businessData) {
        const mealSections = document.querySelectorAll(".meal-category");
        const uniqueCategoriesArray = extractUniqueCategories(
          businessData.items
        );
        let currentCategoryInView = uniqueCategoriesArray[0]?.id;

        mealSections.forEach((section) => {
          const rect = section.getBoundingClientRect();
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            currentCategoryInView = Number(
              section.getAttribute("data-category")
            );
          }
        });

        setActiveMenu(currentCategoryInView);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [businessData]);

  const scrollToCategory = ({ name, id }: Category) => {
    if (categoryRefs !== null && categoryRefs[name] !== null) {
      categoryRefs[name]!.scrollIntoView({ behavior: "smooth", block: "end" });
      setActiveMenu(id);
    }
  };

  const goToCheckout = () => {
    if (editOrderBody && editOrderBody.items.length > 0) {
      handleEditOrder();
    } else {
      toast.error(
        "Please add items to the order before proceeding to checkout."
      );
    }
  };

  return (
    <MainPageWrapper>
      <div className="pageSetup">
        {showActiveScreen === "summary" && (
          <Flex py="30px" flexDirection="column">
            <PageHeadBox>
              <Flex
                justifyContent={"space-between"}
                alignItems="center"
                mb="20px"
                width="100%"
                px="20px"
              >
                <Typography variant="n-text4">Order Summary</Typography>
                <ModalClose
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <NIcons
                    icon={IconsEnum.OUTLINE_X}
                    color="n-grey4"
                    height="20"
                    width="20"
                  />
                </ModalClose>
              </Flex>
              <Flex px="20px">
                <AddNewItemButton
                  variant="medium"
                  color="secondary"
                  onClick={() => {
                    setShowActiveScreen("list");
                  }}
                >
                  Add new item
                </AddNewItemButton>
              </Flex>
            </PageHeadBox>
            <Box mb={"300px"} px="20px">
              {editOrderBody ? (
                editOrderBody?.items.length > 0 ? (
                  editOrderBody?.items.map((item, index) => (
                    <Box mb="30px" key={item.id}>
                      <Flex justifyContent={"space-between"} mt="20px">
                        <EllipsedText variant="n-text3" fontWeight="heavy">
                          {item.name}
                        </EllipsedText>
                        <Button
                          variant="xsmall"
                          style={{ background: "transparent" }}
                          onClick={() => deleteItem(item)}
                        >
                          <NIcons
                            icon={IconsEnum.OUTLINE_TRASH}
                            color="n-red"
                            width="13"
                          />
                        </Button>
                      </Flex>
                      <Flex>
                        <DescriptionText variant="n-text2">
                          {item.description}
                        </DescriptionText>
                      </Flex>
                      <Flex
                        justifyContent={"space-between"}
                        mb="10px"
                        mt="10px"
                        alignItems="end"
                      >
                        <BottomSheetIncDec
                          buttonSize={"22px"}
                          decreaseQuantity={() => qtyMinusChanged(item)}
                          quantity={item.quantity}
                          increaseQuantity={() => qtyPlusChanged(item)}
                        />

                        <Typography variant="n-text3" fontWeight={500}>
                          {formatCurrency(
                            (item.price * item.quantity) as unknown as string,
                            false
                          )}
                        </Typography>
                      </Flex>
                    </Box>
                  ))
                ) : (
                  <Flex
                    mt="150px"
                    flexDirection={"column"}
                    justifyContent="center"
                    alignItems={"center"}
                  >
                    <Typography variant="n-text4">No Orders found!</Typography>
                  </Flex>
                )
              ) : (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  mt="120px"
                >
                  <Oval
                    height={60}
                    width={60}
                    color="#ffcc00"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#332900"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </Flex>
              )}
            </Box>
            {editOrderBody && editOrderBody?.items.length > 0 ? (
              <PriceSummarySection>
                <Flex justifyContent={"space-between"} mb="5px">
                  <Typography
                    color="n-grey4"
                    fontWeight={400}
                    style={{
                      fontSize: "14px",
                      lineHeight: "22px",
                    }}
                  >
                    Subtotal
                  </Typography>
                  <Typography
                    color="n-dark"
                    fontWeight={400}
                    style={{
                      fontSize: "14px",
                      lineHeight: "22px",
                    }}
                  >
                    {formatCurrency(
                      getSelectedMealTotalPrice()
                        .subTotalPrice as unknown as string,
                      false
                    )}
                  </Typography>
                </Flex>
                <Flex justifyContent={"space-between"} mb="5px">
                  <Typography
                    color="n-grey4"
                    fontWeight={400}
                    style={{
                      fontSize: "14px",
                      lineHeight: "22px",
                    }}
                  >
                    Tax
                  </Typography>
                  <Typography
                    color="n-dark"
                    fontWeight={400}
                    style={{
                      fontSize: "14px",
                      lineHeight: "22px",
                    }}
                  >
                    {formatCurrency(
                      (getSelectedMealTotalPrice()
                        .taxOnOrder as unknown as string) || "",
                      false
                    )}
                  </Typography>
                </Flex>

                <Flex justifyContent={"space-between"} mb="5px">
                  <Typography
                    color="n-dark"
                    fontWeight={500}
                    style={{
                      fontSize: "14px",
                      lineHeight: "38px",
                    }}
                  >
                    Total
                  </Typography>
                  <Typography
                    color="n-dark"
                    fontWeight={600}
                    style={{
                      fontSize: "14px",
                      lineHeight: "22px",
                    }}
                  >
                    {formatCurrency(
                      getSelectedMealTotalPrice()
                        .totalAmount as unknown as string,
                      false
                    )}
                  </Typography>
                </Flex>
                <Box mt="20px">
                  <PlaceOrderButton
                    variant="medium"
                    onClick={() => {
                      track(
                        "MENU_CUSTOMER_CLICKS_PLACE_ORDER_TO_INPUT_CUSTOMER_DETAILS"
                      );
                      goToCheckout();
                    }}
                    // disabled={isGoBackDisabled()}
                  >
                    Go to Checkout
                  </PlaceOrderButton>
                </Box>
              </PriceSummarySection>
            ) : null}
          </Flex>
        )}
        {showActiveScreen === "list" && (
          <Box p="30px" mb="200px">
            <HeadBox>
              <Flex justifyContent="space-between">
                <BackButton
                  style={{
                    alignItems: "center",
                    textDecoration: "none",
                  }}
                  onClick={() => setShowActiveScreen("summary")}
                >
                  Back
                </BackButton>
              </Flex>
              <Box my="20px">
                <Typography variant="n-text5">Choose Menu</Typography>
              </Box>
              <SearchBarBox my="20px">
                <SearchMealsInput
                  placeholder="Search menu items.."
                  name="search"
                  type="text"
                  style={{ borderRadius: "30px", paddingLeft: "10px" }}
                  value={searchValue}
                  onChange={(e) => {
                    searchFilter(e.target.value);
                  }}
                />
              </SearchBarBox>
              {meals && meals.length > 0 && (
                <MealTypeBox>
                  <MealTypeSwitch
                    width={"100%"}
                    alignItems="center"
                    pb="20px"
                    px="5px"
                  >
                    {categories &&
                      categories.map((mealType, index) => (
                        <span
                          key={mealType.id}
                          className={
                            activeMenu === mealType.id ? "activeButton" : ""
                          }
                          onClick={() => scrollToCategory(mealType)}
                        >
                          {mealType.name}
                        </span>
                      ))}
                  </MealTypeSwitch>
                </MealTypeBox>
              )}
            </HeadBox>
            <MenuList>
              {categories && meals && meals.length > 0 ? (
                categories.map((mealType, index) => (
                  <div
                    key={mealType.id}
                    className="meal-category"
                    data-category={mealType.id}
                    ref={(ref) => {
                      categoryRefs[mealType.name] = ref;
                    }}
                  >
                    <Box my="20px">
                      <Typography color="n-grey4" fontWeight={700}>
                        {mealType.name}
                      </Typography>
                    </Box>
                    {meals
                      .filter(
                        (meal: MenuListProduct) =>
                          meal.category.id === mealType.id
                      )
                      .map((meal: MenuListProduct) => (
                        <MealsFlex
                          key={meal.id}
                          mb="10px"
                          onClick={() => {
                            setActiveFood(meal);
                            setOpenMealBar(true);
                          }}
                        >
                          <Flex flexDirection={"column"} width="100%">
                            <Box mb="5px">
                              <MealImageText
                                color="n-dark"
                                fontWeight={500}
                                fontSize="14px"
                              >
                                {meal.name}
                              </MealImageText>
                            </Box>
                            <Box mb="5px">
                              <MealImageText
                                variant="n-text1"
                                color="n-grey4"
                                fontWeight={400}
                                fontSize="12px"
                              >
                                {meal.description}
                              </MealImageText>
                            </Box>
                            <Box mb="5px">
                              <Typography
                                color="n-dark"
                                fontSize="12px"
                                fontWeight={500}
                              >
                                {formatCurrency(
                                  meal.price as unknown as string,
                                  false
                                )}
                              </Typography>
                            </Box>
                          </Flex>
                        </MealsFlex>
                      ))}
                  </div>
                ))
              ) : (
                <Flex
                  mt="150px"
                  flexDirection={"column"}
                  justifyContent="center"
                  alignItems={"center"}
                >
                  <Typography variant="n-text4">No result found!</Typography>
                  {searchValue && (
                    <Button
                      variant="medium"
                      color="secondary"
                      style={{ color: "white", marginTop: "20px" }}
                      onClick={() => clearSearch()}
                    >
                      Clear Search
                    </Button>
                  )}
                </Flex>
              )}
            </MenuList>
            {/* {!isGoBackDisabled() && ( */}
            <PriceSummarySection>
              <Button
                variant="medium"
                style={{
                  width: "100%",
                }}
                onClick={() => {
                  setShowActiveScreen("summary");
                }}
                loading={isEditLoading}
              >
                Go back to Summary ({editOrderBody?.items.length} items for{" "}
                {formatCurrency(
                  (getSelectedMealTotalPrice()
                    .subTotalPrice as unknown as string) || "",
                  false
                )}
                )
              </Button>
            </PriceSummarySection>
            {/* )} */}
          </Box>
        )}{" "}
      </div>

      <BottomSheetMeal
        isOpen={openMealBar}
        setIsOpen={setOpenMealBar}
        activeFood={activeFood}
        addToCart={addToCart}
        noImage
      />
    </MainPageWrapper>
  );
};

export default WaiterEditOrder;
