import { Box } from "rebass";
import { Dispatch, ReactNode, SetStateAction } from "react";
import BottomSheetModal from "../BottomSheetModal";

interface FilterModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
}

const FilterModal = ({ isOpen, setIsOpen, children }: FilterModalProps) => {

  return (
    <BottomSheetModal
      title="Add filter"
      subTitle=""
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Box mt={70}>{children}</Box>
    </BottomSheetModal>
  );
};

export default FilterModal;
