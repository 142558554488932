import { PlacedOrderResponse, PlaceOrderPayload } from "../types/MenuList";
import { menuServiceInit } from "../utils";

const postPlaceOrder = async (payload: PlaceOrderPayload) => {
  const url = `/webbe/v1/menu-orders/create`;
  try {
    const result: PlacedOrderResponse = await menuServiceInit.post(
      url,
      payload
    );
    return await Promise.resolve({ error: null, result: result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default postPlaceOrder;
