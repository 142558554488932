import { OrdersListDataResponse } from "../types/MenuList";
import { menuServiceInit } from "../utils";

const fetchOrderList = async ({
  params,
  accessToken,
}: {
  params?: URLSearchParams;
  accessToken: string;
}) => {
  const url = `/webbe/v1/storehouse/waiter/orders?${params ? params.toString() : ""}`;
  try {
    const result: OrdersListDataResponse = await menuServiceInit.get(url, {
      headers: {
        "iam-authorization": `Bearer ${accessToken}`,
        authorization: `Bearer ${accessToken}`
      },
    });
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default fetchOrderList;
