import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import loader from "./loader";

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
  ResourceNotFound = 417,
}
const headers: Readonly<Record<string, string | boolean>> = {
  Accept: "*/*",
};
// We can use the following function to inject the JWT token through an interceptor
// We get the `accessToken` from the localStorage that we set when we authenticated

class HttpConfig {
  private serviceBaseUrl: string;

  constructor(url: string, IAMAuthtoken?: boolean) {
    this.serviceBaseUrl = url;
  }

  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const localInstance = axios.create({
      baseURL: this.serviceBaseUrl,
      headers,
    });

    localInstance.interceptors.response.use(
      (response) => {
        loader.hide();
        return response.data as AxiosResponse;
      },
      (error: AxiosError) => {
        const responseData = error;
        loader.hide();
        return this.handleError(responseData);
      }
    );
    this.instance = localInstance;
    return localInstance;
  }

  request<T = any, R = AxiosResponse<T>>(
    config: AxiosRequestConfig
  ): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }
  // Handle global app errors
  // We can handle generic app errors depending on the status code
  // eslint-disable-next-line class-methods-use-this

  // eslint-disable-next-line class-methods-use-this
  private handleError(error: AxiosError) {
    switch (error?.response?.status) {
      case StatusCode.InternalServerError: {
        // Handle InternalServerError
        break;
      }
      case StatusCode.Forbidden: {
        // Handle Forbidden
        break;
      }

      case StatusCode.TooManyRequests: {
        // Handle TooManyRequests
        break;
      }
      default:
        break;
    }
    return Promise.reject(error.response);
  }
}

export default HttpConfig;
