import { NTheme } from "kudi-component-library";
import { OrderStatus } from "../types/MenuList";

export const ORDER_STATUS_COLORS: Record<
  OrderStatus,
  { background: string; color: string }
> = {
  INITIATED: {
    background: NTheme.nColors["n-grey1"],
    color: NTheme.nColors["n-grey5"],
  },
  IN_PROGRESS: {
    background: NTheme.nColors["n-yellow1"],
    color: NTheme.nColors["n-yellow7"],
  },
  COMPLETED: {
    background: NTheme.nColors["n-green1"],
    color: NTheme.nColors["n-green"],
  },
  FULFILLED: {
    background: NTheme.nColors["n-green1"],
    color: NTheme.nColors["n-green"],
  },
  CANCELLED: {
    background: NTheme.nColors["n-red1"],
    color: NTheme.nColors["n-red"],
  },
};

export const returnFormattedOrderStatusName = (status: OrderStatus) => {
  let orderStatusVal: string = status;
  if (status === "INITIATED") {
    orderStatusVal = "New order";
  }
  if (status === "IN_PROGRESS") {
    orderStatusVal = "Processing";
  }
  return orderStatusVal;
};
