import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../state/store';
import { AppliedFilter } from '../../newFilter/defs';
import { clearOrderTypeAndStatus, updateOrderTypeFilter, updateSearchFilter, updateStatusFilter } from '../../../state/kds/slice';
import { OrderStatus, OrderType } from '../../../types/MenuList';

const useFilterKdsOrders = () => {
    const dispatch = useAppDispatch()
    const [params, setParams] = useState<URLSearchParams>();
    const { filters } = useAppSelector((state) => state.kds);

    const updateFilters = () => {
        const newParams = new URLSearchParams();
        newParams.set("date", filters.date)
        newParams.set("orderType", filters.orderType)
        newParams.set("orderStatus", filters.status);
        if (filters.itemSummary !== "") {
            newParams.set("itemSummary", filters.itemSummary)
        }
        setParams(newParams);
    }

    const handleFilterChange = (filterItems: AppliedFilter[]) => {
        const containsOrderType = filterItems?.some((item) => item.query === "orderType");
        const containsStatus = filterItems?.some((item) => item.query === "status");
        const containsItemSummary = filterItems?.some((item) => item.query === "itemSummary");

        if (!containsOrderType) {
            dispatch(updateOrderTypeFilter("DEFAULT"))
        }
        if (!containsStatus) {
            dispatch(updateStatusFilter("NONE"))
        }
        if (!containsItemSummary) {
            dispatch(updateSearchFilter(""))
        }
    }

    const handleFilterOrders = (filterItems: AppliedFilter[]) => {
        filterItems.forEach((item) => {
            const { query, value } = item;
            if (query === "orderType") {
                dispatch(updateOrderTypeFilter(value as OrderType))
            } if (query === "status") {
                dispatch(updateStatusFilter(value as OrderStatus))
            }
        });
        handleFilterChange(filterItems)
    };



    const handleSearchOrders = (searchTerm: string) => {
        dispatch(updateSearchFilter(searchTerm));
    };

    const handleClearFilters = () => {
        dispatch(clearOrderTypeAndStatus())
    };

    useEffect(() => {
        updateFilters();
    }, [filters])
    return { params, handleFilterOrders, handleClearFilters, handleSearchOrders }
}

export default useFilterKdsOrders
