import { Box, Flex } from "rebass";
import { Dispatch, SetStateAction } from "react";
import { Button, Typography } from "kudi-component-library";
import { useMixPanel } from "../../hooks/useMixpanel";
import BottomSheetModal from "../BottomSheetModal";
import { UnderlinedButton } from "../Styles";

interface ConfirmPayByCashModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onProceed: () => void;
}

const ConfirmPayByCashModal = ({
  isOpen,
  setIsOpen,
  onProceed,
}: ConfirmPayByCashModalProps) => {
  const { track } = useMixPanel();

  const onCancel = () => {
    setIsOpen(false);
  };

  return (
    <BottomSheetModal
      title=""
      subTitle=""
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Box mt={70}>
        <Flex
          justifyContent="center"
          alignItems={"center"}
          flexDirection="column"
        >
          <Box mb="16px">
            <Typography variant="n-text5" fontWeight="heavy">
              Are you sure?
            </Typography>
          </Box>
          <Typography style={{ textAlign: "center" }} variant="n-text2">
            Are you sure this order has been paid via cash? Kindly note that
            this action cannot be undone. Thank you
          </Typography>
        </Flex>

        <Flex
          mt="50px"
          justifyContent={"center"}
          alignItems={"center"}
          style={{ gap: "15px" }}
          flexDirection="column"
        >
          <Button
            variant="medium"
            style={{ width: "100%" }}
            onClick={() => {
              track("MENU_CUSTOMER_CLICKS_CONFIRM_CASH_PAYMENT");
              onProceed();
            }}
          >
            Yes, confirm payment by cash
          </Button>
          <Box
            mt={"20px"}
            onClick={() => {
              onCancel();
            }}
          >
            <UnderlinedButton>Cancel</UnderlinedButton>
          </Box>
        </Flex>
      </Box>
    </BottomSheetModal>
  );
};

export default ConfirmPayByCashModal;
