import { Button, TextInput, Typography } from "kudi-component-library";
import { Box, Flex } from "rebass";
import styled, { DefaultTheme, css } from "styled-components";
import { ORDER_STATUS_COLORS } from "../utils/status";
import { OrderStatus } from "../types/MenuList";

// common input styles
const genaralItemStyles = ({
  theme,
  error,
  isFocus,
  disabled,
  dashed,
}: {
  theme: DefaultTheme;
  error?: string;
  isFocus?: boolean;
  disabled?: boolean;
  dashed?: boolean;
}) => css`
  height: 100%;
  width: 100%;
  background-color: ${error && theme.colors.secondary.danger2};

  border: ${`1px ${dashed ? "dashed" : "solid"} ${
    // eslint-disable-next-line no-nested-ternary
    error
      ? theme.colors.secondary.danger1
      : isFocus
      ? theme.nColors["n-grey2"]
      : theme.nColors["n-grey1"]
  }`};

  border-radius: 4px;
  outline: none;

  ${disabled
    ? `
      background-color: ${theme.colors.primary.black6}};
      border: 1px solid ${theme.colors.primary.black6};
      color: ${theme.colors.primary.black3};
      cursor: not-allowed
    `
    : `:disabled {
      background-color: ${theme.colors.primary.black6};
      border: 1px solid ${theme.colors.primary.black6};
      color: ${theme.colors.primary.black3};
      cursor: not-allowed;
    }`}
`;

export const InputWrapper = styled.div<{ disabled?: boolean; width?: string }>`
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 4px;
  width: ${({ width }) => width || "420px"};
  position: relative;
  color: ${({ theme }) => theme.nColors["n-dark"]};

  .flag-dropdown {
    background-color: transparent !important;
    border: none !important;
  }

  .react-tel-input .selected-flag:hover,
  .react-tel-input .selected-flag:focus {
    background-color: transparent !important;
  }

  .react-tel-input .form-control {
    border: none;
    background-color: transparent !important;
    width: 100%;
    height: 46px;
  }

  .react-tel-input .flag-dropdown.open .selected-flag {
    background: transparent !important;
  }

  .react-tel-input .selected-flag {
    padding: 0;
  }

  .react-tel-input .selected-flag .flag {
    transform: scale(1.2);
  }

  .react-tel-input .selected-flag .arrow {
    border-top: 5px solid #bbbbbb;
  }

  .react-tel-input .selected-flag .arrow.up {
    border-bottom: 5px solid #bbbbbb;
  }
  input#phoneNumberInput::placeholder {
    letter-spacing: 0;
  }
`;

export const Label = styled.label<{ isForm?: boolean }>`
  color: ${({ theme }) => theme.colors.primary.black2};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: #121212;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 400;
  ${({ isForm }) =>
    isForm &&
    `
        color: #121212;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        `}
`;

export const InputItemErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  /* margin-top: 12px; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.secondary.danger1};

  span {
    display: block;
    margin-left: 4px;
  }
`;

// Input Item
export const InputItem = styled.input<{
  error?: string;
  isFocus?: boolean;
}>`
  ${({ theme, error, isFocus }) =>
    genaralItemStyles({ theme, error, isFocus })};

  font-size: 14px;
  line-height: 150%;
  height: 46px;
  padding: 0 12px;
  font-weight: 600;

  :active,
  :focus {
    border: 1px solid ${({ theme }) => theme.colors.primary.black2};
  }
`;

// Select Item
export const SelectItem = styled.div<{
  error?: string;
  open?: boolean;
  disabled?: boolean;
  isFocus?: boolean;
}>`
  ${({ theme, error, isFocus, disabled }) =>
    genaralItemStyles({ theme, error, isFocus, disabled })};

  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  height: 46px;
  padding: 0 24px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
  }

  svg {
    transform: ${({ open }) => (open ? "rotate(0deg)" : "rotate(180deg)")};
  }
`;

export const SelectOptionsWrapper = styled.div<{
  open?: boolean;
  isFocus?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.primary.black7};
  border-radius: 4px;
  position: absolute;
  top: 90px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 11px 0;
  z-index: 5;

  ${({ open }) =>
    !open
      ? `
    height: 0;
    width: 0;
    outline: none;
    background-color: transparent;
    opacity: 0;
    padding: 0;
    margin: 0;
  `
      : ""};
`;

export const SelectOption = styled.div<{ active?: boolean }>`
  border-radius: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  padding: 5px 15px;
  margin-bottom: 6px;
  cursor: pointer;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.primary.black6 : "transparent"};
`;

export const DownShiftOptionsWrapper = styled.ul<{
  open?: boolean;
  isFocus?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.primary.black7};
  border-radius: 4px;
  position: absolute;
  top: 70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 11px 0;
  z-index: 5;
  list-style-type: none;
  max-height: 200px;
  overflow-y: auto;

  ${({ open }) =>
    !open
      ? `
      height: 0;
      width: 0;
      outline: none;
      background-color: transparent;
      opacity: 0;
      padding: 0;
      margin: 0;
    `
      : ""};
`;

export const DownShiftOption = styled.li<{ active?: boolean }>`
  border-radius: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  padding: 10px 15px;
  margin-bottom: 6px;
  cursor: pointer;
  color: ${({ theme }) => theme.nColors["n-grey6"]};
  background-color: ${({ theme, active }) =>
    active ? theme.colors.primary.black6 : "transparent"};
`;

export const DownShiftItem = styled.button<{
  error?: string;
  open?: boolean;
  disabled?: boolean;
  isFocus?: boolean;
}>`
  ${({ theme, error, isFocus, disabled }) =>
    genaralItemStyles({ theme, error, isFocus, disabled })};

  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  height: 46px;
  padding: 0 24px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;
  background-color: #fff;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  svg {
    transform: ${({ open }) => (open ? "rotate(180deg)" : "rotate(0deg)")};
  }
`;

export const EllipsedText = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 60%;
`;

export const Separator = styled.div<{ height?: string; width?: string }>`
  width: ${({ width }) => width || "0px"};
  height: ${({ height }) => height || "0px"};
`;

export const SearchMealsInput = styled(TextInput)`
  background: transparent;
  color: ${({ theme }) => theme.nColors["n-dark"]};
  width: 100%;
  padding: 10px;
  & > div {
    width: 100%;
    padding: 0;
  }
`;

export const MealTypeSwitch = styled(Flex)`
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;

  & span {
    background: none;
    color: #999999;
    border: none;
    margin-right: 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding: 6px 15px;
    min-width: fit-content;
    height: 34px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .activeButton {
    background: #121212;
    border-radius: 30px;
    color: #fff;
    padding: 6px 15px;
    transition: all 0.15s ease;
  }
`;

export const ProceedToViewOrder = styled(Button)`
  position: fixed;
  z-index: 1;
  width: 90%;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 800px;
`;
export const SearchBarBox = styled(Flex)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    padding: 0 20px;
    max-width: 50%;
    @media (max-width: 1023px) {
      & {
        max-width: 100%;
        padding: 0;
      }
    }
  }
`;

export const PriceSummarySection = styled(Box)`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 30px 20px;
  background: ${({ theme }) => theme.nColors["n-light"]};
  box-shadow: 0px 8px 20px 0px rgba(196, 196, 196, 0.3);
  max-width: 800px;
  left: 50%;
  transform: translate(-50%, 0);
`;

export const ModalClose = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  border: solid 1px ${({ theme }) => theme.nColors["n-grey1"]};
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 3;
`;

export const UnderlinedButton = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.nColors["n-yellow"]};
  cursor: pointer;
`;

export const OrderStatusBadge = styled.div<{ status: OrderStatus }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0.2rem 1rem;
  border-radius: 50px;
  background-color: ${({ status }) => ORDER_STATUS_COLORS[status].background};
  color: ${({ status }): string => ORDER_STATUS_COLORS[status].color};
`;

export const HeadBox = styled(Box)`
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
`;

export const NotesBox = styled(Box)`
  width: 100%;
  background: ${({ theme }) => theme.nColors["n-grey0"]};
`;

export const ViewMoreButton = styled(Button)`
  width: 100%;
  background: transparent;
  color: ${({ theme }) => theme.nColors["n-dark"]};
  border: solid 1px ${({ theme }) => theme.nColors["n-dark"]};
`;

export const OrderItem = styled(Box)`
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.nColors["n-grey1"]};
  background: ${({ theme }) => theme.nColors["n-grey0"]};
  width: 100%;
  cursor: pointer;
`;
