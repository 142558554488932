import { Box, Flex } from "rebass";
import { Input } from "@rebass/forms";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { Button, Typography } from "kudi-component-library";
import styled from "styled-components";
import { useMixPanel } from "../hooks/useMixpanel";
import BottomSheetModal from "./BottomSheetModal";
import { useNavigate } from "react-router-dom";

const PlaceOrderButton = styled(Button)`
  width: 100%;
`;

const PlaceOrderInput = styled(Input)`
  margin-top: 5px;
  font-size: 70px !important;
  line-height: 38px !important;
  font-weight: 600;
  color: ${({ theme }) => theme.nColors["n-dark"]} !important;
  border: none !important;
  border-bottom: solid 1px ${({ theme }) => theme.nColors["n-grey1"]} !important;
  outline: none !important;
  &:hover. &:active,
  &:focus,
  &:focus-visible {
    border: none !important;
    outline: none !important;
  }
  /* do not group these rules */
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.nColors["n-grey1"]};
  }
  &:-moz-placeholder {
    /* FF 4-18 */
    color: ${({ theme }) => theme.nColors["n-grey1"]};
    opacity: 1;
  }
  &::-moz-placeholder {
    /* FF 19+ */
    color: ${({ theme }) => theme.nColors["n-grey1"]};
    opacity: 1;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: ${({ theme }) => theme.nColors["n-grey1"]};
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme }) => theme.nColors["n-grey1"]};
  }
  &::placeholder {
    /* modern browser */
    color: ${({ theme }) => theme.nColors["n-grey1"]};
  }
`;

interface EnterOrderIdNumberProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const EnterOrderIdNumber = ({ isOpen, setIsOpen }: EnterOrderIdNumberProps) => {
  const navigate = useNavigate();
  const [orderIdNumberValue, setOrderIdNumberValue] = useState<string>("");
  const { track } = useMixPanel();

  const handlechange = (e: FormEvent<HTMLInputElement>) => {
    let newValue = e.currentTarget.value;
    setOrderIdNumberValue(newValue.toString());
  };
  const onSkip = () => {
    setIsOpen(false);
  };

  return (
    <BottomSheetModal
      title="Track my Order"
      subTitle="Enter or paste your order ID here"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Box mt={40} justifyContent="center" alignItems={"center"}>
        <PlaceOrderInput
          id="orderId"
          name="orderId"
          type="text"
          placeholder="A0"
          value={orderIdNumberValue}
          onChange={(e) => {
            handlechange(e);
          }}
        />

        <Flex
          mt="50px"
          mb="20px"
          justifyContent={"center"}
          alignItems={"center"}
          style={{ gap: "15px" }}
        >
          <PlaceOrderButton
            variant="medium"
            disabled={!orderIdNumberValue}
            onClick={() => {
              track("MENU_CUSTOMER_CLICKS_CONTINUE_TO_TRACK_ORDER");
              navigate(`/order-summary/${orderIdNumberValue}`);
              setIsOpen(false);
            }}
          >
            Continue
          </PlaceOrderButton>
        </Flex>
      </Box>
    </BottomSheetModal>
  );
};

export default EnterOrderIdNumber;
