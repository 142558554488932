import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  fetchOrderDetails,
  fetchTableOrRoomNumberDetails,
} from "../../queries";
import { ErrorResponse } from "../../types";
import { OrderSummaryBody, TableOrRoomResponse } from "../../types/MenuList";
import { useMixPanel } from "../../hooks/useMixpanel";

const useLoadOrderSummary = ({ orderId }: { orderId: string | null }) => {
  const [orderSummary, setOrderSummary] = useState<OrderSummaryBody>();
  const [tableOrRoomNumberDetails, setTableOrRoomNumberDetails] =
    useState<TableOrRoomResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { track } = useMixPanel();

  const handleGetOrderSummary = async (orderId: string) => {
    setIsLoading(true);
    let isCompleted = false;
    let response = null;

    //Fetch first, then enter loop to be checking if its cancelled or sompleted
    response = await fetchOrderDetails({ orderId });
    if (response?.result) {
      track("MENU_CUSTOMER_GET_ORDER_SUMMARY_SUCCESS");
      setOrderSummary(response?.result.data);
      setIsLoading(false);
    }
    if (response?.error) {
      track("MENU_CUSTOMER_ERROR_GETTING_ORDER_SUMMARY");
      toast.error(
        (response?.error as ErrorResponse)?.data.message ||
          "An error occured while fetching order details"
      );
      setIsLoading(false);
    }
    while (!isCompleted) {
      await timer(30000);
      if (
        response.result?.data?.status === "CANCELLED" ||
        response.result?.data?.status === "COMPLETED"
      ) {
        isCompleted = true;
        return;
      } else {
        setIsLoading(true);
        response = await fetchOrderDetails({ orderId });
        if (response?.result) {
          setOrderSummary(response?.result.data);
          setIsLoading(false);
        }
        if (response.error) {
          toast.error(
            (response.error as ErrorResponse)?.data.message ||
              "An error occured while fetching order details"
          );

          setIsLoading(false);
        }
      }
    }
  };

  const handleGetTableNumber = async (orderId: string) => {
    setIsLoading(true);
    const { result } = await fetchTableOrRoomNumberDetails({ orderId });
    setIsLoading(false);
    if (result) {
      setTableOrRoomNumberDetails(result);
    }
  };

  const timer = (delay: number) =>
    new Promise((resolve) => {
      setTimeout(resolve, delay);
    });

  const getOrderCombinedDetails = async () => {
    handleGetOrderSummary(orderId as string);
    handleGetTableNumber(orderId as string);
  };

  useEffect(() => {
    getOrderCombinedDetails();
  }, []);

  return {
    orderSummary,
    handleGetOrderSummary,
    isLoading,
    tableOrRoomNumberDetails,
    getOrderCombinedDetails,
  };
};

export default useLoadOrderSummary;
