import React from "react";

const FilterDropdownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.57223 8.64645C5.76749 8.45118 6.08407 8.45118 6.27933 8.64645L12.9258 15.2929L19.5722 8.64645C19.7675 8.45118 20.0841 8.45118 20.2793 8.64645C20.4746 8.84171 20.4746 9.15829 20.2793 9.35355L13.2793 16.3536C13.0841 16.5488 12.7675 16.5488 12.5722 16.3536L5.57223 9.35355C5.37697 9.15829 5.37697 8.84171 5.57223 8.64645Z"
        fill="#0D0D0D"
      />
    </svg>
  );
};

export default FilterDropdownIcon;
