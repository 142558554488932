import { StationOrderDateFilterType } from "../../types/stations";
import { FilterConfig, FilterConfigOption } from "../newFilter/defs";

export const statusOptions: FilterConfigOption[] = [
  { text: "All", value: "NONE" },
  { text: "New order", value: "INITIATED" },
  { text: "Processing", value: "IN_PROGRESS" },
  { text: "Completed", value: "COMPLETED" },
  { text: "Cancelled", value: "CANCELLED" },
];

const serviceTypeOptions: FilterConfigOption[] = [
  { text: "Dine-In", value: "DINE_IN" },
  { text: "Delivery", value: "DELIVERY" },
];

export const KdsFilterConfig: Array<FilterConfig> = [
  {
    name: "Service-type",
    type: "select",
    query: "orderType",
    options: serviceTypeOptions,
  },
  {
    name: "Status",
    type: "select",
    query: "status",
    options: statusOptions,
  },
];

export const KdsHeaderDateFilterConfig: Array<{
  key: string;
  value: StationOrderDateFilterType;
}> = [
  { key: "Today", value: "TODAY" },
  { key: "Current Week", value: "THIS_WEEK" },
  { key: "Current Month", value: "THIS_MONTH" },
  { key: "Entire Period", value: "ALL" },
];
