import { Box, Flex } from "rebass";
import { Dispatch, SetStateAction, useState } from "react";
import { Button, IconsEnum, NIcons, Typography } from "kudi-component-library";
import styled from "styled-components";
import { toast } from "react-toastify";
import BottomSheetIncDec from "./BottomSheetIncDec";
import { useMixPanel } from "../hooks/useMixpanel";
import { MenuListProduct } from "../types";
import { ModalClose } from "./Styles";

const BottomSheetWrapper = styled.div`
  margin-right: 20px;
  display: block;
`;
const BottomSheetBackdrop = styled.div<{ active: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  transition: all 0.2s ease-in-out;
  opacity: 0;
  pointer-events: none;

  z-index: 20;

  ${({ active }) => {
    if (active)
      return `
        opacity: 1;
        pointer-events: all;
      `;
  }}
`;
const BottomSheetInner = styled.div<{ active: boolean; noImage?: boolean }>`
  background: #fff;
  width: 100%;
  ${({ noImage }) => (noImage ? "min-height: 40%;" : "min-height: 60%;")}
  position: fixed;
  z-index: 2;
  transition: all 0.2s ease-in-out;

  //     centereing an absolute div
  left: 50%;
  transform: translateX(-50%);

  //     initially it will be pushed down out of the view.
  bottom: -110%;

  z-index: 22;

  @media (min-width: 1023px) {
    width: 500px;
  }

  ${({ active }) => {
    if (active)
      return `
      bottom: 0%;
      @media (min-width: 1023px) {
        bottom: 20%;
      }
      `;
  }}
`;

const BottomSheetMealImageWrapper = styled(Flex)`
  position: relative;
  & img.meal-image {
    max-height: 200px;
    min-height: 200px;
    width: 100%;
  }
`;

const TitleAndDescription = styled(Flex)`
  border-bottom: solid 1px ${({ theme }) => theme.nColors["n-grey1"]};
  padding-bottom: 20px;
`;
const OutOfOrderBox = styled(Flex)`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(image.png);
  position: absolute;
  width: 100%;
  height: 200px;
`;
const ActionBottom = styled(Flex)`
  position: absolute;
  width: 100%;
  left: 0;
  padding: 20px;
  bottom: 0;
`;

interface BottomSheetMealProps {
  isOpen: boolean;
  noImage?: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  activeFood?: MenuListProduct;
  addToCart: (activeFood: MenuListProduct, quantity: number) => void;
}

const BottomSheetMeal = ({
  isOpen,
  setIsOpen,
  activeFood,
  addToCart,
  noImage,
}: BottomSheetMealProps) => {
  if (!activeFood) {
    return null;
  }

  const [quantity, setQuantity] = useState<number>(1);
  const { track } = useMixPanel();

  const increaseQuantity = () => {
    if (quantity >= 10) {
      toast.error("Quantity can not be greater than 10");
      return;
    }
    setQuantity((prev) => prev + 1);
  };
  const decreaseQuantity = () => {
    if (quantity <= 1) {
      toast.error("Quantity can not be less than 1");
      return;
    }
    setQuantity((prev) => prev - 1);
  };
  return (
    <BottomSheetWrapper>
      <BottomSheetBackdrop
        onClick={() => setIsOpen(false)}
        active={isOpen}
      ></BottomSheetBackdrop>
      <BottomSheetInner active={isOpen} noImage={noImage}>
        <ModalClose onClick={() => setIsOpen(false)}>
          <NIcons
            icon={IconsEnum.OUTLINE_X}
            color="n-grey4"
            height="20"
            width="20"
          />
        </ModalClose>
        <Box>
          <BottomSheetMealImageWrapper>
            {!noImage && (
              <img
                loading="lazy"
                alt="meal image"
                src={activeFood.imageUrl}
                className="meal-image"
              />
            )}
            {activeFood.quantity === 0 && (
              <OutOfOrderBox
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <img
                  loading="lazy"
                  alt="meal image"
                  src={"/out-of-order.svg"}
                  width="40"
                  height="40"
                />
                <Typography
                  style={{
                    marginTop: "10px",
                    fontSize: "14px",
                    lineHeight: "22px",
                  }}
                  color="n-light"
                  fontWeight={500}
                >
                  Out Of Stock
                </Typography>
              </OutOfOrderBox>
            )}
          </BottomSheetMealImageWrapper>

          <Box p={"20px"}>
            <TitleAndDescription flexDirection={"column"} width="100%">
              <Flex justifyContent={"space-between"} mb="5px">
                <Typography color="n-dark" variant="n-text5" fontWeight={500}>
                  {activeFood.name}
                </Typography>
              </Flex>
              <Typography variant="n-text1" color="n-grey4" fontSize="14px">
                {activeFood.description}
              </Typography>
            </TitleAndDescription>

            <ActionBottom
              mt="100px"
              justifyContent={"space-between"}
              alignItems="center"
            >
              <BottomSheetIncDec
                decreaseQuantity={decreaseQuantity}
                quantity={quantity}
                increaseQuantity={increaseQuantity}
              />
              <Button
                variant="medium"
                disabled={activeFood.quantity === 0}
                onClick={() => {
                  addToCart(activeFood, quantity);
                  setIsOpen(false);
                  setQuantity(1);
                  track("MENU_CUSTOMER_ADDS_MEAL_TO_CART", {
                    mealDetails: activeFood.toString(),
                  });
                }}
              >
                Add item for ₦{activeFood.price * quantity}
              </Button>
            </ActionBottom>
          </Box>
        </Box>
      </BottomSheetInner>
    </BottomSheetWrapper>
  );
};

export default BottomSheetMeal;
