import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fetchMenuListProducts } from "../../queries";
import { useOrderData } from "../../state/context";
import { MenuDataBody } from "../../types";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useMixPanel } from "../../hooks/useMixpanel";
import { useAppDispatch } from "../../state/store";
import { setMenuInformation } from "../../state/menu/slice";

const useMenuListProductsModel = (businessId: string | null) => {
  const dispatch = useAppDispatch();
  const [productBody, setProductBody] = useState<MenuDataBody>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);
  const { setBusinessData, setOrderData } = useOrderData();
  const [savedBusinessData, setSavedBusinessData] =
    useLocalStorage("savedBusinessData");
  const { track } = useMixPanel();

  const handleRefetch = () => setRefetch(!refetch);

  const handleFetchMenuListProducts = async (
    businessIdToBeUsed: string | null
  ) => {
    setIsLoading(true);
    if (savedBusinessData?.alias !== businessId) {
      localStorage.removeItem("savedOrder");
      setOrderData({});
    }
    const { result, error: fetchError } = await fetchMenuListProducts({
      businessId: businessIdToBeUsed as unknown as number,
    });
    setIsLoading(false);
    if (result) {
      setSavedBusinessData(result.data);
      setBusinessData(result.data);
      setProductBody(result.data);
      dispatch(setMenuInformation(result.data as MenuDataBody));
      track("MENU_CUSTOMER_ORDERLIST_FETCHED");
    }
    if (fetchError) {
      localStorage.removeItem("savedOrder");
      track("MENU_CUSTOMER_ERROR_FETCHING_ORDERLIST");
      toast.error("An error occured while fetching menu");
    }
  };

  useEffect(() => {
    if (!businessId) return;
    const request = new AbortController();

    handleFetchMenuListProducts(businessId);
    return () => request.abort();
  }, []);

  return {
    productBody,
    isLoading,
    handleRefetch,
  };
};

export default useMenuListProductsModel;
