import fetchMenuListProducts from "./fetchMenuListProducts";
import postPlaceOrder from "./postPlaceOrder";
import fetchOrderDetails from "./fetchOrderDetails";
import fetchTableOrRoomNumberDetails from "./fetchTableOrRoomNumber";
import fetchBankTransferDetails from "./fetchBankTransferDetails";

export {
    fetchMenuListProducts,
    postPlaceOrder,
    fetchOrderDetails,
    fetchTableOrRoomNumberDetails,
    fetchBankTransferDetails
}