import { LogoutWrapper, NavbarActionsWrapper } from "./styles";
// import NotificationIcon from "../Icons/NotificationIcon";
// import AvatarIcon from "../Icons/AvatarIcon";
import { Typography } from "kudi-component-library";
import { useAuth0 } from "@auth0/auth0-react";

const NavbarActions = () => {
  const { logout } = useAuth0()
  return (
    <NavbarActionsWrapper>
      {/* <NotificationIcon /> */}
      {/* <AvatarIcon /> */}
      <LogoutWrapper>
        <Typography variant="n-text2" as="span" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
          Log out
        </Typography>
      </LogoutWrapper>
    </NavbarActionsWrapper>
  );
};

export default NavbarActions;
