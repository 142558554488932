import { Badge, Button, IconsEnum, Typography } from "kudi-component-library";
import { FilterProps } from "./defs";
import {
  FilterHeader,
  FilterWrapper,
  FilterIcon,
  FilterMainWrapper,
  FilterContainer,
} from "./styles";
import FilterItem from "./filter-item";
import useFilterModel from "./useFilter";
import useDropdown from "../../hooks/useDropdown";
import SearchInput from "../SearchInput";
import FilterModal from "./filterModal";
import { Flex } from "rebass";

const Filter = ({
  searchPlaceholder,
  onSearch,
  onClearSearch,
  filterConfig,
  onApplyFilters,
  onClearFilters,
  onChange,
  hasSearch = true,
  alignItems,
  isTourRun = {
    isDropdownOpen: false,
    sampleFilters: [],
  },
}: FilterProps) => {
  const { showDropdown, setShowDropdown } = useDropdown();
  const {
    appliedFilters: filterInternalState,
    handleApplyFilter,
    handleClearFilters,
  } = useFilterModel();

  const appliedFilters = isTourRun.isDropdownOpen
    ? isTourRun.sampleFilters
    : filterInternalState;

  return (
    <FilterContainer
      alignItems={alignItems}
      flexDirection="column"
      width="100%"
    >
      <FilterMainWrapper
        data-testid="filter_component_container"
        id="filter_component_container"
      >
        {hasSearch ? (
          <SearchInput
            placeholder={searchPlaceholder || "Search"}
            id="searchInputContainer"
            data-testid="searchInputContainer"
            onSearch={(searchTerm: string) => onSearch(searchTerm)}
            onRemove={onClearSearch}
            type="text"
          />
        ) : null}

        <FilterWrapper
          id="filter_button"
          role="button"
          data-testid="filter_button"
        >
          <FilterHeader onClick={() => setShowDropdown((state) => !state)}>
            <Typography variant="n-text2" color="dark">
              Filter
            </Typography>
            {appliedFilters.length ? (
              <Badge
                type="notification"
                backgroundColor="n-yellow"
                textColor="n-grey7"
              >
                {appliedFilters.length}
              </Badge>
            ) : (
              <FilterIcon icon={IconsEnum.OUTLINE_FILTER_1} />
            )}
          </FilterHeader>
          <FilterModal isOpen={showDropdown} setIsOpen={setShowDropdown}>
            {filterConfig.map((item) => (
              <FilterItem
                configItem={item}
                onChange={onChange}
                key={`filter_item_${item.query}`}
                handleApplyFilter={handleApplyFilter}
                appliedFilters={appliedFilters}
              />
            ))}

            <Flex
              mt="100px"
              justifyContent={"center"}
              alignItems={"center"}
              style={{ gap: "15px" }}
              flexDirection="column"
            >
              <Button
                variant="medium"
                style={{ width: "100%" }}
                disabled={appliedFilters.length === 0}
                onClick={() => {
                  setShowDropdown(false);
                  onApplyFilters(appliedFilters);
                }}
              >
                Apply filter
              </Button>
              <Button
                variant="medium"
                color="tertiary"
                style={{ width: "100%" }}
                onClick={() => {
                  setShowDropdown(false);
                  handleClearFilters();
                  onClearFilters();
                }}
              >
                Clear
              </Button>
            </Flex>
          </FilterModal>
        </FilterWrapper>
      </FilterMainWrapper>
    </FilterContainer>
  );
};

export default Filter;
