import styled from "styled-components";

export const MainPageWrapper = styled.div`
  background: ${({ theme }) => theme.nColors["n-light"]};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > div.pageSetup {
    flex: 1;
    min-height: 100vh;
    background: #fff;
    max-width: 800px;
    width: 100%;
  }
`;
