import { ApiResponse } from "../types/ApiResponse";
import { PayByCashPayload } from "../types/MenuList";
import { menuServiceInit } from "../utils";

const postPayByCash = async (payload: PayByCashPayload, accessToken: string) => {
  const url = `/webbe/v1/waiter/orders/update-payment-by-cash-status`;
  try {
    const result: ApiResponse = await menuServiceInit.post(url, payload, {
      headers: {
        "iam-authorization": `Bearer ${accessToken}`,
        coreUserId: "businessId",
      },
    });
    return await Promise.resolve({ error: null, result: result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default postPayByCash;
