import { KdsCardsOverViewWrapper } from "./styles";
import { Flex } from "rebass";
import KdsCardsColumn from "./cardColumn";
import KdsCard from "./card";
import useKdsOrderCardModel from "../models/useCardModel";
import ChangeOrderStatusModal from "../modals/changeOrderStatus";
import CancelOrderModal from "../modals/cancelOrder";
import CompleteOrderModal from "../modals/completeOrder";
import { useAppSelector } from "../../../state/store";

const KdsCardsOverview = () => {
  const { orderColumns } = useAppSelector((state) => state.kds);
  const {
    showOrderModal,
    openOrderModal,
    closeOrderModal,
    showCancelOrderModal,
    closeCancelOrderModal,
    showCompleteOrderModal,
    closeCompleteOrderModal,
    saveUpdateChanges,
  } = useKdsOrderCardModel();

  return (
    <KdsCardsOverViewWrapper>
      <Flex
        style={{
          gap: 16,
          overflow: "auto",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <KdsCardsColumn
          header="New Orders"
          numberOfOrders={orderColumns.new.count}
        >
          {orderColumns?.new?.list?.map((item) => (
            <KdsCard
              key={item.orderReference}
              editable
              handleClick={openOrderModal}
              {...item}
            />
          ))}
        </KdsCardsColumn>
        <KdsCardsColumn
          header="In Progress"
          numberOfOrders={orderColumns.inProgress.count}
        >
          {orderColumns?.inProgress?.list?.map((item) => (
            <KdsCard
              key={item.orderReference}
              editable
              variant="warning"
              handleClick={openOrderModal}
              {...item}
            />
          ))}
        </KdsCardsColumn>
        <KdsCardsColumn
          header="Completed"
          numberOfOrders={orderColumns.completed.count}
        >
          {orderColumns?.completed?.list?.map((item) => (
            <KdsCard
              key={item.orderReference}
              variant="success"
              handleClick={openOrderModal}
              {...item}
            />
          ))}
        </KdsCardsColumn>
        <KdsCardsColumn
          header="Cancelled"
          numberOfOrders={orderColumns.cancelled.count}
        >
          {orderColumns?.cancelled?.list?.map((item) => (
            <KdsCard
              key={item.orderReference}
              variant="error"
              handleClick={openOrderModal}
              {...item}
            />
          ))}
        </KdsCardsColumn>
      </Flex>
      <ChangeOrderStatusModal
        showModal={showOrderModal}
        closeModal={closeOrderModal}
        openConfirmationModal={saveUpdateChanges}
      />
      <CancelOrderModal
        showModal={showCancelOrderModal}
        closeModal={closeCancelOrderModal}
      />
      <CompleteOrderModal
        showModal={showCompleteOrderModal}
        closeModal={closeCompleteOrderModal}
      />
    </KdsCardsOverViewWrapper>
  );
};

export default KdsCardsOverview;
