import { PropsWithChildren, useState } from "react";
import { MenuDataBody } from "../types";
import { MenuCreatedResponse } from "../types/MenuList";
import { OrderDataContextProvider } from "./context";
import { useLocalStorage } from "../hooks/useLocalStorage";

const OrderDataProvider = ({ children }: PropsWithChildren) => {
  const [savedOrder] = useLocalStorage("savedOrder");
  const [savedBusinessData] = useLocalStorage("savedBusinessData");
  const [orderData, setOrderData] = useState(() => savedOrder || {});
  const [businessData, setBusinessData] = useState<MenuDataBody | undefined>(
    () => (savedBusinessData ? savedBusinessData : undefined)
  );
  const [orderReference, setOrderReference] = useState<MenuCreatedResponse>();
  const [paidStatus, setPaidStatus] = useState<boolean>();

  const OrderDataContextProviderValue = {
    orderData,
    setOrderData,
    setBusinessData,
    businessData,
    orderReference,
    setOrderReference,
    paidStatus,
    setPaidStatus,
  };

  return (
    <OrderDataContextProvider value={OrderDataContextProviderValue}>
      {children}
    </OrderDataContextProvider>
  );
};

export default OrderDataProvider;
