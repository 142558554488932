import { Box, Flex } from "rebass";
import Modal from "./components/kds-modal";
import ExclaimationCircleIcon from "../../Icons/ExclaimationCircleIcon";
import { Button, Typography } from "kudi-component-library";
import useUpdateOrderStatus from "../models/useUpdateOrderStatus";
import { useAppSelector } from "../../../state/store";
import { StationType } from "../../../types/stations";
import useFetchMenuStationOrders from "../models/useFetchMenuStationOrders";
import useFilterKdsOrders from "../models/useFilterKdsOrders";

export default function CompleteOrderModal({
  showModal,
  closeModal,
}: {
  showModal: boolean;
  closeModal: () => void;
}) {
  const { selectedStation } = useAppSelector((state) => state.kds);

  const { params } = useFilterKdsOrders()


  const { handleFetchStationOrders } = useFetchMenuStationOrders();

  const { changeMenuOrderStatus } = useUpdateOrderStatus({
    onFailure: closeModal,
    onSuccess: () => {
      handleFetchStationOrders(selectedStation as StationType, params);
      closeModal();
    }
  }); return (
    <Modal showModal={showModal} onClose={closeModal}>
      <Flex flexDirection="column" width="100%">
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <ExclaimationCircleIcon />
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            flexDirection: "column",
            textAlign: "center",
            marginBottom: "32px",
          }}
        >
          <Typography variant="n-text5" fontWeight="heavy">
            Complete this Order?
          </Typography>
          <Typography variant="n-text2" color="grey4">
            This action cannot be undone. This will permanently confirm <br />
            that this order has been completed. Are you sure?
          </Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            gap: "16px",
            justifyContent: "center",
            marginBottom: "28px",
          }}
        >
          <Button variant="medium" color="primary" onClick={changeMenuOrderStatus}>
            Yes, complete order
          </Button>
          <Button variant="medium" color="tertiary" onClick={closeModal}>
            No, thanks
          </Button>
        </Box>
      </Flex>
    </Modal>
  );
}
