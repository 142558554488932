import { Auth0Provider } from "@auth0/auth0-react";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import config from "./config";

export const Auth0ProviderWithNavigate = ({
  children,
}: {
  children: ReactNode;
}) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: any) => {
    localStorage.setItem("fromTerminal", "true");
    navigate(appState?.returnTo || config.auth0Redirect);
  };

  const providerConfig = {
    domain: config.auth0Domain,
    clientId: config.auth0ClientId,
    onRedirectCallback,
    authorizationParams: {
      redirect_uri: window.location.origin,
      ...(config.auth0Audience ? { audience: config.auth0Audience } : null),
    },
  };

  if (!(providerConfig.domain && providerConfig.clientId)) {
    return null;
  }

  return <Auth0Provider {...providerConfig}>{children}</Auth0Provider>;
};
