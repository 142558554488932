import { Box, Flex, Link } from "rebass";
import {
  Badge,
  Button,
  Checkbox,
  IconsEnum,
  NIcons,
  Radio,
  Typography,
} from "kudi-component-library";
import styled from "styled-components";
import { MainPageWrapper } from "../Layout";
import PageLoad from "../PageLoad";
import { useOrderData } from "../../state/context";
import {
  capitalizeFirstLetter,
  formatCurrency,
  formatDateValue,
} from "../../utils";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMixPanel } from "../../hooks/useMixpanel";
import {
  EllipsedText,
  OrderStatusBadge,
  PriceSummarySection,
  UnderlinedButton,
} from "../Styles";
import useWaiterOrderDetails from "./models/useWaiterOrderDetails";
import { OrderStatus } from "../../types/MenuList";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth0 } from "@auth0/auth0-react";
import ConfirmPayByCashModal from "./ConfirmPayByCash";

const HeadWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.nColors["n-grey1"]};
  padding-bottom: 25px;
  padding-top: 15px;
`;
const TotalWrapper = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.nColors["n-grey1"]};
  border-bottom: 1px solid ${({ theme }) => theme.nColors["n-grey1"]};
  padding-bottom: 25px;
  padding-top: 15px;
`;
const Navigation = styled(Flex)`
  padding: 20px 10px;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const PageBody = styled(Box)`
  background: ${({ theme }) => theme.nColors["n-light"]};
  min-height: 100vh;
`;

const EditButton = styled(Flex)`
  background: ${({ theme }) => theme.nColors["n-light"]};
  cursor: pointer;
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.nColors["n-grey1"]};
  background: #fff;
  padding: 8px 16px;
  gap: 4px;
  align-items: center;
  :hover {
    background: ${({ theme }) => theme.nColors["n-grey0"]};
  }
`;

const WaiterOrderDetailsPage = () => {
  const { orderId } = useParams();
  const { track } = useMixPanel();
  const [paymentMethod, setPaymentMethod] = useState<string>("cash");
  const { businessData, setOrderReference } = useOrderData();
  const navigate = useNavigate();
  const [confirmCash, setConfirmCash] = useState(false);

  const { orderSummary, isLoading, payByCash, customerDetails } =
    useWaiterOrderDetails({
      orderId: orderId as string,
    });

  useEffect(() => {
    track("MENU_CUSTOMER_CUSTOMER_VIEW_ORDER_SUMMARY_PAGE_DETAILS", {
      orderId: orderId as string,
    });
  }, []);

  const returnOrderNames = (status: OrderStatus) => {
    let orderStatusVal: string = status;
    if (status === "INITIATED") {
      orderStatusVal = "New order";
    }
    if (status === "IN_PROGRESS") {
      orderStatusVal = "Processing";
    }
    return orderStatusVal;
  };

  return (
    <>
      {isLoading ? (
        <PageLoad />
      ) : (
        <PageBody>
          {orderSummary ? (
            <MainPageWrapper>
              <Box className="pageSetup">
                <Navigation>
                  <span
                    onClick={() =>
                      navigate(`/${businessData?.alias}/waiter/order-list/`)
                    }
                  >
                    <NIcons icon={IconsEnum.OUTLINE_CHEVRON_LEFT} />
                  </span>
                  <Typography variant="n-text5">Order</Typography>
                  {orderSummary.paymentStatus === "PAYMENT_PENDING" && (
                    <EditButton
                      onClick={() =>
                        navigate(
                          `/${businessData?.alias}/waiter/edit-order/${orderSummary.orderReference}`
                        )
                      }
                    >
                      <NIcons
                        height="16"
                        width="16"
                        icon={IconsEnum.OUTLINE_EDIT_V}
                      />{" "}
                      <Typography variant="n-text2">Edit</Typography>
                    </EditButton>
                  )}
                </Navigation>
                <HeadWrapper mb="20px" p="20px">
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    mt={"15px"}
                    flexWrap={"wrap"}
                  >
                    <Box>
                      <Typography
                        color="n-grey3"
                        fontWeight={500}
                        variant="n-text1"
                      >
                        Table No
                      </Typography>
                      <Typography fontWeight={400} variant="n-text2">
                        {orderSummary.destination}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        color="n-grey3"
                        fontWeight={500}
                        variant="n-text1"
                      >
                        Order#/Date
                      </Typography>
                      <Flex
                        style={{
                          gap: "5px",
                        }}
                        alignItems="center"
                      >
                        <Typography variant="n-text2">
                          {orderSummary.orderReference}
                        </Typography>
                        <svg
                          width="3"
                          height="4"
                          viewBox="0 0 3 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="1.5" cy="2" r="1.5" fill="#121212" />
                        </svg>
                        <Typography variant="n-text2">
                          {formatDateValue(
                            orderSummary?.orderDate as unknown as Date
                          )}
                        </Typography>
                      </Flex>
                    </Box>
                  </Flex>

                  <Flex style={{ gap: "10px" }} pt="20px" alignItems="center">
                    <OrderStatusBadge
                      style={{ padding: "0.5rem 1.5rem" }}
                      status={orderSummary.status}
                    >
                      {capitalizeFirstLetter(
                        returnOrderNames(orderSummary.status || "")
                      )}
                    </OrderStatusBadge>
                    {orderSummary.paymentStatus === "PAYMENT_PENDING" ? (
                      <Typography variant="n-text1" color="n-red">
                        Not Paid
                      </Typography>
                    ) : (
                      <Typography variant="n-text1" color="n-green">
                        Paid by{" "}
                        {capitalizeFirstLetter(
                          orderSummary.paymentMethod || ""
                        )}
                      </Typography>
                    )}
                  </Flex>
                </HeadWrapper>

                <Box>
                  <Box p="20px">
                    {orderSummary?.items.map((item: any) => (
                      <Flex
                        justifyContent={"space-between"}
                        my="10px"
                        key={item.id}
                      >
                        <Box>
                          <Typography color="n-grey3" variant="n-text2">
                            {item.name}
                          </Typography>
                          <Typography color="n-grey3" variant="n-text1">
                            ({item.quantity}pcs)
                          </Typography>
                        </Box>
                        <Typography color="n-dark" variant="n-text2">
                          {formatCurrency(
                            item.price as unknown as string,
                            false
                          )}
                        </Typography>
                      </Flex>
                    ))}
                  </Box>
                  <Flex justifyContent={"space-between"} my="10px" px="20px">
                    <Flex>
                      <Typography
                        color="n-dark"
                        fontWeight={500}
                        variant="n-text3"
                      >
                        Tax
                      </Typography>
                    </Flex>
                    <Typography
                      color="n-dark"
                      fontWeight={500}
                      style={{
                        fontSize: "16px",
                        lineHeight: "22px",
                      }}
                    >
                      {formatCurrency(
                        (orderSummary?.taxOnOrder as unknown as string) || "",
                        false
                      )}
                    </Typography>
                  </Flex>
                  <TotalWrapper
                    justifyContent={"space-between"}
                    my="10px"
                    px="20px"
                  >
                    <Flex>
                      <Typography
                        color="n-dark"
                        fontWeight={500}
                        variant="n-text3"
                      >
                        Total
                      </Typography>

                      <Typography color="n-grey3" variant="n-text2">
                        ({orderSummary?.items.length})
                      </Typography>
                    </Flex>
                    <Typography
                      color="n-dark"
                      fontWeight={500}
                      style={{
                        fontSize: "16px",
                        lineHeight: "22px",
                      }}
                    >
                      {formatCurrency(
                        orderSummary?.totalAmount as unknown as string,
                        false
                      )}
                    </Typography>
                  </TotalWrapper>
                </Box>
                {orderSummary.paymentStatus === "PAYMENT_PENDING" ? (
                  <Box mt={"60px"} p="20px" mb="500px">
                    <Box mb={"10px"}>
                      <Typography color="n-dark" fontWeight={700}>
                        Payment Method
                      </Typography>
                    </Box>
                    <Flex flexDirection={"column"} mt="20px">
                      <Box mb={"20px"}>
                        <Radio
                          name="payment"
                          value={"cash"}
                          defaultChecked={paymentMethod === "cash"}
                          onClick={(e) => {
                            const target = e.target as HTMLInputElement;
                            setPaymentMethod(target.value);
                          }}
                        >
                          Cash
                        </Radio>
                      </Box>
                      <Box mb={"20px"}>
                        <Radio
                          name="payment"
                          value={"transfer"}
                          defaultChecked={paymentMethod === "transfer"}
                          onClick={(e) => {
                            const target = e.target as HTMLInputElement;
                            setPaymentMethod(target.value);
                          }}
                        >
                          Bank Transfer
                        </Radio>
                      </Box>
                      <Box mb={"20px"}>
                        <Radio
                          name="payment"
                          value="terminal"
                          defaultChecked={paymentMethod === "terminal"}
                          onClick={(e) => {
                            const target = e.target as HTMLInputElement;
                            setPaymentMethod(target.value);
                          }}
                        >
                          Pay by Card
                        </Radio>
                      </Box>
                    </Flex>
                  </Box>
                ) : null}
              </Box>
              <PriceSummarySection>
                <Flex
                  justifyContent="center"
                  flexDirection="column"
                  style={{ gap: "20px" }}
                  alignItems="center"
                >
                  {orderSummary.paymentStatus === "PAYMENT_PENDING" ? (
                    <Button
                      variant="medium"
                      loading={isLoading}
                      style={{ width: "100%" }}
                      onClick={async () => {
                        setOrderReference && setOrderReference(orderSummary);
                        track(
                          "MENU_CUSTOMER_CLICKS_CONFIRM_PAYMENT_FROM_WAITER_ORDER_DETAILS",
                          {
                            paymentMethod,
                          }
                        );
                        if (paymentMethod === "transfer") {
                          navigate("/bank-transfer");
                          return;
                        }
                        if (paymentMethod === "terminal") {
                          window.location.href = `intent://com.nomba.pro#Intent?scheme=cardPaymentScheme;action=card_payment_action;S.amount=${orderSummary.totalAmount};S.orderReference=${orderSummary.orderReference};end`;
                          return;
                        }

                        setConfirmCash(true);
                      }}
                    >
                      Confirm Order (
                      {formatCurrency(orderSummary.totalAmount, false)})
                    </Button>
                  ) : null}
                  <Box
                    onClick={() => {
                      const itemsToSend = orderSummary.items?.map((item) => ({
                        name: item.name,
                        quantity: item.quantity,
                        pricePerItem: item.price,
                        price: item.price * item.quantity,
                      }));
                      window.location.href = `intent://com.nomba.pro#Intent?scheme=cardPaymentScheme;action=order_printing;S.data={${JSON.stringify(
                        itemsToSend
                      )}};S.paymentStatus={${
                        orderSummary.paymentStatus
                      }};S.totalAmount={${
                        orderSummary.totalAmount
                      }};S.orderFor={${
                        customerDetails?.data?.fullName || ""
                      }};S.table={${
                        orderSummary.destination
                      }};s.date={${formatDateValue(
                        orderSummary?.orderDate as unknown as Date
                      )}};s.orderStatus={${orderSummary.status}};end`;
                    }}
                  >
                    <UnderlinedButton>Print out this Order</UnderlinedButton>
                  </Box>
                </Flex>
              </PriceSummarySection>
            </MainPageWrapper>
          ) : (
            <Flex
              mt="150px"
              flexDirection={"column"}
              justifyContent="center"
              alignItems={"center"}
              minHeight="80vh"
            >
              <Typography variant="n-text4">
                No result found for this order
              </Typography>
            </Flex>
          )}
        </PageBody>
      )}
      <ConfirmPayByCashModal
        isOpen={confirmCash}
        setIsOpen={setConfirmCash}
        onProceed={() => {
          setConfirmCash(false);
          payByCash();
        }}
      />
    </>
  );
};

export default WaiterOrderDetailsPage;
