import { Box } from 'rebass';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Button, PhoneNumberInput, TextInput } from 'kudi-component-library';
import styled from 'styled-components';
import BottomSheetModal from './BottomSheetModal';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useOrderData } from '../state/context';
import { useNavigate } from 'react-router-dom';
import { useMixPanel } from '../hooks/useMixpanel';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { Separator } from './Styles';
import { phoneNumberConvert } from '../utils/functions';

const PlaceOrderButton = styled(Button)`
  width: 100%;
`;

const PlaceOrderInputWrapper = styled(Box)`
  & > div {
    width: 100%;
  }

  & input {
    width: '100%';
    color: ${({ theme }) => theme.nColors['n-dark']};
    background: transparent;
  }
`;

interface InitialFormValues {
  phoneInput: string;
  nameInput: string;
}

interface BottomSheetCustomerDetailsModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const InitialFormValues: InitialFormValues = {
  phoneInput: '',
  nameInput: '',
};

const BottomSheetCustomerDetails = ({ isOpen, setIsOpen }: BottomSheetCustomerDetailsModalProps) => {
  const navigate = useNavigate();
  const { setOrderData, orderData, businessData } = useOrderData();
  const { track } = useMixPanel();
  const [savedOrder, setSavedOrder] = useLocalStorage('savedOrder');
  const [savedCustomerName, setCustomerName] = useLocalStorage('savedCustomerName');
  const [savedCustomePhone, setCustomerPhone] = useLocalStorage('savedCustomePhone');

  useEffect(() => {
    if (savedCustomerName) {
      InitialFormValues.nameInput = savedCustomerName;
    }
    if (savedCustomePhone) {
      InitialFormValues.phoneInput = savedCustomePhone;
    }
    if (!businessData?.menuStoreResponse?.showCustomerPhoneNumber) {
      InitialFormValues.phoneInput =
        phoneNumberConvert(businessData?.menuStoreResponse?.phoneNumber || '', '+234') || '';
    }
  }, []);

  const saveUserData = (values: InitialFormValues) => {
    setOrderData({
      ...orderData,
      customerName: values.nameInput,
      customerPhone: values.phoneInput,
    });
    navigate('/order-meal-details');
  };

  const Schema = businessData?.menuStoreResponse?.showCustomerPhoneNumber
    ? Yup.object().shape({
        nameInput: Yup.string()
          .matches(/[A-Za-z]+$/, 'enter a valid fullname')
          .required('Enter a name')
          .trim(),
        phoneInput: Yup.string()
          .min(13, 'Enter a valid phone number. e.g. 2349012345678')
          .max(13, 'Enter a valid phone number. e.g. 2349012345678')
          .matches(/^234/, 'Invalid phone number. e.g. 2349012345678')
          .required('Phone number is required')
          .trim(),
      })
    : Yup.object().shape({
        nameInput: Yup.string()
          .matches(/[A-Za-z]+$/, 'enter a valid fullname')
          .required('Enter a name')
          .trim(),
      });

  return (
    <BottomSheetModal
      title="Your details"
      subTitle="This information is used to send your order confirmation, and won’t
      be used for marketing"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Box>
        <Formik
          initialValues={InitialFormValues}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            saveUserData(values);
          }}
          validationSchema={Schema}
        >
          {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
            <>
              <PlaceOrderInputWrapper>
                <TextInput
                  name="nameInput"
                  label="Enter Name"
                  value={values.nameInput}
                  onChange={handleChange}
                  error={touched.nameInput && errors.nameInput ? errors.nameInput : undefined}
                  disabled={isSubmitting}
                />
              </PlaceOrderInputWrapper>
              {businessData?.menuStoreResponse?.showCustomerPhoneNumber ? (
                <PlaceOrderInputWrapper mt={'20px'}>
                  <PhoneNumberInput
                    name="phoneInput"
                    label="Enter your phone number"
                    value={values.phoneInput}
                    onChange={handleChange}
                    error={touched.phoneInput && errors.phoneInput ? errors.phoneInput : undefined}
                    onlyCountries={['ng']}
                    disabled={isSubmitting}
                  />
                </PlaceOrderInputWrapper>
              ) : (
                <Separator height="6rem" />
              )}
              <Box mt="20px">
                <PlaceOrderButton
                  variant="medium"
                  onClick={() => {
                    track('MENU_CUSTOMER_CLICKS_PROCEED_AFTER_FILLING_DETAILS');
                    setCustomerName(values.nameInput);
                    setCustomerPhone(values.phoneInput);
                    setSavedOrder({
                      ...orderData,
                      customerName: values.nameInput,
                      customerPhone: values.phoneInput.replace('0', '234'),
                    });
                    handleSubmit();
                  }}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  Proceed
                </PlaceOrderButton>
              </Box>
              {console.log({ errors })}
            </>
          )}
        </Formik>
      </Box>
    </BottomSheetModal>
  );
};

export default BottomSheetCustomerDetails;
