import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { NTheme as theme } from "kudi-component-library";
import OrderDataProvider from "./state/orderDataProvider";
import { ToastContainer } from "react-toastify";
import ErrorBoundary from "./components/ErrorBoundary";
import PageRoutes from "./routes/routes";
import { Auth0ProviderWithNavigate } from "./utils/auth0Provider";
import { Provider } from "react-redux";
import { store } from "./state/store";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Auth0ProviderWithNavigate>
          <ThemeProvider theme={theme}>
            <OrderDataProvider>
              <ToastContainer
                hideProgressBar
                autoClose={3000}
                closeOnClick={false}
                closeButton={false}
              />
              <ErrorBoundary>
                <PageRoutes />
              </ErrorBoundary>
            </OrderDataProvider>
          </ThemeProvider>
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
