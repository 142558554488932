import { MenuAccountDetailsResponse } from "../types";
import { menuServiceInit } from "../utils";

const fetchBankTransferDetails = async ({ menuId }: { menuId: number }) => {
  const url = `/webbe/v1/menus/${menuId}/kta-accounts`;
  try {
    const result: MenuAccountDetailsResponse = await menuServiceInit.get(url);
    return await Promise.resolve({ error: null, result: result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default fetchBankTransferDetails;
