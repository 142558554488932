import { Box, Flex } from "rebass";
import { Button, Typography } from "kudi-component-library";
import { MainPageWrapper } from "../Layout";
import config from "../../utils/config";
import { useEffect } from "react";
import { useMixPanel } from "../../hooks/useMixpanel";
import { useLocation, useSearchParams } from "react-router-dom";
import PageLoad from "../PageLoad";

export default function HomePageSetup() {
  const { track } = useMixPanel();

  const search = useLocation().search;
  const paramCode = new URLSearchParams(search).get("code");

  useEffect(() => {
    track("MENU_CUSTOMER_VIEW_HOME_PAGE");
  }, []);

  return (
    <MainPageWrapper>
      {paramCode ? (
        <PageLoad />
      ) : (
        <div className="pageSetup">
          <Flex
            justifyContent={"center"}
            mt="5"
            mx="20px"
            flexDirection={"column"}
            alignItems="center"
            height={"70vh"}
          >
            <Box mt={"20px"}>
              <Typography variant="n-text4" style={{ textAlign: "center" }}>
                Are you a customer? Request for Nomba Menu QR code from your
                Establishment.
              </Typography>
            </Box>
            <Box my={"10px"}>
              <Typography variant="n-text4" style={{ textAlign: "center" }}>
                Are you a merchant? Visit our Dashboard to create a menu.
              </Typography>
            </Box>
            <Button
              variant="medium"
              width="100%"
              onClick={() => {
                window.location.href = `${config.dashboardBaseUrl}`;
              }}
            >
              Visit Dashboard
            </Button>
          </Flex>
        </div>
      )}
    </MainPageWrapper>
  );
}
