import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { StationOrderDateFilterType, StationType } from "../../types/stations";
import { OrderStatus, OrderSummaryBody, OrderType } from "../../types/MenuList";

type filterIntialState = {
  date: StationOrderDateFilterType;
  orderType: OrderType;
  status: OrderStatus | "NONE";
  itemSummary: string;
};
interface InitialState {
  isLoading: boolean;
  stations: StationType[];
  selectedStation: StationType | null;
  orders: OrderSummaryBody[];
  selectedOrder: OrderSummaryBody | null;
  proposedOrderStatus: OrderStatus | null;
  filters: filterIntialState;
  orderColumns: {
    [x in "new" | "inProgress" | "completed" | "cancelled"]: {
      list: OrderSummaryBody[];
      count: number;
    };
  };
}

const initialState: InitialState = {
  isLoading: false,
  stations: [],
  orders: [],
  orderColumns: {
    new: {
      list: [],
      count: 0,
    },
    inProgress: {
      list: [],
      count: 0,
    },
    cancelled: {
      list: [],
      count: 0,
    },
    completed: {
      list: [],
      count: 0,
    },
  },
  selectedOrder: null,
  selectedStation: null,
  proposedOrderStatus: null,
  filters: {
    date: "TODAY",
    orderType: "DEFAULT",
    status: "NONE",
    itemSummary: "",
  },
};

export const kdsSlice = createSlice({
  name: "kds",
  initialState,
  reducers: {
    setStations: (state, action: PayloadAction<StationType[]>) => {
      state.stations = action.payload;
    },
    setSelectedStation: (state, action: PayloadAction<StationType>) => {
      state.selectedStation = action.payload;
    },
    setOrders: (state, action: PayloadAction<OrderSummaryBody[]>) => {
      state.orders = action.payload;
      state.orderColumns = {
        new: {
          list: action?.payload?.filter(
            (order: OrderSummaryBody) => order.status === "INITIATED"
          ),
          count: action?.payload?.filter(
            (order) => order.status === "INITIATED"
          ).length,
        },
        inProgress: {
          list: action?.payload?.filter(
            (order) => order.status === "IN_PROGRESS"
          ),
          count: action?.payload?.filter(
            (order) => order.status === "IN_PROGRESS"
          ).length,
        },
        cancelled: {
          list: action?.payload?.filter(
            (order) => order.status === "CANCELLED"
          ),
          count: action?.payload?.filter(
            (order) => order.status === "CANCELLED"
          ).length,
        },
        completed: {
          list: action?.payload?.filter(
            (order) => order.status === "COMPLETED"
          ),
          count: action?.payload?.filter(
            (order) => order.status === "COMPLETED"
          ).length,
        },
      };
    },
    setSelectedOrder: (
      state,
      action: PayloadAction<OrderSummaryBody | null>
    ) => {
      state.selectedOrder = action.payload;
      if (action.payload) {
        state.proposedOrderStatus = action.payload.status;
      }
    },
    setProposedOrderStatus: (
      state,
      action: PayloadAction<OrderStatus | null>
    ) => {
      state.proposedOrderStatus = action.payload;
    },

    // filters
    updateDateFilter: (
      state,
      action: PayloadAction<StationOrderDateFilterType>
    ) => {
      state.filters.date = action.payload;
    },

    updateOrderTypeFilter: (state, action: PayloadAction<OrderType>) => {
      state.filters.orderType = action.payload;
    },

    updateStatusFilter: (
      state,
      action: PayloadAction<OrderStatus | "NONE">
    ) => {
      state.filters.status = action.payload;
    },

    updateSearchFilter: (state, action: PayloadAction<string>) => {
      state.filters.itemSummary = action.payload;
    },
    clearOrderTypeAndStatus: (state) => {
      state.filters = {
        ...state.filters,
        orderType: "DEFAULT",
        status: "NONE",
        itemSummary: "",
      };
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setStations,
  setOrders,
  setSelectedOrder,
  setSelectedStation,
  setProposedOrderStatus,
  updateDateFilter,
  updateOrderTypeFilter,
  updateStatusFilter,
  clearOrderTypeAndStatus,
  updateSearchFilter,
} = kdsSlice.actions;

export default kdsSlice.reducer;
