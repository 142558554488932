import { Box, Flex } from "rebass";
import {
  BackButton,
  Button,
  Radio,
  Typography,
} from "kudi-component-library";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { MainPageWrapper } from "../Layout";
import usePlaceOrder from "./usePlaceOrder";
import { useOrderData } from "../../state/context";
import { useNavigate } from "react-router-dom";
import PageLoad from "../PageLoad";
import { formatCurrency } from "../../utils";
import { useMixPanel } from "../../hooks/useMixpanel";
import { EllipsedText, PriceSummarySection } from "../Styles";
import { useLocalStorage } from "../../hooks/useLocalStorage";

const DetailSection = styled(Box)`
  margin-top: 20px !important;
  padding-bottom: 30px;
  border-bottom: solid 1px ${({ theme }) => theme.nColors["n-grey1"]};
`;

const SummaryButton = styled(Button)`
  width: 100%;
`;

const OrderMealDetailsPageSetup = () => {
  const navigate = useNavigate();
  const { orderData, businessData, setOrderData } = useOrderData();
  const { track } = useMixPanel();

  const [savedBusinessData] = useLocalStorage("savedBusinessData");
  const [fromTerminal] = useLocalStorage("fromTerminal");

  useEffect(() => {
    const navigatePage = () => {
      if (!orderData) {
        if (savedBusinessData) {
          navigate(
            `/${savedBusinessData?.alias}/${fromTerminal ? "waiter" : ""}`
          );
          return;
        }
        navigate(`/`);
        return;
      }
    };
    navigatePage();
  }, []);

  const { handlePlaceOrder, isLoading } = usePlaceOrder();

  const [paymentMethod, setPaymentMethod] = useState(() => {
    if (
      !savedBusinessData?.menuStoreResponse?.inHouseService &&
      !savedBusinessData?.menuStoreResponse?.deliveryService
    ) {
      return "qr";
    }
    if (!!orderData?.deliveryArea) {
      return "transfer";
    }
    return "cash";
  });

  const getSelectedMealTotalPrice = () => {
    let totalPrice = 0;
    let delivery = 0;
    orderData?.selectedMeals?.forEach((item) => {
      totalPrice = totalPrice + item.meal.price * item.quantity;
    });

    if (orderData?.deliveryArea) {
      delivery = Number(orderData?.deliveryArea?.price);
    }

    // const vat = (7.5 / 100) * totalPrice;

    const totalAmount = delivery + totalPrice;

    return {
      totalPrice,
      // vat,
      delivery,
      totalAmount,
    };
  };

  return (
    <MainPageWrapper>
      <div className="pageSetup">
        <Box py="20px">
          <Box px="20px">
            <Flex>
              <span
                style={{
                  textDecoration: "none",
                }}
                onClick={() => {
                  track("MENU_CUSTOMER_GOES_BACK_FROM_CHECKOUT_DETAILS");
                  navigate(-1);
                }}
              >
                <BackButton
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "none",
                  }}
                >
                  Back
                </BackButton>
              </span>
            </Flex>
            <Flex
              justifyContent={"space-between"}
              alignItems="center"
              mb="20px"
            >
              <Typography variant="n-text5">Checkout</Typography>
            </Flex>
          </Box>

          <DetailSection px="20px">
            <Box mb={"10px"}>
              <Typography color="n-grey5" fontWeight={700}>
                Order Summary
              </Typography>
            </Box>
            <Flex my={"5px"} justifyContent={"space-between"}>
              <Typography color="n-grey4">Order Total:</Typography>
              <Typography>
                {formatCurrency(getSelectedMealTotalPrice().totalAmount, false)}
              </Typography>
            </Flex>
          </DetailSection>
          <DetailSection px="20px">
            <Box mb={"10px"}>
              <Typography color="n-grey5" fontWeight={700}>
                Contact and Delivery Info
              </Typography>
            </Box>
            <Flex my={"10px"} justifyContent={"space-between"}>
              <EllipsedText color="n-grey4">Client’s Full Name:</EllipsedText>
              <Typography>{orderData?.customerName}</Typography>
            </Flex>
            {businessData?.menuStoreResponse?.showCustomerPhoneNumber && (
              <Flex my={"10px"} justifyContent={"space-between"}>
                <Typography color="n-grey4">Phone Number:</Typography>
                <Typography>{orderData?.customerPhone}</Typography>
              </Flex>
            )}
            <Flex my={"10px"} justifyContent={"space-between"}>
              <Typography color="n-grey4">Destination:</Typography>
              <EllipsedText>{orderData?.tableNumber}</EllipsedText>
            </Flex>
            {orderData?.deliveryArea && (
              <Flex my={"10px"} justifyContent={"space-between"}>
                <Typography color="n-grey4">Delivery Area:</Typography>
                <EllipsedText>
                  {orderData?.deliveryArea.area.replace("+", ", ")}
                </EllipsedText>
              </Flex>
            )}
          </DetailSection>
          <Box mt={"30px"} mb="500px" px="20px">
            <Box mb={"10px"}>
              <Typography color="n-dark" fontWeight={700}>
                Payment Method
              </Typography>
            </Box>
            <Flex flexDirection={"column"} mt="20px">
              {!savedBusinessData?.menuStoreResponse?.inHouseService &&
              !savedBusinessData?.menuStoreResponse?.deliveryService ? (
                <Box mb={"20px"}>
                  <Radio
                    name="payment"
                    value={"qr"}
                    defaultChecked={paymentMethod === "qr"}
                    onClick={(e) => {
                      const target = e.target as HTMLInputElement;
                      setPaymentMethod(target.value);
                    }}
                  >
                    Pay by Nomba QR
                  </Radio>
                </Box>
              ) : (
                <>
                  <Box mb={"20px"}>
                    <Radio
                      name="payment"
                      value={"cash"}
                      defaultChecked={paymentMethod === "cash"}
                      onClick={(e) => {
                        const target = e.target as HTMLInputElement;
                        setPaymentMethod(target.value);
                      }}
                      disabled={
                        !!orderData?.deliveryArea && !Boolean(fromTerminal)
                      }
                    >
                      Cash or Pay Later
                    </Radio>
                  </Box>
                  <Box mb={"20px"}>
                    <Radio
                      name="payment"
                      value={"transfer"}
                      defaultChecked={paymentMethod === "transfer"}
                      onClick={(e) => {
                        const target = e.target as HTMLInputElement;
                        setPaymentMethod(target.value);
                      }}
                    >
                      Bank Transfer
                    </Radio>
                  </Box>
                  {Boolean(fromTerminal) ? (
                    <Box mb={"20px"}>
                      <Radio
                        name="payment"
                        value="fromTerminal"
                        onClick={(e) => {
                          const target = e.target as HTMLInputElement;
                          setPaymentMethod(target.value);
                        }}
                      >
                        Pay by Card
                      </Radio>
                    </Box>
                  ) : null}
                </>
              )}
            </Flex>
          </Box>

          <PriceSummarySection>
            <SummaryButton
              variant="medium"
              loading={isLoading}
              onClick={async () => {
                track("MENU_CUSTOMER_CLICKS_CONFIRM_ORDER_FROM_CHECKOUT", {
                  paymentMethod,
                });
                if (paymentMethod === "transfer") {
                  await handlePlaceOrder("/bank-transfer");
                  return;
                }
                if (paymentMethod === "fromTerminal") {
                  await handlePlaceOrder("fromTerminal");
                  return;
                }
                await handlePlaceOrder("/success");
              }}
            >
              Confirm Order (
              {formatCurrency(getSelectedMealTotalPrice().totalAmount, false)})
            </SummaryButton>
          </PriceSummarySection>
        </Box>
      </div>
      {isLoading && <PageLoad />}
    </MainPageWrapper>
  );
};

export default OrderMealDetailsPageSetup;
