import React, { useState } from "react";
import useDropdown from "../../../hooks/useDropdown";
import { KdsHeaderDateFilterConfig } from "../config";
import { StationOrderDateFilterType } from "../../../types/stations";
import { useAppDispatch, useAppSelector } from "../../../state/store";
import { updateDateFilter } from "../../../state/kds/slice";

interface KeyDatePair {
  key: string;
  value: StationOrderDateFilterType;
}

const useKdsDateFilter = () => {
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.kds);
  const getCurrentDateFilter = () => {
    const dateFilter = KdsHeaderDateFilterConfig.find(
      (filter) => filter.value === filters.date
    );
    return dateFilter || KdsHeaderDateFilterConfig[0];
  };
  const [selectedDate, setSelectedDate] = useState<KeyDatePair>(
    getCurrentDateFilter()
  );

  const { dropdownRef, dropdownButtonRef, showDropdown, setShowDropdown } =
    useDropdown();

  const handleSelectDate = (selectedDateObj: KeyDatePair) => {
    setSelectedDate(selectedDateObj);
    setShowDropdown(false);
    dispatch(updateDateFilter(selectedDateObj.value));
  };

  const isTodaySelected = selectedDate.value === "TODAY";

  return {
    selectedDate,
    dropdownRef,
    dropdownButtonRef,
    showDropdown,
    setShowDropdown,
    handleSelectDate,
    KdsHeaderDateFilterConfig,
    setSelectedDate,
    isTodaySelected,
  };
};

export default useKdsDateFilter;


