import { Typography } from "kudi-component-library";
import styled from "styled-components";
import { Box, Flex } from "rebass";
import FilterDropdownIcon from "../../../Icons/FilterDropdownIcon";
import useDropdown from "../../../../hooks/useDropdown";
import {
  NavContextMenuItem,
  NavContextMenuWrapper,
} from "../../../Navbar/styles";
import { statusOptions as orderStatusOptions } from "../../config";
import { useAppDispatch, useAppSelector } from "../../../../state/store";
import { OrderStatus } from "../../../../types/MenuList";
import { setProposedOrderStatus } from "../../../../state/kds/slice";
import { returnFormattedOrderStatusName } from "../../../../utils/status";
import { useState } from "react";

const LabelText = styled(Typography)`
  font-weight: 500;
`;

const OrderStatusDropdownContainer = styled(Flex)`
  padding: 16px 24px 24px;
  width: 100%;
  flex-direction: column;
  gap: 4px;
  position: relative;
`;

const DropdownButton = styled(Flex)`
  display: flex;
  width: 218px;
  height: 40px;
  padding: 8px 10px 8px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #eee;
  cursor: pointer;
`;

export default function OrderStatusDropdown() {
  const dispatch = useAppDispatch();
  const { dropdownRef, dropdownButtonRef, showDropdown, setShowDropdown } =
    useDropdown();
  const { selectedOrder } = useAppSelector(
    (state) => state.kds
  );
  const [currStatus, setCurrStatus] = useState<OrderStatus | null>(selectedOrder?.status ?? null);

  const formattedStatus = returnFormattedOrderStatusName(
    (currStatus) as OrderStatus
  );

  return (
    <OrderStatusDropdownContainer>
      <LabelText variant="n-text1" color="grey2">
        Order Status
      </LabelText>
      <DropdownButton
        ref={dropdownButtonRef}
        onClick={() => setShowDropdown((state) => !state)}
      >
        <Typography variant="n-text2" style={{ textTransform: "capitalize" }}>
          {formattedStatus?.toLowerCase()}
        </Typography>
        <Box>
          <FilterDropdownIcon />
        </Box>
      </DropdownButton>
      {showDropdown ? (
        <NavContextMenuWrapper top="80px" ref={dropdownRef}>
          {orderStatusOptions.slice(1).map((option) => (
            <NavContextMenuItem
              key={option.value}
              onClick={() => {
                dispatch(setProposedOrderStatus(option.value as OrderStatus));
                setCurrStatus(option.value as OrderStatus);
                setShowDropdown((state) => !state);
              }}
            >
              <Typography variant="n-text2">{option.text}</Typography>
            </NavContextMenuItem>
          ))}
        </NavContextMenuWrapper>
      ) : null}
    </OrderStatusDropdownContainer>
  );
}
