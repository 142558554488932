import { IconsEnum, NIcons, Typography } from "kudi-component-library";
import {
  KdsDateFilterContextItem,
  KdsDateFilterContextMenuWrapper,
  KdsDateFilterButtonWrapper,
  KdsDateWrapper,
  KdsHeaderDateText,
  KdsDateFilterWrapper,
} from "./styles";
import useKdsDateFilter from "./models/useKdsDateFilter";
import dayjs from "dayjs";

const HeaderDateFilter = () => {
  const {
    dropdownRef,
    dropdownButtonRef,
    showDropdown,
    setShowDropdown,
    handleSelectDate,
    KdsHeaderDateFilterConfig,
    selectedDate,
    isTodaySelected,
  } = useKdsDateFilter();
  return (
    <KdsDateFilterWrapper>
      <KdsDateFilterButtonWrapper
        ref={dropdownButtonRef}
        isButtonActive={showDropdown}
        onClick={() => setShowDropdown((state) => !state)}
      >
        <Typography variant="n-text3" fontWeight="light">
          {isTodaySelected ? "Today:" : null}
        </Typography>
        <KdsDateWrapper>
          <KdsHeaderDateText variant="n-text3">
            {isTodaySelected ? dayjs().format("MMM D, YYYY") : selectedDate.key}
          </KdsHeaderDateText>
          <NIcons
            icon={IconsEnum.OUTLINE_CHEVRON_DOWN}
            width="20"
            height="20"
            color="n-grey6"
          />
        </KdsDateWrapper>
      </KdsDateFilterButtonWrapper>
      {showDropdown ? (
        <KdsDateFilterContextMenuWrapper ref={dropdownRef}>
          {KdsHeaderDateFilterConfig.map((schema) => {
            return (
              <KdsDateFilterContextItem
                key={`${schema.value}${Math.random()}`}
                onClick={() => handleSelectDate(schema)}
              >
                <Typography variant="n-text2">{schema.key}</Typography>
              </KdsDateFilterContextItem>
            );
          })}
        </KdsDateFilterContextMenuWrapper>
      ) : null}
    </KdsDateFilterWrapper>
  );
};

export default HeaderDateFilter;
