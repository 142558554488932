import { Box, Flex } from "rebass";
import { Dispatch, SetStateAction } from "react";
import { IconsEnum, NIcons, Typography } from "kudi-component-library";
import styled from "styled-components";

const BottomSheetWrapper = styled.div`
  margin-right: 20px;
  display: block;
  img {
    border-radius: 10px;
    width: 60px;
    height: 60px;
  }
`;
const BottomSheetBackdrop = styled.div<{ active: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  transition: all 0.2s ease-in-out;
  opacity: 0;
  pointer-events: none;

  z-index: 20;

  ${({ active }) => {
    if (active)
      return `
        opacity: 1;
        pointer-events: all;
      `;
  }}
`;
const BottomSheetInner = styled.div<{ active: boolean }>`
  background: rgb(255, 255, 255);
  width: 100%;
  /* min-height: 30%; */
  position: fixed;
  z-index: 22;
  transition: all 0.2s ease-in-out 0s;
  padding: 30px 20px;
  left: 50%;
  transform: translateX(-50%);

  //     initially it will be pushed down out of the view.
  bottom: -110%;

  ${({ active }) => {
    if (active)
      return `
      bottom: 0;
      @media (min-width: 700px) {
        bottom: 30%;
      }
      `;
  }}

  @media (min-width: 700px) {
    width: 400px;
  }
`;
const ModalClose = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  background-color: ${({ theme }) => theme.nColors["n-grey1"]};
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 3;
`;

interface BottomSheetModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  subTitle?: string;
  children: React.ReactNode;
}

const BottomSheetModal = ({
  isOpen,
  title,
  children,
  subTitle,
  setIsOpen,
}: BottomSheetModalProps) => {
  return (
    <BottomSheetWrapper>
      <BottomSheetBackdrop
        active={isOpen}
        onClick={() => {
          setIsOpen(false);
        }}
      ></BottomSheetBackdrop>
      <BottomSheetInner active={isOpen}>
        <Box>
          <Flex justifyContent={"space-between"} mb={"24px"}>
            <Box>
              <Typography
                fontWeight={700}
                style={{
                  fontSize: "20px",
                  lineHeight: "30px",
                  marginBottom: "5px",
                }}
              >
                {title}
              </Typography>
              {subTitle && (
                <Typography
                  fontWeight={400}
                  style={{
                    fontSize: "14px",
                    lineHeight: "22px",
                  }}
                >
                  {subTitle}
                </Typography>
              )}
            </Box>
            <ModalClose
              onClick={() => {
                setIsOpen(false);
              }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <NIcons height="18" width="18" icon={IconsEnum.OUTLINE_X} />
            </ModalClose>
          </Flex>

          {children}
        </Box>
      </BottomSheetInner>
    </BottomSheetWrapper>
  );
};

export default BottomSheetModal;
